import React from 'react'
import ReactBodymovin from 'react-bodymovin'
import styled from 'styled-components'

import animation from './assets/bm-spinner.json'

const bodymovinOptions = {
  loop: true,
  autoplay: true,
  prerender: true,
  animationData: animation,
}

const LoadingMaskStyled = styled.div`
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: ${({ props }) => `rgba(255, 255, 255, ${props.isOpaque ? '1' : '0.7'})`};
  backdrop-filter: blur(4px);
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  border-radius: 8px;
  width: 100%;
  .animation-wrapper {
    height: 100px;
    width: 100px;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    overflow: none;
  }
`

const LoadingMask = props => {
  return (
    <LoadingMaskStyled props={props}>
      <div className="animation-wrapper">
        <ReactBodymovin options={bodymovinOptions} />
      </div>
    </LoadingMaskStyled>
  )
}

export default LoadingMask
