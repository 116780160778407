import { H3, LabelLarge, LabelSmall, MultiVariantLabel, ParagraphBody, theme } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'

const PerMileStyles = styled.div<{ background?: string }>`
  width: 100%;
  padding-bottom: ${theme.grid.spacing.md}px;
  padding-top: ${theme.grid.spacing.md}px;
  text-align: center;
  background: ${props => (props.background ? props.background : '#ffffff')};
  border-radius: 8px;
  @media (min-width: ${theme.grid.resolution.tablet.width}px) {
    width: ${theme.grid.resolution.tablet.container}px;
  }
  @media (min-width: ${theme.grid.resolution.desktop.width}px) {
    width: ${theme.grid.resolution.desktop.container}px;
  }
  .number {
    margin-bottom: ${theme.grid.spacing.xs}px;
    margin-top: ${theme.grid.spacing.xs}px;
  }
  p.text {
    max-width: 240px;
    color: ${theme.primaryColours.aubergine.main};
    position: relative;
    margin: auto;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      max-width: 363px;
    }
  }
  p.refund-at-renewal {
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      max-width: 474px;
    }
    @media (min-width: ${theme.grid.resolution.desktop.width}px) {
      max-width: 508px;
    }
  }
  .a-mile {
    color: ${theme.primaryColours.aubergine.main};
    margin-bottom: ${theme.grid.spacing.s}px;
  }
`
const descriptions = {
  standard: 'This is the amount you pay for each mile you drive. You’ll be billed monthly for your driving.',
  refundAtRenewal: (
    <>
      If you need to top up, you can buy more miles at this price. Or, if you don’t fancy that, just keep driving and
      we’ll charge you for your extra miles monthly instead. Either way the rate won’t change.
      <br />
      <br />
      And that refund you get for unused miles? That’s based on this rate too.
    </>
  ),
}

interface PerMileProps {
  pence: number
  background?: string
  description?: string | JSX.Element | false
  largeLabel?: boolean
  className?: string
}

export const PerMile = ({ pence, description = descriptions.standard, ...props }: PerMileProps) => {
  return (
    <PerMileStyles {...props}>
      <MultiVariantLabel
        text="your PER-MILE RATE is"
        className="title LoNotSensitive"
        resolutions={{
          mobile: 'LabelSmall',
          tablet: 'LabelLarge',
          desktop: 'LabelLarge',
        }}
      />
      <H3 className="number LoNotSensitive">{`${pence} pence`}</H3>
      {props.largeLabel ? (
        <LabelLarge className="a-mile">A MILE</LabelLarge>
      ) : (
        <LabelSmall className="a-mile">A MILE</LabelSmall>
      )}

      <ParagraphBody className={`text LoNotSensitive ${props.className}`}>{description}</ParagraphBody>
    </PerMileStyles>
  )
}
PerMile.descriptions = descriptions
