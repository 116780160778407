import Cookie from 'js-cookie'
import React, { FC, useContext, useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { quoteId } from '../cache'
import LoadingMask from '../components/LoadingMask'
import config from '../config'
import { useInitialLoad } from '../hooks/useInitialLoad'
import { useQuote } from '../hooks/useQuote'
import { ProviderContext } from '../Provider'

interface CheckoutRouteProps extends RouteProps {}

export const CheckoutRoute: FC<CheckoutRouteProps> = ({ children, ...rest }) => {
  const {
    state: { interval },
    handleSetInterval,
  } = useContext(ProviderContext)
  if (!quoteId()) window.location.href = `${config.checkoutOrigin}/details/car`
  const quoteToken = Cookie.get(config.quoteTokenCookieName)
  if (!quoteToken) window.location.href = `/timeout`
  useInitialLoad()
  const quote = useQuote()

  useEffect(() => {
    interval && window.clearInterval(interval)
    handleSetInterval(
      setInterval(() => {
        const quoteToken = Cookie.get(config.quoteTokenCookieName)
        if (quoteToken) return
        window.location.href = `/timeout/retrieve?${window.location.pathname.substr(1)}`
      }, 60000),
    )
  }, [])
  if (!quote) return <LoadingMask />
  return <Route {...rest}> {children}</Route>
}
