import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const ExtrasPanelStyles = styled.div`
  margin-bottom: ${theme.grid.spacing.s}px;
  position: relative;
  background: white;
  border-radius: ${theme.grid.spacing.xs}px;
  padding: ${theme.grid.spacing.xs}px;
  display: flex;
  flex-direction: column;

  .selected-banner {
    background: ${theme.primaryColours.white.main};
    position: absolute;
    right: 0;
    border-radius: 0 8px;
    padding: 8px 12px;
    display: flex;
    align-items: center;
    gap: 8px;

    span {
      margin: 0;
    }
  }

  .flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    text-align: center;
  }

  .content-wrapper {
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    padding: ${theme.grid.spacing.s}px;
    height: 100%;
  }

  .extra-heading {
    color: ${theme.primaryColours.aubergine.main};
    margin-bottom: ${theme.grid.spacing.xs}px;
    text-transform: capitalize;
  }

  .extra-description {
    color: ${theme.primaryColours.slateGray.darkSlateGray};
    margin-bottom: ${theme.grid.spacing.base}px;
  }

  .extras-price {
    color: ${theme.primaryColours.aubergine.main};
    background: ${theme.tints.lightAubergine};
    margin: ${theme.grid.spacing.base}px 0;
    padding: ${theme.grid.spacing.xxs}px;
    text-align: center;
  }

  .content-wrapper {
    p {
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        font-size: 14px;
      }
    }
  }
`
