import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const StickyPricingStyles = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  box-shadow: ${theme.shadows.doubleUpShadow};
  background: ${theme.primaryColours.white.main};
  z-index: 10;

  p {
    margin: 0;
  }

  .container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: ${theme.grid.spacing.s}px ${theme.grid.spacing.base}px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      padding: ${theme.grid.spacing.base}px ${theme.grid.spacing.md}px;
      justify-content: space-between;
      max-width: ${theme.grid.resolution.tablet.container}px;
      margin: 0 auto;
    }

    @media (min-width: ${theme.grid.resolution.desktop.width}px) {
      max-width: ${theme.grid.resolution.desktop.container}px;
    }

    .content {
      .pay-today-container {
        margin-bottom: ${theme.grid.spacing.xs}px;
        &--refund-at-renewal {
          display: flex;
          align-items: center;
        }
        .pay-today-price {
          min-width: 100px;
        }
      }

      .ticked-headers {
        padding-left: 32px;
        margin-left: 32px;
        color: ${theme.primaryColours.black.main};
        display: none;
        border-left: 1px solid ${theme.tints.aubergine};
        @media (min-width: ${theme.grid.resolution.tablet.width}px) {
          display: block;
        }
        &__item {
          display: flex;
          &:first-child {
            margin-bottom: ${theme.grid.spacing.xs}px;
          }
          svg {
            margin-right: ${theme.grid.spacing.s}px;
          }
        }
      }

      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        display: flex;
        .pay-today-container {
          position: relative;
          margin: 0 32px 0 0;
          :after {
            content: '';
            position: absolute;
            width: 1px;
            height: 58px;
            background: ${theme.tints.aubergine};
            right: -32px;
            bottom: 0;
          }
        }
        .per-month-container {
          margin: 0 32px;
        }
      }
    }
  }
`
