interface calculatorInput {
  estimatedMiles: string
  perMileCost: number
}

export const calculateRefundFlexiblePricing = ({ estimatedMiles, perMileCost }: calculatorInput) => {
  const calculateValue = (number: number) => {
    return (number / 100).toFixed(2)
  }

  let estimates: any = []
  if (parseInt(estimatedMiles) === 0) {
    estimates = [
      {
        active: true,
        estimateCost: '0.00',
        estimateMiles: '0.00',
      },
      {
        active: false,
        middleColumnValue: `£${calculateValue(500)}`,
        estimateMiles: '500',
        estimateCost: '0.00',
      },
      {
        active: false,
        middleColumnValue: `£${calculateValue(1000)}`,
        estimateMiles: '1000',
        estimateCost: '0.00',
      },
      {
        active: false,
        middleColumnValue: `£${calculateValue(1500)}`,
        estimateMiles: '1500',
        estimateCost: '0.00',
      },
    ]
  } else if (parseInt(estimatedMiles) <= 500) {
    estimates = [
      {
        active: false,
        estimateCost: `${calculateValue(parseInt(estimatedMiles) * perMileCost)}`,
        estimateMiles: '0.00',
      },
      {
        active: true,
        estimateCost: '0.00',
        estimateMiles: estimatedMiles,
      },
      {
        active: false,
        estimateCost: '0.00',
        estimateMiles: parseInt(estimatedMiles) === 500 ? '1000' : '500',
        middleColumnValue:
          parseInt(estimatedMiles) === 500
            ? `£${calculateValue((1000 - parseInt(estimatedMiles)) * perMileCost)}`
            : `£${calculateValue((500 - parseInt(estimatedMiles)) * perMileCost)}`,
      },
      {
        active: false,
        estimateCost: '0.00',
        estimateMiles: parseInt(estimatedMiles) === 500 ? '1500' : '1000',
        middleColumnValue:
          parseInt(estimatedMiles) === 500
            ? `£${calculateValue((1500 - parseInt(estimatedMiles)) * perMileCost)}`
            : `£${calculateValue((1000 - parseInt(estimatedMiles)) * perMileCost)}`,
      },
    ]
  } else if (parseInt(estimatedMiles) > 500) {
    let secondEstimate = Math.floor(parseInt(estimatedMiles) / 500) * 500
    if (secondEstimate === parseInt(estimatedMiles)) {
      secondEstimate = Math.floor((parseInt(estimatedMiles) - 500) / 500) * 500
    }
    const lowestEstimate =
      Math.floor(secondEstimate - 500 / 500) * 500 < 0 ? 0 : Math.floor((secondEstimate - 500) / 500) * 500
    const nextStep = Math.floor((parseInt(estimatedMiles) + 500) / 500) * 500
    estimates = [
      {
        active: false,
        estimateCost: `${calculateValue((parseInt(estimatedMiles) - lowestEstimate) * perMileCost)}`,
        middleColumnValue: 0.0,
        estimateMiles: lowestEstimate,
      },
      {
        active: false,
        estimateCost: `${calculateValue((parseInt(estimatedMiles) - secondEstimate) * perMileCost)}`,
        estimateMiles: secondEstimate,
      },
      {
        active: true,
        estimateCost: '0.00',
        estimateMiles: estimatedMiles,
        middleColumnValue: '£0.00',
      },
      {
        active: false,
        estimateCost: '0.00',
        estimateMiles: nextStep,
        middleColumnValue: `£${calculateValue((nextStep - parseInt(estimatedMiles)) * perMileCost)}`,
      },
    ]
  }

  return estimates
}
