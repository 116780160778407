import { datadogRum } from '@datadog/browser-rum'

import { env, isProd, isTest } from '../config'

if (isProd) {
  datadogRum.init({
    site: 'datadoghq.eu',
    service: 'bymiles-quoteflow',
    version: process.env.REACT_APP_IMAGE_TAG || '1.0.0',
    sampleRate: 100,
    premiumSampleRate: 10,
    trackInteractions: true,
    trackFrustrations: true,
    applicationId: '3e1a1e0e-7fc2-44ae-a014-ccb1a7465029',
    clientToken: 'pub8f92f7e5d03d9fc3f4328c0eb5707eb5',
    env,
    trackSessionAcrossSubdomains: true,
    defaultPrivacyLevel: 'mask',
  })
} else if (isTest) {
  datadogRum.init({
    site: 'datadoghq.eu',
    service: 'bymiles-quoteflow',
    version: process.env.REACT_APP_IMAGE_TAG || '1.0.0',
    sampleRate: 100,
    trackInteractions: true,
    trackFrustrations: true,
    applicationId: '3e1a1e0e-7fc2-44ae-a014-ccb1a7465029',
    clientToken: 'pub8f92f7e5d03d9fc3f4328c0eb5707eb5',
    env,
    trackSessionAcrossSubdomains: true,
    allowedTracingOrigins: ['https://checkout.bymiles.co.uk', 'https://checkout.test.bymiles.co.uk'],
  })
}
datadogRum.startSessionReplayRecording()
