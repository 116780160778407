import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const TrustpilotStyles = styled.div<{ showModal?: boolean }>`
  .trustpilot {
    // * Preventing accessibility to widget when in open modal
    ${({ showModal }) => showModal && 'display: none'};

    // * Trustpilot widget sizing
    margin-right: -9px;
    iframe {
      width: 128px !important;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        width: 153px !important;
        height: 90px !important;
      }
    }
  }

  // * Trustpilot backup styling
  svg {
    width: 128px;
    .st0 {
      fill: #191919;
    }
    .st1 {
      fill: #00b67a;
    }
    .st2 {
      fill: #005128;
    }
  }
`
