export const createAccount = {
  userInputs: {
    email: '',
    repeatEmail: '',
    password: '',
    repeatPassword: '',
    phoneNumber: '',
  },
  validInputs: {
    email: false,
    repeatEmail: false,
    password: false,
    repeatPassword: false,
    phoneNumber: false,
  },
  errors: {
    email: false,
    repeatEmail: false,
    password: false,
    repeatPassword: false,
    phoneNumber: false,
  },
}
