import React from 'react'
import styled from 'styled-components'

import { useRegisterAccordionItem } from './Accordion'
import { AccordionItemProps } from './types'

const AccordionItemStyled = styled.div`
  display: block;
`

export const AccordionItem: React.FC<AccordionItemProps> = ({ children, index, expanded }) => {
  useRegisterAccordionItem({ index, expanded })
  const childrenWithProps = React.Children.map(children, child => {
    if (React.isValidElement(child)) return React.cloneElement(child, { index })
    return child
  })
  return <AccordionItemStyled className="accordion__item">{childrenWithProps}</AccordionItemStyled>
}
