export const getBulletCopy = (code: string) => {
  if (code === 'breakdown_rr') {
    return {
      bullets: [
        'Roadside assistance and repairs with no call-out charge*',
        'Recovery service for your car to a repairer',
      ],
      aboveBullets: '',
    }
  }
  if (code === 'breakdown_rrahot') {
    return {
      bullets: [
        'All the cover offered by UK Roadside & Recovery package',
        'Onward travel for your car and passengers to anywhere in the UK**',
        'Service at home',
      ],
      aboveBullets: '',
    }
  }
  if (code === 'breakdown_rrahote') {
    return {
      bullets: [
        'All the cover offered by UK Roadside, Recovery, At Home & Onward Travel package',
        'Breakdown cover in 48 European countries (and their off-shore islands)',
        'Service at home',
      ],
      aboveBullets: '',
    }
  }
  if (code === 'legal_cover_1' || code === 'waived_legal_cover_1') {
    return {
      bullets: [
        'Claim back expenses you’ve had to pay as a result of a road traffic accident.',
        'Defend motoring prosecutions arising from your use or ownership of a car.',
        'Claim for injury or death as a result of a road traffic accident.',
      ],
      aboveBullets: '',
    }
  }
  if (code === 'courtesy_car') {
    return {
      bullets: [
        'Get access to a courtesy hire car for up to 21 days if your car is totally destroyed, lost, or damaged to such an extent that it cannot be recovered (also known as a ‘write off’)',
        'The courtesy car will be of a similar make and model to your own car (up to 2.0 litre)',
        'Any courtesy car will be delivered and collected, and includes comprehensive temporary motor insurance - at no extra cost',
      ],
      aboveBullets: 'With Courtesy Car Plus, we’ll be able to get you back on the road quickly, whatever happens.',
    }
  }
  return {
    bullets: undefined,
    aboveBullets: '',
  }
}
