import Cookie from 'js-cookie'
import React, { FC, useContext, useEffect } from 'react'
import { Route, RouteProps } from 'react-router-dom'

import { quoteId } from '../cache'
import LoadingMask from '../components/LoadingMask'
import config from '../config'
import { quoteQuery } from '../hooks'
import { useQuote } from '../hooks/useQuote'
import { ProviderContext } from '../Provider'

interface FullQuoteRouteProps extends RouteProps {}

export const FullQuoteRoute: FC<FullQuoteRouteProps> = ({ children, ...rest }) => {
  const {
    state: { interval },
    handleSetInterval,
  } = useContext(ProviderContext)

  const quote = useQuote()

  const handleTimeout = () => {
    const retrieveQuote = quote?.status?.sections?.proposer?.status === 'COMPLETE'
    window.location.href = `/timeout${retrieveQuote ? '/retrieve' : ''}?${window.location.pathname.substr(1)}`
  }
  if (quoteId() && !Cookie.get(config.quoteTokenCookieName)) {
    handleTimeout()
  }

  const { loading } = quoteQuery({ skip: !quoteId(), skipImmobilise: true })

  useEffect(() => {
    interval && window.clearInterval(interval)
    handleSetInterval(
      setInterval(() => {
        const quoteToken = Cookie.get(config.quoteTokenCookieName)
        if (quoteToken || (window.location.pathname === '/details/car' && !quote)) return
        handleTimeout()
      }, 60000),
    )
  }, [quote])
  if (loading) return <LoadingMask />
  return <Route {...rest}> {children}</Route>
}
