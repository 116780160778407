import { ButtonPrimary, H2, ParagraphBody } from '@by-miles/bymiles-styles'
import { DataLayer } from '@by-miles/data-layer'
import React, { useContext, useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { useQuote } from '../../hooks/useQuote'
import { ProviderContext } from '../../Provider'
import { Extras } from '../Extras'
import { GenericOverlay } from './GenericOverlay/GenericOverlay'

export const ExtrasPopup = () => {
  const quote = useQuote()
  const history = useHistory()
  const { handleClickedExtras, simpleStateUpdater } = useContext(ProviderContext)

  useEffect(() => {
    DataLayer.customEvent({
      eventName: '/checkout/extras-pop-up',
      section: 'Extras Popup',
      eventType: 'virtual page view',
    })
  }, [])

  const handleClickedContinueFromPopup = () => {
    simpleStateUpdater([
      { state: 'showModal', value: false },
      { state: 'shouldShowExtrasPopUp', value: false },
      { state: 'activeModal', value: '' },
    ])
    history.push('/how', { from: '/cover' })
  }

  return (
    <div className="popup-overlay">
      <GenericOverlay
        onClose={() => simpleStateUpdater([{ state: 'shouldShowExtrasPopUp', value: false }])}
        overlay={
          <>
            <H2 className="popup-title">Want to boost your cover?</H2>
            <ParagraphBody className="popup-description">
              Add extra cover to your policy, and get covered if the worst happens.
            </ParagraphBody>
            <Extras
              breakdownCoverTiers={quote?.breakdownCoverOptions || []}
              legalCoverTiers={quote?.legalCoverOptions || []}
              handleOnClick={(type: string) => handleClickedExtras(type, true)}
              hasBreakdown={quote?.breakdownCoverOptions.some((tier: any) => tier?.selected === true) || false}
              hasLegal={quote?.legalCoverOptions.some((tier: any) => tier?.selected === true) || false}
            />
            <div className="popup-button-wrapper">
              <ButtonPrimary
                onClick={() => handleClickedContinueFromPopup()}
                label="continue"
                className="popup-button"
                iconRight="IconArrowRight"
              />
            </div>
          </>
        }
      />
    </div>
  )
}
