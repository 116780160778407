export const randomCustomerNames = [
  'A savvy driver',
  'An astute motorist',
  'An astute driver',
  'An inspired car owner',
  'An inspired driver',
  'An inspired motorist',
  'A discerning automobile owner',
  'A discerning car owner',
  'A clever driver',
  'A clever motorist',
  'A savvy motorist',
  'A forward-thinking motorist',
  'A forward-thinking driver',
  'A low mileage driver',
  'A low mileage motorist',
  'A frugal consumer',
  'A frugal driver',
  'A frugal motorist',
  'An early adopter of great ideas',
  'A smart web user',
]

export const randomCustomerName = () => randomCustomerNames[Math.floor(Math.random() * randomCustomerNames.length - 1)]
