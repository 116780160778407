import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const EditableDropdownPanelStyles = styled.div`
  .container {
    padding: 24px;
    border-radius: 8px;
    background: ${theme.primaryColours.white.main};
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      max-width: ${theme.grid.resolution.tablet.container}px;
      margin: 0 auto;
      .inner-container {
        display: flex;
        justify-content: space-between;
        gap: 48px;
      }
    }
    @media (min-width: ${theme.grid.resolution.desktop.width}px) {
      max-width: ${theme.grid.resolution.desktop.container}px;
    }
  }
  .voluntary-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: ${theme.grid.spacing.s}px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      width: 100%;
      position: relative;
      margin-bottom: 0;

      :after {
        content: '';
        position: absolute;
        width: 1px;
        height: 100%;
        background: ${theme.tints.aubergine};
        right: -24px;
        bottom: 0;
      }
    }
  }
  .compulsory-wrapper {
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      display: flex;
      justify-content: space-between;
      position: relative;
      flex-wrap: wrap;
    }

    .label-wrapper {
      width: 100%;
      min-height: 32px;
      .comp-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
      }
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        display: flex;
        align-items: center;
        min-height: none;
      }
    }

    .compulsory-amount-mobile {
      color: ${theme.primaryColours.aubergine.main};
      margin-left: auto;
    }
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      width: 100%;
      .compulsory-amount-mobile {
        display: none;
      }
    }
  }
  .info-wrapper {
    &.tablet {
      display: none;
    }
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-left: auto;

      &.mobile {
        display: none;
      }

      &.tablet {
        display: flex;
        justify-content: flex-end;
        position: relative;
        top: -16px;

        .info {
          display: flex;
          p {
            margin-right: 8px;
          }
        }
      }
    }
    .info {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      p {
        margin-right: 8px;
      }
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        margin-top: 0;
      }
      span {
        height: 20px;
      }
    }
  }
  .info_icon {
    svg {
      cursor: pointer;
    }
  }
  .label {
    max-width: 95px;
  }
`
