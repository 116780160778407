import './App.scss'

import React, { Suspense } from 'react'
import Helmet from 'react-helmet'
import { BrowserRouter as Router } from 'react-router-dom'

import ChatWidget from './components/ChatWidget'
import LoadingMask from './components/LoadingMask'
import ScrollToTop from './components/ScrollToTop'
import config from './config'
import { GTMScript } from './GTMScript'
import Provider from './Provider'
import { Router as Routes } from './Router'
import { GenericStyles } from './styles/GenericStyles'

function App() {
  return (
    <Suspense fallback={<LoadingMask />}>
      <Router>
        <Provider>
          <Helmet titleTemplate="%s | By Miles" defaultTitle="By Miles">
            <script src={GTMScript() as undefined} type="text/javascript" />
            <script async src={`https://www.googleoptimize.com/optimize.js?id=${config.googleOptimizeId}`} />
          </Helmet>
          <ScrollToTop />
          <GenericStyles id="pages-wrapper" className="pages-wrapper">
            <Routes />
          </GenericStyles>
          <ChatWidget />
        </Provider>
      </Router>
    </Suspense>
  )
}

export default App
