import axios from 'axios'

export const fetcher = (url, method, headers, payload) => {
  return axios({
    method,
    url,
    headers: {
      ...headers,
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    credentials: 'include',
    data: payload ? JSON.stringify(payload) : null,
  })
}
