import React from 'react'
import styled from 'styled-components'

const ExtrasLogoStyles = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  .extra-logo {
    min-height: 40px;
  }
`

export const ExtrasLogo = ({ logo, altText }) => {
  return (
    <ExtrasLogoStyles>
      <div className="extra-logo">
        <img className="image" alt={altText} src={`../v2/assets/extras/${logo}`} />
      </div>
    </ExtrasLogoStyles>
  )
}
