import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const ExcessOverlayStyles = styled.div`
  .overlay-heading {
    margin-bottom: 24px;
  }

  .overlay-content {
    color: ${theme.primaryColours.aubergine.main};
    margin-bottom: 24px;

    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-bottom: 32px;
    }
  }

  .accordion__item {
    &:not(:last-child) {
      margin-bottom: ${theme.grid.spacing.s}px;
    }
    .header-container {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding-right: ${theme.grid.spacing.s}px;
      .header-text {
        max-width: 60%;
      }
    }
  }
`
