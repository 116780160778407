import { datadogRum } from '@datadog/browser-rum'
import dayjs from 'dayjs'
import { useContext } from 'react'

import { useQuoteQuery } from '../generated/graphql'
import { ProviderContext } from '../Provider'
import { handleShowChurchill } from '../utils/handleShowChurchill'
import { onlyImmobiliserIsIdScore } from '../utils/onlyImmobiliserIsIdScore'

interface QuoteQueryProps {
  skip?: boolean
  skipImmobilise?: boolean
}

const QuoteQuery = (props?: QuoteQueryProps) => {
  const { state, setPricingState, simpleStateUpdater } = useContext(ProviderContext)
  const { data, error, loading } = useQuoteQuery({
    variables: { id: state.quoteId },
    skip: props?.skip || !state.quoteId,
    onCompleted: async data => {
      const quote = data?.quote
      if (!quote) {
        datadogRum.addError('Quote not found', { quoteId: state.quoteId })
        window.location.href = '/sorry'
      } else if (!quote.pricing) {
        simpleStateUpdater([
          { state: 'quoteId', value: quote.id },
          { state: 'isTrackerless', value: quote?.details.policy?.isTrackerless! },
        ])
      } else if (!quote.details?.policy?.inception_date) {
        datadogRum.addError('No inception date', { quoteId: state.quoteId })
        if (window.location.pathname !== '/details/cover') {
          window.location.href = window.location.pathname.includes('/details') ? 'cover' : 'details/cover'
        }
      } else if (dayjs(quote.details?.policy?.inception_date!).isBefore(dayjs(), 'day')) {
        datadogRum.addError('Inception date in the past', { quoteId: state.quoteId })
        if (window.location.pathname !== '/details/cover') {
          window.location.href = window.location.pathname.includes('/details') ? 'cover' : 'details/cover'
        }
      } else if (quote.pricing) {
        simpleStateUpdater([{ state: 'showChurchill', value: handleShowChurchill(quote.details.sid || '') }])
        if (!props?.skipImmobilise) {
          if (!quote.pricing.isValid && !quote.isAllowListed && !onlyImmobiliserIsIdScore(quote.pricing?.immobiliser)) {
            const error = new Error('Invalid quote')
            datadogRum.addError(error, {
              quoteId: quote.id,
              isValid: quote.pricing?.isValid,
              isAllowListed: quote.isAllowListed,
              immobiliser: quote.pricing?.immobiliser,
            })
            window.location.href = '/sorry'
          } else {
            setPricingState(quote)
            simpleStateUpdater([{ state: 'isTrackerless', value: quote?.details.policy?.isTrackerless! }])
          }
        }
      }
    },
    onError: error => {
      datadogRum.addError(error)
      window.location.href = '/sorry'
    },
  })
  return { data, loading, error }
}

export const quoteQuery = QuoteQuery
