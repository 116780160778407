import { IconCheckButton, ParagraphBody, theme } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'

const PluginListItemStyles = styled.div`
  display: flex;
  align-items: start;
  margin-bottom: 16px;
  @media (min-width: ${theme.grid.resolution.tablet.width}px) {
    margin-bottom: 24px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .icon {
    margin: 0 8px 0 0;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin: 0 16px 0 0;
    }

    svg {
      width: 20px;
      height: 20px;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        width: 33px;
        height: 33px;
      }
    }
  }
`

export const PluginListItem = ({ icon, item }) => (
  <PluginListItemStyles className="item">
    <div>
      <IconCheckButton status={icon.status} iconFill={icon.fill} backgroundFill={icon.background} />
    </div>
    <ParagraphBody>{item}</ParagraphBody>
  </PluginListItemStyles>
)
