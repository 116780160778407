import { ParagraphBody } from '@by-miles/bymiles-styles'
import { FooterLink } from '@by-miles/bymiles-styles/dist/molecules/navigation'
import React, { useEffect, useState } from 'react'

import { Link } from '../../utils/links'
import { FooterStyled } from './Footer.styled'

interface FooterProps {
  links: Link[]
  text: string
  className?: string
  tabFocusTrap?: number
  hideStickyPricing?: boolean
}

export const Footer = ({ links, text, className = '', tabFocusTrap, hideStickyPricing = true }: FooterProps) => {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight)
  const pageHeight = document.getElementById('pages-wrapper')?.offsetHeight || 0
  const handleResize = () => {
    setScreenHeight(window.innerHeight)
  }
  useEffect(() => {
    window.addEventListener('resize', handleResize, false)
  }, [])
  const footerHeight = document.getElementById('footer-container')?.offsetHeight || 0
  const fixedFooter = screenHeight - footerHeight > pageHeight
  return (
    <FooterStyled className={className} fixedFooter={fixedFooter} hideStickyPricing={hideStickyPricing}>
      <div id="footer-container" className="container">
        <div className="links">
          {links.map((link: Link, key: number) => (
            <span key={key}>
              <FooterLink
                tabIndex={tabFocusTrap}
                onClick={link.tracking}
                target="_blank"
                rel="noreferrer"
                href={link.href}
              >
                {link.label}
              </FooterLink>
            </span>
          ))}
        </div>
        <ParagraphBody>{text}</ParagraphBody>
      </div>
    </FooterStyled>
  )
}
