export enum RemoveExtraAction {
  RemoveBreakdownCover = 'removeBreakdownCover',
  RemoveLegalCover = 'removeLegalCover',
  RemoveCarHireCover = 'removeCarHireCover',
}

export enum AddExtraAction {
  AddBreakdownCover = 'addBreakdownCover',
  AddLegalCover = 'addLegalCover',
  AddCarHireCover = 'addCarHireCover',
}
