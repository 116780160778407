import { H3, H4 } from '@by-miles/bymiles-styles'
import { ButtonGeneral, ParagraphBody } from '@by-miles/bymiles-styles'
import { useContext } from 'react'

import config from '../../../config'
import { ProviderContext } from '../../../Provider'
import CXBanner from '../../CXBanner'
import * as Styles from './ContactUs.styles'
// import { isCallCentreOpen } from './ContactUs.utils'

interface ContactUsProps {
  onClick: () => void
}

const ContactUs = ({ onClick }: ContactUsProps) => {
  const { state } = useContext(ProviderContext)
  const CXBannerEnabled = config.CXBannerEnabled
  // const isOpen = isCallCentreOpen()

  return (
    <Styles.Wrapper
      CXBannerEnabled={CXBannerEnabled}
      // isOpen={isOpen}
    >
      <div className="container">
        <div className="image-container">
          <img className="image" alt="customer experience executive" src="/v2/assets/contact-us.jpg" />
        </div>

        <div className="details">
          <H3 className="heading main">We're here to help.</H3>
          {CXBannerEnabled && <CXBanner />}

          <ButtonGeneral
            label="CHAT TO US"
            onClick={onClick}
            className="icon"
            icon="IconSpeechBubble"
            tabFocusTrap={state.showModal ? -1 : 0}
          />

          <div className="phone-wrapper">
            <H3 className="heading sub">Call us.</H3>

            <a href={`tel:${state.byMilesPhoneNumber}`} tabIndex={state.showModal ? -1 : 0}>
              <H4 className="heading telephone">{state.byMilesPhoneNumberFormatted}</H4>
            </a>

            <a href={`tel:${state.byMilesPhoneNumber}`} tabIndex={state.showModal ? -1 : 0}>
              <ButtonGeneral
                label={state.byMilesPhoneNumberFormatted}
                className="hide-tablet"
                icon="IconPhone"
                tabFocusTrap={state.showModal ? -1 : 0}
              />
            </a>
          </div>
          <ParagraphBody className={`${CXBannerEnabled ? 'call-us-paragraph' : ''}`}>
            Contact us between 10am - 4pm on weekdays, weekends and bank holidays.
            <br />
            {/* The call centre is <span className="indicator">{isOpen ? 'open' : 'closed'}</span> right now. */}
          </ParagraphBody>
        </div>
      </div>
    </Styles.Wrapper>
  )
}

export default ContactUs
