import {
  ButtonCheckbox,
  ButtonGeneral,
  ButtonSecondary,
  H2,
  H5,
  ParagraphBody,
  SpecialLink,
} from '@by-miles/bymiles-styles'
import { DataLayer } from '@by-miles/data-layer'
import { includes } from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import useChatWidget from '../../../hooks/useChatWidget'
import { useQuote } from '../../../hooks/useQuote'
import { ProviderContext } from '../../../Provider'
import { sanitizeCarModel } from '../../../utils/sanitizeCarModel'
import { GenericOverlay } from '../GenericOverlay/GenericOverlay'
import { DifficultPluginStyles } from './DifficultPluginStyles'

interface DifficultPluginProps {
  nextPage: string
}

export const DifficultPlugin = ({ nextPage }: DifficultPluginProps) => {
  const quote = useQuote()
  const { openChatWidget } = useChatWidget()
  const { simpleStateUpdater, handleShowModal } = useContext(ProviderContext)
  const [acceptedTerms, setAcceptedTerms] = useState(false)
  const [buttonError, setButtonError] = useState(false)
  const history = useHistory()
  const vehicle = quote?.details?.vehicle

  useEffect(() => {
    if (acceptedTerms && buttonError) {
      setButtonError(false)
    }
  }, [acceptedTerms])

  const obdFit = vehicle?.obd_fit || ''
  const withCable = includes(obdFit.toLowerCase(), 'cable')
  const bodyCopy = withCable
    ? `We'll send you velcro pads and an extension cable to make it a better fit, but before you pay, have a quick look in your car to make sure you're happy plugging the Miles Tracker into its OBD-II socket.`
    : `Before you pay, have a quick look in your car to make sure you're happy plugging the Miles Tracker into its OBD-II socket.`
  const obdLocationText = vehicle?.obd_text
  const make = vehicle?.make
  const model = sanitizeCarModel({ make: vehicle?.make || '', model: vehicle?.model || '', trim: vehicle?.trim || '' })
  const obdHelperText = vehicle?.obd_helper_text

  const validatePageAndNavigate = () => {
    if (acceptedTerms) {
      simpleStateUpdater([{ state: 'showModal', value: false }])
      history.push(nextPage)
    }
    setButtonError(true)
  }

  const handleSaveButtonClick = () => {
    DataLayer.customEvent({
      eventName: 'save quote button',
      section: 'quote',
      eventType: 'form tracking',
    })
    handleShowModal('saveQuoteModal')
  }

  return (
    <GenericOverlay
      hideDismissIcon
      onClose={() => {}}
      overlay={
        <DifficultPluginStyles>
          <div className="difficult-plugin-modal-wrapper">
            <H2 className="LoNotSensitive difficult-plugin-heading">Hang on a sec!</H2>
            <H5 className="LoNotSensitive lead-paragraph">
              Looks like it might be a bit tricky to plug a Miles Tracker into your {make} {model}
            </H5>
            <div className="mod-body">
              <ParagraphBody>
                {bodyCopy} You can find your car's OBD-II socket {obdLocationText}
                <br />
                <br />
                {obdHelperText !== '0' ? obdHelperText : ''}
              </ParagraphBody>
              <div className="obd-gif-wrapper">
                <img
                  className="gif"
                  src={`./v2/assets/obdLocations/Location-${vehicle?.obd_location}.gif`}
                  alt="location of obd"
                />
              </div>
              <div className="intercom-link">
                <SpecialLink onClick={() => openChatWidget()}>Ask us a question.</SpecialLink>
              </div>
              <ButtonCheckbox
                className="LoNotSensitive plugin-checkbox-button"
                label="I’ve checked and I’m happy I can safely plug it in"
                onClick={() => {
                  setAcceptedTerms(!acceptedTerms)
                  simpleStateUpdater([{ state: 'difficultPlugginConfirmed', value: true }])
                }}
                secondary={true}
                error={buttonError}
              />
              <div className="button-wrapper">
                <ButtonSecondary
                  onClick={handleSaveButtonClick}
                  className="save-quote-btn LoNotSensitive"
                  label="Save quote while I check"
                />
                <ButtonGeneral
                  onClick={validatePageAndNavigate}
                  className="proceed-btn LoNotSensitive"
                  label="Buy this policy"
                />
              </div>
            </div>
          </div>
        </DifficultPluginStyles>
      }
    />
  )
}
