import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

import { AccordionStep } from './accordionStep'

const AccordionStyle = styled.div`
  max-width: 824px;
`

export const Accordion = ({
  type = 'simple',
  steps = [],
  defaultStep = 1,
  keepOpen = false,
  activeStep = 1,
  onAllStepsCompleted = () => {
    return false
  },
  onStepCompleted = () => {
    return false
  },
  stepStyle = {},
  confirmButtonLabel = 'confirm',
  noPaddingBody = false,
  className = '',
}) => {
  // active is the current open step. MultipleActive is used when we want to keep multiple steps open.
  // completedSteps keeps track of completed steps for the smart type.
  const [state, setState] = useState({
    active: type === 'simple' ? defaultStep : 1,
    multipleActive: {},
    completedSteps: [],
  })
  // this populates the manyActives state with the current steps
  useEffect(() => {
    const multipleActive = {}
    steps.forEach((step, index) => {
      const stepIndex = index + 1
      multipleActive[`step${stepIndex}`] = stepIndex === defaultStep
    })
    setState({
      ...state,
      multipleActive,
    })
  }, [])

  //main function when a step is clicked
  const setActiveStep = (
    event,
    stepIndex,
    tracking = () => {
      return false
    },
  ) => {
    if (type !== 'simple' || !keepOpen) {
      // in smart mode, we only want to trigger this when the EDIT button is clicked
      if (type !== 'simple') {
        if (!event.target.classList.contains('edit-button')) {
          return false
        }
        // make sure the step has been completed or it is the next one, so the user can't jump ahead
        // if so, update the completedSteps. Formally completed steps will now be uncompleted and needed to be
        // confirmed again.
        if (state.completedSteps.includes(stepIndex) || stepIndex === Math.max(...state.completedSteps) + 1) {
          let completedArray = [...state.completedSteps]
          if (state.completedSteps.includes(stepIndex)) {
            completedArray = state.completedSteps.filter(item => item < stepIndex)
          }
          setState({
            ...state,
            active: stepIndex,
            completedSteps: completedArray,
          })
        }
        return
      }
      // if this is the simple type, then we just need to update the active tab.
      // if the step clicked is already open, we want to close it.
      const active = state.active === stepIndex ? 0 : stepIndex
      setState({
        ...state,
        active,
      })
    } else {
      // if this is the simple one but we have "keepOpen" as true, then we update the multiActive array
      setState({
        ...state,
        multipleActive: {
          ...state.multipleActive,
          [`step${stepIndex}`]: !state.multipleActive[`step${stepIndex}`],
        },
      })
    }

    if (tracking) {
      tracking()
    }
  }

  // a function to update "completedSteps" when a step has been completed.
  // it uses a passed function and if all goes well goes on to update the rest.
  const handleStepCompleted = stepIndex => {
    const success = onStepCompleted()
    if (!success) return false

    const completedArray = [...state.completedSteps]
    if (!completedArray.includes(stepIndex)) {
      completedArray.push(stepIndex)
    }
    if (steps.length === completedArray.length) {
      onAllStepsCompleted()
    } else {
      setState({
        ...state,
        active: stepIndex + 1,
        completedSteps: completedArray,
      })
    }
  }

  return (
    <AccordionStyle className={className}>
      {steps &&
        steps.length > 0 &&
        steps.map((step, index) => {
          const stepIndex = index + 1
          return (
            <AccordionStep
              key={`acc_step_${index}`}
              type={type}
              step={step}
              stepIndex={stepIndex}
              active={
                (type !== 'simple' && activeStep === stepIndex) ||
                (type === 'simple' && !keepOpen && state.active === stepIndex) ||
                (keepOpen && type === 'simple' && state.multipleActive[`step${stepIndex}`] === true)
              }
              completed={state.completedSteps.includes(stepIndex)}
              onClick={event => setActiveStep(event, stepIndex, step.tracking ? step.tracking : false)}
              onStepCompleted={() => handleStepCompleted(stepIndex)}
              style={stepStyle}
              confirmButtonLabel={confirmButtonLabel}
              noPaddingBody={noPaddingBody}
            />
          )
        })}
    </AccordionStyle>
  )
}
