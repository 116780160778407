import { IconCaretDown, IconCaretUp, theme } from '@by-miles/bymiles-styles'
import { DataLayer } from '@by-miles/data-layer'
import React from 'react'
import styled from 'styled-components'

import Accordion, { useAccordionContextProvider } from '../shared/AccordionV2/Accordion'

const FAQAccordionHeaderStyled = styled.div<{ background?: string }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: ${({ background }) => (background ? background : theme.primaryColours.white.main)};
  color: ${theme.primaryColours.aubergine.main};
  padding: 12px 24px;
  min-height: 37px;
  border-left: 3px solid ${theme.primaryColours.aubergine.main};
  cursor: pointer;
  .icon {
    display: flex;
  }
`

export const FAQAccordionHeader: React.FC<{
  index?: string
  background?: string
  closeOnOpen?: boolean
  tabFocusTrap?: number
}> = ({ index, children, background, closeOnOpen, tabFocusTrap }) => {
  if (!index) throw Error('Missing index in Accordion Header')
  const { expanded } = useAccordionContextProvider({ index })
  return (
    <Accordion.Header
      index={index}
      closeOnOpen={closeOnOpen}
      tabFocusTrap={tabFocusTrap}
      callback={() =>
        DataLayer.customEvent({
          eventName: 'open',
          section: index,
          eventType: 'button click',
        })
      }
    >
      <FAQAccordionHeaderStyled background={background}>
        {children} {expanded ? <IconCaretUp /> : <IconCaretDown />}
      </FAQAccordionHeaderStyled>
    </Accordion.Header>
  )
}
