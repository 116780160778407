import { Dropdown, DropdownWidth, Option } from '@by-miles/bymiles-styles'
import { H5, InfoIcon, MultiVariantLabel, ParagraphBody, theme } from '@by-miles/bymiles-styles'
import React, { useContext } from 'react'

import { ProviderContext } from '../../../Provider'
import { EditableDropdownPanelStyles } from './EditableDropdownPanelStyles'

interface EditableDropdownPanelProps {
  primaryTitle: string
  secondaryTitle: string
  secondaryInfo: string
  infoTitle: string
  options?: Option[]
  preFilledValue: string
  dropdownState?: string
  handleDropdownChange: (option: Option) => void
  onInfoClick?: () => void
  width?: DropdownWidth
  dataDogActionName?: string
}

export const EditableDropdownPanel = ({
  primaryTitle,
  secondaryTitle,
  secondaryInfo,
  infoTitle,
  options = [],
  preFilledValue,
  dropdownState = '',
  handleDropdownChange,
  onInfoClick,
  width,
  dataDogActionName,
}: EditableDropdownPanelProps) => {
  const {
    state: { isMobile, showModal },
  } = useContext(ProviderContext)
  return (
    <EditableDropdownPanelStyles>
      <div className="container LoNotSensitive" data-dd-action-name={dataDogActionName}>
        <div className="inner-container">
          <div className="voluntary-wrapper">
            <MultiVariantLabel
              text={primaryTitle}
              resolutions={{
                mobile: 'LabelSmall',
                tablet: 'LabelMedium',
                desktop: 'LabelLarge',
              }}
              className="label"
            />
            <Dropdown
              options={options}
              preFilled={preFilledValue}
              width={width}
              isMobile={isMobile}
              states={dropdownState}
              handleDropdownChange={handleDropdownChange}
              tabFocusTrap={showModal ? -1 : 0}
            />
          </div>
          <div className="compulsory-wrapper">
            <div className="label-wrapper">
              <div className="comp-wrapper">
                <MultiVariantLabel
                  text={secondaryTitle}
                  resolutions={{
                    mobile: 'LabelSmall',
                    tablet: 'LabelMedium',
                    desktop: 'LabelLarge',
                  }}
                  className="label"
                />
                <H5 className="compulsory-amount">{secondaryInfo}</H5>
              </div>
            </div>
            <div className="info-wrapper mobile">
              <div className="info">
                <ParagraphBody style={{ color: theme.primaryColours.aubergine.main }}>{infoTitle}</ParagraphBody>
                <span onClick={onInfoClick}>
                  <InfoIcon fill={theme.primaryColours.cyan.superDarkCyan} />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="info-wrapper tablet">
          <div className="info">
            <ParagraphBody style={{ color: theme.primaryColours.aubergine.main }}>{infoTitle}</ParagraphBody>
            <span onClick={onInfoClick}>
              <InfoIcon fill={theme.primaryColours.cyan.superDarkCyan} />
            </span>
          </div>
        </div>
      </div>
    </EditableDropdownPanelStyles>
  )
}
