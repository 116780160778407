import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AddBreakdownCover = {
  code: Scalars['String'];
};

export type AddCarHireCover = {
  code: Scalars['String'];
};

export type AddGaClientId = {
  clientId: Scalars['String'];
};

export type AddLegalCover = {
  code: Scalars['String'];
};

export type AdditionalDriver = {
  __typename?: 'AdditionalDriver';
  first_names?: Maybe<Scalars['String']>;
  last_names?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  claims: Array<Maybe<Claim>>;
  business?: Maybe<Scalars['String']>;
  born_in_uk?: Maybe<Scalars['Boolean']>;
  employment?: Maybe<Scalars['String']>;
  has_claims?: Maybe<Scalars['Boolean']>;
  occupation?: Maybe<Scalars['String']>;
  licence_type?: Maybe<Scalars['String']>;
  residency_date?: Maybe<Scalars['String']>;
  no_claims_selected?: Maybe<Scalars['Int']>;
  licence_issued_date?: Maybe<Scalars['String']>;
  has_medical_conditions?: Maybe<Scalars['Boolean']>;
  convictions: Array<Maybe<Conviction>>;
  no_driving_convictions?: Maybe<Scalars['Int']>;
  has_criminal_conviction?: Maybe<Scalars['Boolean']>;
  has_driving_convictions?: Maybe<Scalars['Boolean']>;
  has_informed_dvla_medical_conditions?: Maybe<Scalars['Boolean']>;
  relationship?: Maybe<Scalars['String']>;
};

export type AdditionalDriverInput = {
  first_names?: Maybe<Scalars['String']>;
  last_names?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  claims: Array<Maybe<ClaimInput>>;
  business?: Maybe<Scalars['String']>;
  born_in_uk?: Maybe<Scalars['Boolean']>;
  employment?: Maybe<Scalars['String']>;
  has_claims?: Maybe<Scalars['Boolean']>;
  occupation?: Maybe<Scalars['String']>;
  licence_type?: Maybe<Scalars['String']>;
  residency_date?: Maybe<Scalars['String']>;
  no_claims_selected?: Maybe<Scalars['Int']>;
  licence_issued_date?: Maybe<Scalars['String']>;
  has_medical_conditions?: Maybe<Scalars['Boolean']>;
  convictions: Array<Maybe<ConvictionInput>>;
  no_driving_convictions?: Maybe<Scalars['Int']>;
  has_criminal_conviction?: Maybe<Scalars['Boolean']>;
  has_driving_convictions?: Maybe<Scalars['Boolean']>;
  has_informed_dvla_medical_conditions?: Maybe<Scalars['Boolean']>;
  relationship?: Maybe<Scalars['String']>;
};

export type Address = {
  __typename?: 'Address';
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  line_1?: Maybe<Scalars['String']>;
  line_2?: Maybe<Scalars['String']>;
  line_3?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type AddressInput = {
  city?: Maybe<Scalars['String']>;
  county?: Maybe<Scalars['String']>;
  line_1?: Maybe<Scalars['String']>;
  line_2?: Maybe<Scalars['String']>;
  line_3?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  postcode?: Maybe<Scalars['String']>;
};

export type Allocation = {
  __typename?: 'Allocation';
  experimentId?: Maybe<Scalars['String']>;
  variant?: Maybe<Scalars['String']>;
  customerId?: Maybe<Scalars['String']>;
  modified?: Maybe<Scalars['String']>;
  entity?: Maybe<Scalars['String']>;
  created?: Maybe<Scalars['String']>;
};

export type Annual = {
  __typename?: 'Annual';
  fees: Scalars['String'];
  mta_fee: Scalars['String'];
  upfront: Scalars['String'];
  deposit: Scalars['String'];
  pm: Scalars['String'];
  estimate: Scalars['String'];
  underwriter?: Maybe<Insurer>;
  cancellation_fee: Scalars['String'];
  non_returned_fee: Scalars['String'];
  monthly_total_cost_estimate: Scalars['String'];
};

export type AuthenticationInput = {
  dob: Scalars['String'];
  lastName: Scalars['String'];
  postcode: Scalars['String'];
};

export type Claim = {
  __typename?: 'Claim';
  code: Scalars['String'];
  code_formatted?: Maybe<Scalars['String']>;
  at_fault: Scalars['Boolean'];
  at_fault_formatted?: Maybe<Scalars['String']>;
  ncd_lost: Scalars['Boolean'];
  ncd_lost_formatted?: Maybe<Scalars['String']>;
  by_miles_claim?: Maybe<Scalars['Boolean']>;
  date: Scalars['String'];
  original?: Maybe<Scalars['Boolean']>;
};

export type ClaimInput = {
  code: Scalars['String'];
  code_formatted?: Maybe<Scalars['String']>;
  at_fault: Scalars['Boolean'];
  at_fault_formatted?: Maybe<Scalars['String']>;
  ncd_lost: Scalars['Boolean'];
  ncd_lost_formatted?: Maybe<Scalars['String']>;
  by_miles_claim?: Maybe<Scalars['Boolean']>;
  date: Scalars['String'];
  original?: Maybe<Scalars['Boolean']>;
};

export type ClaimListItem = {
  __typename?: 'ClaimListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type CompletePurchaseError = {
  __typename?: 'CompletePurchaseError';
  type: Scalars['String'];
  message: Scalars['String'];
};

export type CompletePurchaseInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  paymentIntentId: Scalars['String'];
  paymentMethodId: Scalars['String'];
  gaToken?: Maybe<Scalars['String']>;
};

export type CompletePurchaseResult = CompletePurchaseSuccess | CompletePurchaseError;

export type CompletePurchaseSuccess = {
  __typename?: 'CompletePurchaseSuccess';
  policyReference?: Maybe<Scalars['String']>;
};

export enum ConnectedCarCompatibility {
  Yes = 'Yes',
  No = 'No',
  YesWithConnection = 'YesWithConnection'
}

export enum ConnectionType {
  Obd = 'obd',
  Odometer = 'odometer'
}

export type ConnectionTypes = {
  __typename?: 'ConnectionTypes';
  Obd: Scalars['Boolean'];
  OdometerAlways: Scalars['Boolean'];
  OdometerWithData: Scalars['Boolean'];
};

export type Conviction = {
  __typename?: 'Conviction';
  code: Scalars['String'];
  ban: Scalars['Int'];
  points: Scalars['Int'];
  date: Scalars['String'];
  code_formatted?: Maybe<Scalars['String']>;
  date_formatted?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['Boolean']>;
};

export type ConvictionInput = {
  code: Scalars['String'];
  ban: Scalars['Int'];
  points: Scalars['Int'];
  date: Scalars['String'];
  code_formatted?: Maybe<Scalars['String']>;
  date_formatted?: Maybe<Scalars['String']>;
  original?: Maybe<Scalars['Boolean']>;
};

export type ConvictionListItem = {
  __typename?: 'ConvictionListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  label: Scalars['String'];
  value: Scalars['String'];
};

export type CreatePaymentIntentPayload = {
  __typename?: 'CreatePaymentIntentPayload';
  /** Provided by Stripe to be used on the client when confirming a card payment. */
  clientSecret?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type CreateQuotePayload = {
  __typename?: 'CreateQuotePayload';
  quote?: Maybe<Quote>;
};

export type Details = {
  __typename?: 'Details';
  site?: Maybe<Site>;
  sid?: Maybe<Scalars['String']>;
  vehicle: Vehicle;
  proposer?: Maybe<Proposer>;
  policy?: Maybe<Policy>;
  additional_drivers?: Maybe<Array<Maybe<AdditionalDriver>>>;
  payment?: Maybe<Payment>;
  customerId?: Maybe<Scalars['ID']>;
  qqid?: Maybe<Scalars['String']>;
};

export type EmailCheckPayload = {
  __typename?: 'EmailCheckPayload';
  isMember?: Maybe<Scalars['Boolean']>;
};

export type EmploymentListItem = {
  __typename?: 'EmploymentListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type Experiment = {
  __typename?: 'Experiment';
  name: Scalars['String'];
  variant: Scalars['Int'];
  /** @deprecated Field no longer supported */
  value?: Maybe<Scalars['Int']>;
  experimentValue?: Maybe<Scalars['Int']>;
};

export type ExperimentGo = {
  __typename?: 'ExperimentGO';
  id: Scalars['String'];
  variant: Scalars['Int'];
};

export type Extra = {
  __typename?: 'Extra';
  amount: Scalars['Int'];
  amountInPounds: Scalars['Float'];
  category: Scalars['String'];
  code: Scalars['String'];
  description: Scalars['String'];
  name: Scalars['String'];
  selected: Scalars['Boolean'];
  popular?: Maybe<Scalars['Boolean']>;
};

export type Immobiliser = {
  __typename?: 'Immobiliser';
  type?: Maybe<Scalars['String']>;
  check?: Maybe<Scalars['String']>;
  refer?: Maybe<Scalars['Boolean']>;
  excess?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Int']>;
  include?: Maybe<Scalars['Boolean']>;
  description?: Maybe<Scalars['String']>;
  mta_decline?: Maybe<Scalars['Boolean']>;
  mta_warning?: Maybe<Scalars['Boolean']>;
  underwriter?: Maybe<Scalars['String']>;
  cs_explanation?: Maybe<Scalars['String']>;
  override_by_cs?: Maybe<Scalars['Boolean']>;
  override_never?: Maybe<Scalars['Boolean']>;
  renewal_decline?: Maybe<Scalars['Boolean']>;
  override_by_cshead?: Maybe<Scalars['Boolean']>;
  customer_explanation?: Maybe<Scalars['String']>;
};

export type IndustryListItem = {
  __typename?: 'IndustryListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export enum Insurer {
  Axa = 'AXA',
  Wakam = 'Wakam',
  Mulsanne = 'Mulsanne',
  Zurich = 'Zurich',
  Covea = 'Covea',
  Uki = 'Uki'
}

export type LicenceTypeListItem = {
  __typename?: 'LicenceTypeListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type Marketing = {
  __typename?: 'Marketing';
  email?: Maybe<Scalars['Boolean']>;
  post?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Boolean']>;
};

export type MarketingInput = {
  post?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Boolean']>;
};

export type Modification = {
  __typename?: 'Modification';
  code: Scalars['String'];
};

export type ModificationInput = {
  code?: Maybe<Scalars['String']>;
};

export type ModificationListItem = {
  __typename?: 'ModificationListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type Modifications = {
  __typename?: 'Modifications';
  accessories: Array<ModificationListItem>;
  cosmetics: Array<ModificationListItem>;
  functional: Array<ModificationListItem>;
  mechanical: Array<ModificationListItem>;
};

export type Monthly = {
  __typename?: 'Monthly';
  fees: Scalars['String'];
  mta_fee: Scalars['String'];
  upfront: Scalars['String'];
  mon: Scalars['String'];
  deposit: Scalars['String'];
  pm: Scalars['String'];
  estimate: Scalars['String'];
  underwriter?: Maybe<Insurer>;
  cancellation_fee: Scalars['String'];
  non_returned_fee: Scalars['String'];
  monthly_total_cost_estimate: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * This mutation creates a new quote at the start of the quoteflow process when the
   * only information we have is the reg.
   *
   * It retrieves the vehicle details from Dealership using the reg.
   *
   * It then creates a new quote with the vehicle information, ready for further mutations
   * throughout the quoteflow.
   */
  createQuote: CreateQuotePayload;
  /**
   * Vehicle changes (a new reg) require the already captured vehicle information to be replaced with the new
   * vehicle information whilst ensuring the rest of the quote details are retained.
   */
  changeVehicle: CreateQuotePayload;
  /**
   * This mutation provides the contract to the business logic when updating a
   * quote. There a few processes which could be invoked as a result of an update.
   *
   * #### Pricing and Generating A New Quote
   * If a pricing sensitive quote property is updated then a new price is
   * required. This results in a new quote row being persisted in the database
   * with a newly generated UUID which ensures a record is kept of all the
   * different prices returned to customers rather than overwriting a quote in place.
   *
   * #### Quote Confirmation Email
   * The quote confirmation email is sent to a customer if the following are true:
   * - The quote did not originate with a partner who contractually prohibits us
   *   sending emails.
   * - No previous email has been sent or a new price has been created following
   *   updates.
   */
  updateQuote: UpdateQuotePayload;
  /** Creates a payment intent with Stripe. */
  createPaymentIntent: CreatePaymentIntentPayload;
  /** Creates the policy via engine and triggers the Google Analytics Transaction event. */
  completePurchase?: Maybe<CompletePurchaseResult>;
  /**
   * If a Stripe transaction fails to complete for whatever reason then we must
   * attempt to cancel the payment intent via the Stripe APIs otherwise the
   * customer funds will remain withheld from the customer.
   */
  cancelPaymentIntent: Scalars['ID'];
  /** Sends an email to the customer so they can retrieve their quote later on. */
  sendSaveQuoteEmail: SendSaveQuoteEmailPayload;
  /**
   * This mutation has been deprecated following the migration of [Stripe
   * SCA](https://stripe.com/docs/strong-customer-authentication) compliant
   * checkout.
   *
   * Instead use the `createPaymentIntent`, `completePurchase` or `cancelPaymentIntent` mutations.
   * @deprecated Field no longer supported
   */
  purchasePolicy?: Maybe<PurchasePolicyPayload>;
  /**
   * The addition of the `refreshPrice` override parameter to the `updateQuote`
   * mutation made this mutation redundant and it is therefore deprecated.
   * @deprecated Field no longer supported
   */
  priceQuote: PriceQuotePayload;
  /**
   * The addition of the `sendEmail` override parameter to the `updateQuote`
   * mutation made this mutation redundant and it is therefore deprecated.
   * @deprecated Field no longer supported
   */
  sendPriceEmail: SendPriceEmailPayload;
};


export type MutationCreateQuoteArgs = {
  reg: Scalars['String'];
  sid?: Maybe<Scalars['String']>;
  qqid?: Maybe<Scalars['String']>;
};


export type MutationChangeVehicleArgs = {
  id: Scalars['ID'];
  reg: Scalars['String'];
};


export type MutationUpdateQuoteArgs = {
  id: Scalars['ID'];
  actions?: Maybe<Array<UpdateQuoteAction>>;
  refreshPrice?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
};


export type MutationCreatePaymentIntentArgs = {
  id: Scalars['ID'];
  nameOnCard: Scalars['String'];
  billingPostcode: Scalars['String'];
};


export type MutationCompletePurchaseArgs = {
  id: Scalars['ID'];
  input: CompletePurchaseInput;
};


export type MutationCancelPaymentIntentArgs = {
  id: Scalars['ID'];
};


export type MutationSendSaveQuoteEmailArgs = {
  id: Scalars['ID'];
};


export type MutationPurchasePolicyArgs = {
  id: Scalars['ID'];
  input: PurchasePolicyInput;
};


export type MutationPriceQuoteArgs = {
  id: Scalars['ID'];
};


export type MutationSendPriceEmailArgs = {
  id: Scalars['ID'];
};

export type OccupationListItem = {
  __typename?: 'OccupationListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type OtherVehicleRelationshipListItem = {
  __typename?: 'OtherVehicleRelationshipListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type OwnerListItem = {
  __typename?: 'OwnerListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type Payment = {
  __typename?: 'Payment';
  billingDay?: Maybe<Scalars['Int']>;
};

export enum PaymentType {
  Monthly = 'Monthly',
  Yearly = 'Yearly',
  RefundAtRenewalYearly = 'RefundAtRenewalYearly'
}

export type Policy = {
  __typename?: 'Policy';
  promo?: Maybe<Scalars['String']>;
  excess?: Maybe<Scalars['Int']>;
  address: Address;
  inception_date?: Maybe<Scalars['String']>;
  /** @deprecated Field no longer supported */
  connection_type: Scalars['String'];
  payment_frequency?: Maybe<PremiumPaymentFrequency>;
  refund_at_renewal?: Maybe<Scalars['Boolean']>;
  connectionType: ConnectionType;
  isTrackerless: Scalars['Boolean'];
  paymentType: PaymentType;
  requested_payment_frequency?: Maybe<RequestedPaymentFrequency>;
  current?: Maybe<Scalars['Int']>;
};

export enum PremiumPaymentFrequency {
  Monthly = 'monthly',
  Yearly = 'yearly'
}

export type PriceQuotePayload = {
  __typename?: 'PriceQuotePayload';
  quote: Quote;
};

export type Pricing = {
  __typename?: 'Pricing';
  isValid: Scalars['Boolean'];
  initialQuoteDate: Scalars['String'];
  perMileRate: Scalars['Float'];
  fixedUpfrontCost: Scalars['String'];
  insurer: Insurer;
  insurerName: Scalars['String'];
  usage: Scalars['String'];
  usageCode?: Maybe<Scalars['String']>;
  total?: Maybe<Total>;
  annual?: Maybe<Annual>;
  monthly?: Maybe<Monthly>;
  trackerNotReturnedFee?: Maybe<Scalars['String']>;
  cancellationFee?: Maybe<Scalars['String']>;
  fees?: Maybe<Scalars['String']>;
  insurancePremiumTax?: Maybe<Scalars['Float']>;
  isMonthlyEligible: Scalars['Boolean'];
  compulsoryExcess?: Maybe<Scalars['Int']>;
  immobiliser?: Maybe<Array<Maybe<Immobiliser>>>;
  connectionTypes?: Maybe<ConnectionTypes>;
};

export type Promo = {
  __typename?: 'Promo';
  amount: Scalars['Float'];
  code: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  isValid: Scalars['Boolean'];
  title?: Maybe<Scalars['String']>;
  type: Scalars['String'];
};

export type Proposer = {
  __typename?: 'Proposer';
  first_names?: Maybe<Scalars['String']>;
  last_names?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  ncd?: Maybe<Scalars['Int']>;
  children?: Maybe<Scalars['Int']>;
  marketing?: Maybe<Marketing>;
  mobile_number?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  claims?: Maybe<Array<Maybe<Claim>>>;
  address?: Maybe<Address>;
  business?: Maybe<Scalars['String']>;
  born_in_uk?: Maybe<Scalars['Boolean']>;
  employment?: Maybe<Scalars['String']>;
  has_claims?: Maybe<Scalars['Boolean']>;
  occupation?: Maybe<Scalars['String']>;
  licence_type?: Maybe<Scalars['String']>;
  residency_date?: Maybe<Scalars['String']>;
  intro_ncd_awarded?: Maybe<Scalars['Int']>;
  other_vehicle_ncd?: Maybe<Scalars['Int']>;
  no_claims_selected?: Maybe<Scalars['Int']>;
  licence_issued_date?: Maybe<Scalars['String']>;
  drives_other_vehicle?: Maybe<Scalars['Boolean']>;
  has_medical_conditions?: Maybe<Scalars['Boolean']>;
  convictions?: Maybe<Array<Maybe<Conviction>>>;
  no_driving_convictions?: Maybe<Scalars['Int']>;
  has_criminal_conviction?: Maybe<Scalars['Boolean']>;
  has_driving_convictions?: Maybe<Scalars['Boolean']>;
  other_vehicle_relationship?: Maybe<Scalars['String']>;
  has_informed_dvla_medical_conditions?: Maybe<Scalars['Boolean']>;
};

export type PurchasePolicyInput = {
  email: Scalars['String'];
  password: Scalars['String'];
  stripeToken: Scalars['String'];
  gaToken?: Maybe<Scalars['String']>;
};

export type PurchasePolicyPayload = {
  __typename?: 'PurchasePolicyPayload';
  /** @deprecated Field no longer supported */
  quote?: Maybe<Quote>;
  policyReference?: Maybe<Scalars['String']>;
};

export type Query = {
  __typename?: 'Query';
  validatePromo: ValidatePromoPayload;
  quote: Quote;
  emailCheck?: Maybe<EmailCheckPayload>;
  retrieveQuoteByReg: Quote;
  retrieveQuoteById: Quote;
  industries: Array<IndustryListItem>;
  occupations: Array<OccupationListItem>;
  modifications?: Maybe<Modifications>;
  owners: Array<OwnerListItem>;
  employment: Array<EmploymentListItem>;
  licenceType: Array<LicenceTypeListItem>;
  titles: Array<TitleListItem>;
  claims: Array<ClaimListItem>;
  convictions: Array<ConvictionListItem>;
  relationships: Array<RelationshipListItem>;
  otherVehicleRelationship: Array<OtherVehicleRelationshipListItem>;
};


export type QueryValidatePromoArgs = {
  code: Scalars['String'];
  returnName?: Maybe<Scalars['Boolean']>;
};


export type QueryQuoteArgs = {
  id: Scalars['ID'];
};


export type QueryEmailCheckArgs = {
  email?: Maybe<Scalars['String']>;
};


export type QueryRetrieveQuoteByRegArgs = {
  reg?: Maybe<Scalars['String']>;
  input?: Maybe<AuthenticationInput>;
};


export type QueryRetrieveQuoteByIdArgs = {
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AuthenticationInput>;
};


export type QueryConvictionsArgs = {
  searchText?: Maybe<Scalars['String']>;
};

export type Quote = {
  __typename?: 'Quote';
  id: Scalars['ID'];
  status?: Maybe<QuoteStatus>;
  dtl?: Maybe<Scalars['String']>;
  details: Details;
  pricing?: Maybe<Pricing>;
  promo?: Maybe<Promo>;
  email?: Maybe<Scalars['String']>;
  isAllowListed: Scalars['Boolean'];
  deliveryAddress?: Maybe<Address>;
  extras: Array<Maybe<Extra>>;
  possibleVoluntaryExcesses: Array<Scalars['Int']>;
  isPotentialRefundAtRenewal: Scalars['Boolean'];
  priceComparisonSiteOriginated: Scalars['Boolean'];
  experiments?: Maybe<Array<ExperimentGo>>;
  serverSideAllocation?: Maybe<Array<Maybe<Allocation>>>;
};


export type QuoteExtrasArgs = {
  category?: Maybe<Scalars['String']>;
  selected?: Maybe<Scalars['Boolean']>;
};

export type QuoteStatus = {
  __typename?: 'QuoteStatus';
  status?: Maybe<Scalars['String']>;
  sections?: Maybe<Sections>;
};

export type RelationshipListItem = {
  __typename?: 'RelationshipListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export enum RequestedPaymentFrequency {
  Monthly = 'monthly',
  Annually = 'annually'
}

export type RetrieveQuote = {
  lastNames: Scalars['String'];
  dob: Scalars['String'];
  postcode: Scalars['String'];
  reg: Scalars['String'];
};

export type RetrieveQuotePayload = {
  __typename?: 'RetrieveQuotePayload';
  quote?: Maybe<Quote>;
};

export type SectionStatus = {
  __typename?: 'SectionStatus';
  status?: Maybe<Scalars['String']>;
  missingFields?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Sections = {
  __typename?: 'Sections';
  vehicle?: Maybe<SectionStatus>;
  proposer?: Maybe<SectionStatus>;
  policy?: Maybe<SectionStatus>;
  additionalDriver?: Maybe<SectionStatus>;
};

export type SendPriceEmailPayload = {
  __typename?: 'SendPriceEmailPayload';
  success: Scalars['Boolean'];
};

export type SetAdditionalDrivers = {
  additionalDriver: Array<Maybe<AdditionalDriverInput>>;
};

export type SetBillingDay = {
  billingDay: Scalars['Int'];
};

export type SetConfirmed = {
  confirmed: Scalars['Boolean'];
};

export type SetConnectionType = {
  connectionType: ConnectionType;
};

export type SetCurrentRenewalPrice = {
  current: Scalars['Int'];
};

export type SetDeliveryAddress = {
  deliveryAddress: AddressInput;
};

export type SetEmail = {
  email: Scalars['String'];
};

export type SetEstimatedMiles = {
  estimatedMiles: Scalars['Int'];
};

export type SetExperimentVariant = {
  experiment: Scalars['String'];
  variant: Scalars['Int'];
};

export type SetInceptionDate = {
  inceptionDate: Scalars['String'];
  setBillingDay?: Maybe<Scalars['Boolean']>;
};

export type SetLastLocation = {
  lastLocation: Scalars['String'];
};

export type SetMarketingPreferences = {
  email?: Maybe<Scalars['Boolean']>;
  phone?: Maybe<Scalars['Boolean']>;
  post?: Maybe<Scalars['Boolean']>;
};

export type SetMobileNumber = {
  mobileNumber: Scalars['String'];
};

export type SetPaymentType = {
  paymentType: PaymentType;
};

export type SetPolicyAddress = {
  address: AddressInput;
};

export type SetPremiumPaymentFrequency = {
  premiumPaymentFrequency: PremiumPaymentFrequency;
};

export type SetProposer = {
  title?: Maybe<Scalars['String']>;
  firstNames?: Maybe<Scalars['String']>;
  lastNames?: Maybe<Scalars['String']>;
  dob?: Maybe<Scalars['String']>;
  ncd?: Maybe<Scalars['Int']>;
  children?: Maybe<Scalars['Int']>;
  mobileNumber?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  claims?: Maybe<Array<Maybe<ClaimInput>>>;
  address?: Maybe<AddressInput>;
  business?: Maybe<Scalars['String']>;
  marketing?: Maybe<MarketingInput>;
  bornInUk?: Maybe<Scalars['Boolean']>;
  employment?: Maybe<Scalars['String']>;
  hasClaims?: Maybe<Scalars['Boolean']>;
  occupation?: Maybe<Scalars['String']>;
  licenceType?: Maybe<Scalars['String']>;
  residencyDate?: Maybe<Scalars['String']>;
  introNcdAwarded?: Maybe<Scalars['Int']>;
  otherVehicleNcd?: Maybe<Scalars['Int']>;
  noClaimsSelected?: Maybe<Scalars['Int']>;
  licenceIssuedDate?: Maybe<Scalars['String']>;
  drivesOtherVehicle?: Maybe<Scalars['Boolean']>;
  hasMedicalConditions?: Maybe<Scalars['Boolean']>;
  convictions?: Maybe<Array<Maybe<ConvictionInput>>>;
  noDrivingConvictions?: Maybe<Scalars['Int']>;
  hasCriminalConviction?: Maybe<Scalars['Boolean']>;
  hasDrivingConvictions?: Maybe<Scalars['Boolean']>;
  otherVehicleRelationship?: Maybe<Scalars['String']>;
  hasInformedDvlaMedicalConditions?: Maybe<Scalars['Boolean']>;
};

export type SetVehicle = {
  address?: Maybe<VehicleAddressInput>;
  estimated_mileage?: Maybe<Scalars['Int']>;
  is_parked_home?: Maybe<Scalars['Boolean']>;
  keeper?: Maybe<Scalars['String']>;
  modifications?: Maybe<Array<Maybe<ModificationInput>>>;
  has_modifications?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<Scalars['String']>;
  purchase_date?: Maybe<Scalars['String']>;
  same_mileage?: Maybe<Scalars['Boolean']>;
  purchase_date_boolean?: Maybe<Scalars['Boolean']>;
};

export type SetVoluntaryExcess = {
  voluntaryExcess: Scalars['Int'];
};

export type Site = {
  __typename?: 'Site';
  introNcdSaved?: Maybe<Scalars['Boolean']>;
  selfServePath?: Maybe<Scalars['Boolean']>;
  selfServeSubPath?: Maybe<Scalars['Boolean']>;
  shown_assumptions?: Maybe<Scalars['Boolean']>;
  tracker_activation?: Maybe<Scalars['Boolean']>;
  introNcdSaveRequired?: Maybe<Scalars['Boolean']>;
  renderBreakdownTiers?: Maybe<Scalars['Boolean']>;
  shownIntro?: Maybe<Scalars['Boolean']>;
  confirmed?: Maybe<Scalars['Boolean']>;
  trackerConfirmed?: Maybe<Scalars['Boolean']>;
  docsConfirmed?: Maybe<Scalars['Boolean']>;
  freeLegal?: Maybe<Scalars['Boolean']>;
  wasOfferedFreeLegal?: Maybe<Scalars['Boolean']>;
  experiments?: Maybe<Array<Maybe<Allocation>>>;
};

export type TitleListItem = {
  __typename?: 'TitleListItem';
  const: Scalars['String'];
  title: Scalars['String'];
  value: Scalars['String'];
  label: Scalars['String'];
};

export type Total = {
  __typename?: 'Total';
  estimate: Scalars['String'];
  annual: Scalars['String'];
};

/** Actions that update the quote. Some actions trigger a reprice and some do not. */
export type UpdateQuoteAction = {
  /** Does not trigger a reprice */
  setProposer?: Maybe<SetProposer>;
  /** Does not trigger a reprice */
  setVehicle?: Maybe<SetVehicle>;
  /** Does not trigger a reprice */
  setPolicyAddress?: Maybe<SetPolicyAddress>;
  /** Does not trigger reprice. */
  setBillingDay?: Maybe<SetBillingDay>;
  /** Triggers a reprice if quote is complete. InceptionDate should be an isoString. */
  setInceptionDate?: Maybe<SetInceptionDate>;
  /** Does not trigger reprice. */
  setAdditionalDrivers?: Maybe<SetAdditionalDrivers>;
  /** Does not trigger reprice */
  setDeliveryAddress?: Maybe<SetDeliveryAddress>;
  /** Does not trigger reprice. */
  setEmail?: Maybe<SetEmail>;
  /** Does not trigger reprice. */
  setEstimatedMiles?: Maybe<SetEstimatedMiles>;
  /** Does not trigger a reprice. */
  setVoluntaryExcess?: Maybe<SetVoluntaryExcess>;
  /** Does not trigger reprice. */
  addLegalCover?: Maybe<AddLegalCover>;
  /** Does not trigger a reprice. */
  removeLegalCover?: Maybe<Scalars['Boolean']>;
  /** Does not trigger a reprice. */
  addBreakdownCover?: Maybe<AddBreakdownCover>;
  /** Does not trigger a reprice. */
  removeBreakdownCover?: Maybe<Scalars['Boolean']>;
  /** Does not trigger a reprice. */
  addCarHireCover?: Maybe<AddCarHireCover>;
  /** Does not trigger a reprice. */
  removeCarHireCover?: Maybe<Scalars['Boolean']>;
  /** Does not trigger a reprice. */
  setMarketingPreferences?: Maybe<SetMarketingPreferences>;
  /**
   * Does not trigger a reprice.
   *
   * This update quote action was deprecated when ByMiles Money Back (BMMB) payment
   * type was introduced. Use instead the `setPaymentType` update action.
   */
  setPremiumPaymentFrequency?: Maybe<SetPremiumPaymentFrequency>;
  /** Does not trigger a reprice. */
  setPaymentType?: Maybe<SetPaymentType>;
  /** Does not trigger a reprice. */
  setMobileNumber?: Maybe<SetMobileNumber>;
  /** Does not trigger a reprice. */
  shownIntro?: Maybe<Scalars['Boolean']>;
  /** Does not trigger a reprice. */
  setLastLocation?: Maybe<SetLastLocation>;
  /** Does not trigger a reprice. */
  setExperimentVariant?: Maybe<SetExperimentVariant>;
  /** Does not trigger a reprice. */
  confirmed?: Maybe<SetConfirmed>;
  /** Does not trigger a reprice. */
  trackerConfirmed?: Maybe<SetConfirmed>;
  /** Does not trigger a reprice. */
  docsConfirmed?: Maybe<SetConfirmed>;
  /** Does not trigger a reprice. */
  setConnectionType?: Maybe<SetConnectionType>;
  /** Does not trigger a reprice. */
  addGAClientId?: Maybe<AddGaClientId>;
  /** Does not trigger a reprice. */
  setCurrentRenewalPrice?: Maybe<SetCurrentRenewalPrice>;
};

export type UpdateQuotePayload = {
  __typename?: 'UpdateQuotePayload';
  quote: Quote;
  pricing?: Maybe<Pricing>;
};

export type ValidatePromoPayload = {
  __typename?: 'ValidatePromoPayload';
  promo: Promo;
};

export type Vehicle = {
  __typename?: 'Vehicle';
  abi_code: Scalars['String'];
  address?: Maybe<VehicleAddress>;
  bymiles_make: Scalars['String'];
  bymiles_model: Scalars['String'];
  bymiles_trim: Scalars['String'];
  co2: Scalars['String'];
  colour: Scalars['String'];
  current_value: Scalars['Int'];
  doors: Scalars['String'];
  engine: Scalars['Int'];
  estimated_mileage?: Maybe<Scalars['Int']>;
  euro_status: Scalars['String'];
  fuel: Scalars['String'];
  full_cc: Scalars['Boolean'];
  has_modifications?: Maybe<Scalars['Boolean']>;
  initial_value: Scalars['Int'];
  is_import: Scalars['Boolean'];
  is_parked_home?: Maybe<Scalars['Boolean']>;
  is_rhd: Scalars['Boolean'];
  keeper?: Maybe<Scalars['String']>;
  keyless: Scalars['Boolean'];
  make: Scalars['String'];
  manufacture_date: Scalars['String'];
  mileage: Scalars['Int'];
  model: Scalars['String'];
  modifications?: Maybe<Array<Maybe<Modification>>>;
  no_modifications?: Maybe<Scalars['Int']>;
  obd_compatible: Scalars['Boolean'];
  obd_fit: Scalars['String'];
  obd_helper_text: Scalars['String'];
  obd_location: Scalars['String'];
  obd_text: Scalars['String'];
  odometer_cc: Scalars['Boolean'];
  owner?: Maybe<Scalars['String']>;
  purchase_date_boolean?: Maybe<Scalars['Boolean']>;
  purchase_date: Scalars['String'];
  rating: Scalars['Int'];
  reg: Scalars['String'];
  same_mileage?: Maybe<Scalars['Boolean']>;
  seats: Scalars['Int'];
  security: Scalars['String'];
  size: Scalars['Int'];
  transmission: Scalars['String'];
  trim?: Maybe<Scalars['String']>;
  type: Scalars['String'];
  vin: Scalars['String'];
  manufacturerApp?: Maybe<Scalars['String']>;
  manufacturerAppAccount?: Maybe<Scalars['String']>;
  isElectricVehicle: Scalars['Boolean'];
  connectedCar: ConnectedCarCompatibility;
  parentCompany?: Maybe<Scalars['String']>;
};

export type VehicleAddress = {
  __typename?: 'VehicleAddress';
  postcode?: Maybe<Scalars['String']>;
};

/** Need to get the other items here */
export type VehicleAddressInput = {
  postcode: Scalars['String'];
};

export type SendSaveQuoteEmailPayload = {
  __typename?: 'sendSaveQuoteEmailPayload';
  success: Scalars['Boolean'];
};

export type CancelPaymentIntentMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelPaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelPaymentIntent'>
);

export type ChangeVehicleMutationVariables = Exact<{
  id: Scalars['ID'];
  reg: Scalars['String'];
}>;


export type ChangeVehicleMutation = (
  { __typename?: 'Mutation' }
  & { changeVehicle: (
    { __typename?: 'CreateQuotePayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & QuoteMainFragment
    )> }
  ) }
);

export type ClaimsQueryVariables = Exact<{ [key: string]: never; }>;


export type ClaimsQuery = (
  { __typename?: 'Query' }
  & { claims: Array<(
    { __typename?: 'ClaimListItem' }
    & Pick<ClaimListItem, 'label' | 'value'>
  )> }
);

export type CompletePurchaseMutationVariables = Exact<{
  id: Scalars['ID'];
  input: CompletePurchaseInput;
}>;


export type CompletePurchaseMutation = (
  { __typename?: 'Mutation' }
  & { completePurchase?: Maybe<(
    { __typename?: 'CompletePurchaseSuccess' }
    & Pick<CompletePurchaseSuccess, 'policyReference'>
  ) | (
    { __typename?: 'CompletePurchaseError' }
    & Pick<CompletePurchaseError, 'type' | 'message'>
  )> }
);

export type ConvictionsQueryVariables = Exact<{
  searchText: Scalars['String'];
}>;


export type ConvictionsQuery = (
  { __typename?: 'Query' }
  & { convictions: Array<(
    { __typename?: 'ConvictionListItem' }
    & Pick<ConvictionListItem, 'label' | 'value'>
  )> }
);

export type CreatePaymentIntentMutationVariables = Exact<{
  id: Scalars['ID'];
  nameOnCard: Scalars['String'];
  billingPostcode: Scalars['String'];
}>;


export type CreatePaymentIntentMutation = (
  { __typename?: 'Mutation' }
  & { createPaymentIntent: (
    { __typename?: 'CreatePaymentIntentPayload' }
    & Pick<CreatePaymentIntentPayload, 'clientSecret' | 'message'>
  ) }
);

export type CreateQuoteMutationVariables = Exact<{
  reg: Scalars['String'];
  sid: Scalars['String'];
  qqid: Scalars['String'];
}>;


export type CreateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { createQuote: (
    { __typename?: 'CreateQuotePayload' }
    & { quote?: Maybe<(
      { __typename?: 'Quote' }
      & QuoteMainFragment
    )> }
  ) }
);

export type EmailCheckQueryVariables = Exact<{
  email?: Maybe<Scalars['String']>;
}>;


export type EmailCheckQuery = (
  { __typename?: 'Query' }
  & { emailCheck?: Maybe<(
    { __typename?: 'EmailCheckPayload' }
    & Pick<EmailCheckPayload, 'isMember'>
  )> }
);

export type EmploymentQueryVariables = Exact<{ [key: string]: never; }>;


export type EmploymentQuery = (
  { __typename?: 'Query' }
  & { employment: Array<(
    { __typename?: 'EmploymentListItem' }
    & Pick<EmploymentListItem, 'label' | 'value'>
  )> }
);

export type ExtraMainFragment = (
  { __typename?: 'Extra' }
  & Pick<Extra, 'amount' | 'amountInPounds' | 'category' | 'code' | 'description' | 'name' | 'selected'>
);

export type IndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type IndustriesQuery = (
  { __typename?: 'Query' }
  & { industries: Array<(
    { __typename?: 'IndustryListItem' }
    & Pick<IndustryListItem, 'label' | 'value'>
  )> }
);

export type LicenceTypeQueryVariables = Exact<{ [key: string]: never; }>;


export type LicenceTypeQuery = (
  { __typename?: 'Query' }
  & { licenceType: Array<(
    { __typename?: 'LicenceTypeListItem' }
    & Pick<LicenceTypeListItem, 'label' | 'value'>
  )> }
);

export type ModificationsQueryVariables = Exact<{ [key: string]: never; }>;


export type ModificationsQuery = (
  { __typename?: 'Query' }
  & { modifications?: Maybe<(
    { __typename?: 'Modifications' }
    & { cosmetics: Array<(
      { __typename?: 'ModificationListItem' }
      & Pick<ModificationListItem, 'value' | 'label'>
    )>, accessories: Array<(
      { __typename?: 'ModificationListItem' }
      & Pick<ModificationListItem, 'value' | 'label'>
    )>, functional: Array<(
      { __typename?: 'ModificationListItem' }
      & Pick<ModificationListItem, 'value' | 'label'>
    )>, mechanical: Array<(
      { __typename?: 'ModificationListItem' }
      & Pick<ModificationListItem, 'value' | 'label'>
    )> }
  )> }
);

export type OccupationsQueryVariables = Exact<{ [key: string]: never; }>;


export type OccupationsQuery = (
  { __typename?: 'Query' }
  & { occupations: Array<(
    { __typename?: 'OccupationListItem' }
    & Pick<OccupationListItem, 'label' | 'value'>
  )> }
);

export type OwnersQueryVariables = Exact<{ [key: string]: never; }>;


export type OwnersQuery = (
  { __typename?: 'Query' }
  & { owners: Array<(
    { __typename?: 'OwnerListItem' }
    & Pick<OwnerListItem, 'label' | 'value'>
  )> }
);

export type QuoteMainFragment = (
  { __typename?: 'Quote' }
  & Pick<Quote, 'id' | 'email' | 'isAllowListed' | 'possibleVoluntaryExcesses' | 'isPotentialRefundAtRenewal' | 'priceComparisonSiteOriginated'>
  & { extras: Array<Maybe<(
    { __typename?: 'Extra' }
    & ExtraMainFragment
  )>>, breakdownCoverOptions: Array<Maybe<(
    { __typename?: 'Extra' }
    & ExtraMainFragment
  )>>, legalCoverOptions: Array<Maybe<(
    { __typename?: 'Extra' }
    & ExtraMainFragment
  )>>, carHireCoverOptions: Array<Maybe<(
    { __typename?: 'Extra' }
    & ExtraMainFragment
  )>>, selectedExtras: Array<Maybe<(
    { __typename?: 'Extra' }
    & ExtraMainFragment
  )>>, experiments?: Maybe<Array<(
    { __typename?: 'ExperimentGO' }
    & Pick<ExperimentGo, 'id' | 'variant'>
  )>>, serverSideAllocation?: Maybe<Array<Maybe<(
    { __typename?: 'Allocation' }
    & Pick<Allocation, 'experimentId' | 'variant'>
  )>>>, details: (
    { __typename?: 'Details' }
    & Pick<Details, 'qqid' | 'sid' | 'customerId'>
    & { site?: Maybe<(
      { __typename?: 'Site' }
      & Pick<Site, 'shownIntro' | 'confirmed' | 'trackerConfirmed' | 'docsConfirmed' | 'wasOfferedFreeLegal'>
      & { experiments?: Maybe<Array<Maybe<(
        { __typename?: 'Allocation' }
        & Pick<Allocation, 'experimentId' | 'variant' | 'customerId'>
      )>>> }
    )>, proposer?: Maybe<(
      { __typename?: 'Proposer' }
      & Pick<Proposer, 'first_names' | 'last_names' | 'title' | 'ncd' | 'children' | 'email' | 'mobile_number' | 'dob' | 'employment' | 'business' | 'occupation' | 'has_medical_conditions' | 'has_informed_dvla_medical_conditions' | 'born_in_uk' | 'residency_date' | 'has_claims' | 'has_criminal_conviction' | 'has_driving_convictions' | 'licence_type' | 'licence_issued_date' | 'drives_other_vehicle' | 'other_vehicle_ncd' | 'other_vehicle_relationship'>
      & { convictions?: Maybe<Array<Maybe<(
        { __typename?: 'Conviction' }
        & Pick<Conviction, 'code' | 'ban' | 'date' | 'points'>
      )>>>, marketing?: Maybe<(
        { __typename?: 'Marketing' }
        & Pick<Marketing, 'email' | 'phone' | 'post'>
      )>, address?: Maybe<(
        { __typename?: 'Address' }
        & Pick<Address, 'city' | 'county' | 'country' | 'line_1' | 'line_2' | 'line_3' | 'postcode'>
      )>, claims?: Maybe<Array<Maybe<(
        { __typename?: 'Claim' }
        & Pick<Claim, 'code' | 'at_fault' | 'ncd_lost' | 'date'>
      )>>> }
    )>, vehicle: (
      { __typename?: 'Vehicle' }
      & VehicleMainFragment
    ), policy?: Maybe<(
      { __typename?: 'Policy' }
      & Pick<Policy, 'excess' | 'inception_date' | 'promo' | 'current' | 'payment_frequency' | 'paymentType' | 'isTrackerless' | 'requested_payment_frequency'>
      & { address: (
        { __typename?: 'Address' }
        & Pick<Address, 'line_1' | 'line_2' | 'line_3' | 'postcode' | 'city'>
      ) }
    )>, additional_drivers?: Maybe<Array<Maybe<(
      { __typename?: 'AdditionalDriver' }
      & Pick<AdditionalDriver, 'first_names' | 'last_names' | 'title' | 'dob' | 'business' | 'born_in_uk' | 'employment' | 'has_claims' | 'occupation' | 'licence_type' | 'residency_date' | 'licence_issued_date' | 'has_medical_conditions' | 'has_criminal_conviction' | 'has_driving_convictions' | 'has_informed_dvla_medical_conditions' | 'relationship'>
      & { claims: Array<Maybe<(
        { __typename?: 'Claim' }
        & Pick<Claim, 'code' | 'at_fault' | 'ncd_lost' | 'date'>
      )>>, convictions: Array<Maybe<(
        { __typename?: 'Conviction' }
        & Pick<Conviction, 'code' | 'ban' | 'date' | 'points'>
      )>> }
    )>>>, payment?: Maybe<(
      { __typename?: 'Payment' }
      & Pick<Payment, 'billingDay'>
    )> }
  ), pricing?: Maybe<(
    { __typename?: 'Pricing' }
    & Pick<Pricing, 'fixedUpfrontCost' | 'isValid' | 'perMileRate' | 'insurer' | 'insurerName' | 'insurancePremiumTax' | 'cancellationFee' | 'trackerNotReturnedFee' | 'fees' | 'usage' | 'usageCode' | 'compulsoryExcess'>
    & { immobiliser?: Maybe<Array<Maybe<(
      { __typename?: 'Immobiliser' }
      & Pick<Immobiliser, 'type'>
    )>>>, total?: Maybe<(
      { __typename?: 'Total' }
      & Pick<Total, 'estimate' | 'annual'>
    )>, annual?: Maybe<(
      { __typename?: 'Annual' }
      & Pick<Annual, 'fees' | 'mta_fee' | 'upfront' | 'deposit' | 'pm' | 'estimate' | 'underwriter' | 'cancellation_fee' | 'non_returned_fee' | 'monthly_total_cost_estimate'>
    )>, monthly?: Maybe<(
      { __typename?: 'Monthly' }
      & Pick<Monthly, 'fees' | 'mta_fee' | 'upfront' | 'mon' | 'deposit' | 'pm' | 'estimate' | 'underwriter' | 'cancellation_fee' | 'non_returned_fee' | 'monthly_total_cost_estimate'>
    )> }
  )>, promo?: Maybe<(
    { __typename?: 'Promo' }
    & Pick<Promo, 'isValid' | 'amount' | 'title' | 'firstName' | 'lastName' | 'type'>
  )>, deliveryAddress?: Maybe<(
    { __typename?: 'Address' }
    & Pick<Address, 'line_1' | 'line_2' | 'line_3' | 'postcode' | 'city'>
  )>, status?: Maybe<(
    { __typename?: 'QuoteStatus' }
    & { sections?: Maybe<(
      { __typename?: 'Sections' }
      & { proposer?: Maybe<(
        { __typename?: 'SectionStatus' }
        & Pick<SectionStatus, 'status' | 'missingFields'>
      )>, vehicle?: Maybe<(
        { __typename?: 'SectionStatus' }
        & Pick<SectionStatus, 'status' | 'missingFields'>
      )>, policy?: Maybe<(
        { __typename?: 'SectionStatus' }
        & Pick<SectionStatus, 'status' | 'missingFields'>
      )>, additionalDriver?: Maybe<(
        { __typename?: 'SectionStatus' }
        & Pick<SectionStatus, 'status' | 'missingFields'>
      )> }
    )> }
  )> }
);

export type QuoteQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type QuoteQuery = (
  { __typename?: 'Query' }
  & { quote: (
    { __typename?: 'Quote' }
    & QuoteMainFragment
  ) }
);

export type RelationshipsQueryVariables = Exact<{ [key: string]: never; }>;


export type RelationshipsQuery = (
  { __typename?: 'Query' }
  & { relationships: Array<(
    { __typename?: 'RelationshipListItem' }
    & Pick<RelationshipListItem, 'label' | 'value'>
  )> }
);

export type RetrieveQuoteByIdQueryVariables = Exact<{
  id?: Maybe<Scalars['ID']>;
  input?: Maybe<AuthenticationInput>;
}>;


export type RetrieveQuoteByIdQuery = (
  { __typename?: 'Query' }
  & { retrieveQuoteById: (
    { __typename?: 'Quote' }
    & Pick<Quote, 'id'>
    & { details: (
      { __typename?: 'Details' }
      & { proposer?: Maybe<(
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'last_names'>
      )>, vehicle: (
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'reg'>
      ) }
    ) }
  ) }
);

export type RetrieveQuoteByRegQueryVariables = Exact<{
  reg?: Maybe<Scalars['String']>;
  input?: Maybe<AuthenticationInput>;
}>;


export type RetrieveQuoteByRegQuery = (
  { __typename?: 'Query' }
  & { retrieveQuoteByReg: (
    { __typename?: 'Quote' }
    & Pick<Quote, 'id'>
    & { details: (
      { __typename?: 'Details' }
      & { proposer?: Maybe<(
        { __typename?: 'Proposer' }
        & Pick<Proposer, 'last_names'>
      )>, vehicle: (
        { __typename?: 'Vehicle' }
        & Pick<Vehicle, 'reg'>
      ) }
    ) }
  ) }
);

export type SendSaveQuoteEmailMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type SendSaveQuoteEmailMutation = (
  { __typename?: 'Mutation' }
  & { sendSaveQuoteEmail: (
    { __typename?: 'sendSaveQuoteEmailPayload' }
    & Pick<SendSaveQuoteEmailPayload, 'success'>
  ) }
);

export type TitlesQueryVariables = Exact<{ [key: string]: never; }>;


export type TitlesQuery = (
  { __typename?: 'Query' }
  & { titles: Array<(
    { __typename?: 'TitleListItem' }
    & Pick<TitleListItem, 'label' | 'value'>
  )> }
);

export type UpdateQuoteMutationVariables = Exact<{
  id: Scalars['ID'];
  actions?: Maybe<Array<UpdateQuoteAction> | UpdateQuoteAction>;
  refreshPrice?: Maybe<Scalars['Boolean']>;
  sendEmail?: Maybe<Scalars['Boolean']>;
}>;


export type UpdateQuoteMutation = (
  { __typename?: 'Mutation' }
  & { updateQuote: (
    { __typename?: 'UpdateQuotePayload' }
    & { quote: (
      { __typename?: 'Quote' }
      & QuoteMainFragment
    ) }
  ) }
);

export type VehicleMainFragment = (
  { __typename?: 'Vehicle' }
  & Pick<Vehicle, 'reg' | 'estimated_mileage' | 'manufacture_date' | 'has_modifications' | 'no_modifications' | 'purchase_date' | 'purchase_date_boolean' | 'keeper' | 'owner' | 'is_parked_home' | 'transmission' | 'is_import' | 'size' | 'fuel' | 'type' | 'is_rhd' | 'current_value' | 'make' | 'model' | 'trim' | 'manufacturerApp' | 'manufacturerAppAccount' | 'isElectricVehicle' | 'obd_location' | 'obd_fit' | 'obd_text' | 'obd_helper_text' | 'connectedCar' | 'parentCompany'>
  & { modifications?: Maybe<Array<Maybe<(
    { __typename?: 'Modification' }
    & Pick<Modification, 'code'>
  )>>>, address?: Maybe<(
    { __typename?: 'VehicleAddress' }
    & Pick<VehicleAddress, 'postcode'>
  )> }
);

export const ExtraMainFragmentDoc = gql`
    fragment ExtraMain on Extra {
  amount
  amountInPounds
  category
  code
  description
  name
  selected
}
    `;
export const VehicleMainFragmentDoc = gql`
    fragment VehicleMain on Vehicle {
  reg
  estimated_mileage
  manufacture_date
  has_modifications
  modifications {
    code
  }
  no_modifications
  purchase_date
  purchase_date_boolean
  keeper
  owner
  is_parked_home
  address {
    postcode
  }
  transmission
  is_import
  size
  fuel
  type
  is_rhd
  current_value
  make
  model
  trim
  manufacturerApp
  manufacturerAppAccount
  isElectricVehicle
  obd_location
  obd_fit
  obd_text
  obd_helper_text
  connectedCar
  parentCompany
}
    `;
export const QuoteMainFragmentDoc = gql`
    fragment QuoteMain on Quote {
  id
  email
  isAllowListed
  possibleVoluntaryExcesses
  isPotentialRefundAtRenewal
  priceComparisonSiteOriginated
  extras {
    ...ExtraMain
  }
  breakdownCoverOptions: extras(category: "breakdown") {
    ...ExtraMain
  }
  legalCoverOptions: extras(category: "legal") {
    ...ExtraMain
  }
  carHireCoverOptions: extras(category: "carhire") {
    ...ExtraMain
  }
  selectedExtras: extras(selected: true) {
    ...ExtraMain
  }
  experiments {
    id
    variant
  }
  serverSideAllocation {
    experimentId
    variant
  }
  details {
    qqid
    sid
    customerId
    site {
      shownIntro
      experiments {
        experimentId
        variant
        customerId
      }
      confirmed
      trackerConfirmed
      docsConfirmed
      wasOfferedFreeLegal
    }
    proposer {
      first_names
      last_names
      title
      ncd
      children
      email
      mobile_number
      dob
      employment
      business
      occupation
      has_medical_conditions
      has_informed_dvla_medical_conditions
      born_in_uk
      residency_date
      children
      convictions {
        code
        ban
        date
        points
      }
      marketing {
        email
        phone
        post
      }
      address {
        city
        county
        country
        line_1
        line_2
        line_3
        postcode
      }
      has_claims
      has_criminal_conviction
      has_driving_convictions
      licence_type
      licence_issued_date
      drives_other_vehicle
      other_vehicle_ncd
      other_vehicle_relationship
      claims {
        code
        at_fault
        ncd_lost
        date
      }
    }
    vehicle {
      ...VehicleMain
    }
    policy {
      excess
      inception_date
      promo
      current
      payment_frequency
      paymentType
      isTrackerless
      requested_payment_frequency
      address {
        line_1
        line_2
        line_3
        postcode
        city
      }
    }
    additional_drivers {
      first_names
      last_names
      title
      dob
      claims {
        code
        at_fault
        ncd_lost
        date
      }
      business
      born_in_uk
      employment
      has_claims
      occupation
      licence_type
      residency_date
      licence_issued_date
      has_medical_conditions
      convictions {
        code
        ban
        date
        points
      }
      has_criminal_conviction
      has_driving_convictions
      has_informed_dvla_medical_conditions
      relationship
    }
    payment {
      billingDay
    }
  }
  pricing {
    fixedUpfrontCost
    isValid
    perMileRate
    insurer
    insurerName
    insurancePremiumTax
    cancellationFee
    trackerNotReturnedFee
    fees
    usage
    usageCode
    compulsoryExcess
    immobiliser {
      type
    }
    total {
      estimate
      annual
    }
    annual {
      fees
      mta_fee
      upfront
      deposit
      pm
      estimate
      underwriter
      cancellation_fee
      non_returned_fee
      monthly_total_cost_estimate
    }
    monthly {
      fees
      mta_fee
      upfront
      mon
      deposit
      pm
      estimate
      underwriter
      cancellation_fee
      non_returned_fee
      monthly_total_cost_estimate
    }
  }
  promo {
    isValid
    amount
    title
    firstName
    lastName
    type
  }
  deliveryAddress {
    line_1
    line_2
    line_3
    postcode
    city
  }
  status {
    sections {
      proposer {
        status
        missingFields
      }
      vehicle {
        status
        missingFields
      }
      policy {
        status
        missingFields
      }
      additionalDriver {
        status
        missingFields
      }
    }
  }
}
    ${ExtraMainFragmentDoc}
${VehicleMainFragmentDoc}`;
export const CancelPaymentIntentDocument = gql`
    mutation CancelPaymentIntent($id: ID!) {
  cancelPaymentIntent(id: $id)
}
    `;
export type CancelPaymentIntentMutationFn = Apollo.MutationFunction<CancelPaymentIntentMutation, CancelPaymentIntentMutationVariables>;

/**
 * __useCancelPaymentIntentMutation__
 *
 * To run a mutation, you first call `useCancelPaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelPaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelPaymentIntentMutation, { data, loading, error }] = useCancelPaymentIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelPaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CancelPaymentIntentMutation, CancelPaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelPaymentIntentMutation, CancelPaymentIntentMutationVariables>(CancelPaymentIntentDocument, options);
      }
export type CancelPaymentIntentMutationHookResult = ReturnType<typeof useCancelPaymentIntentMutation>;
export type CancelPaymentIntentMutationResult = Apollo.MutationResult<CancelPaymentIntentMutation>;
export type CancelPaymentIntentMutationOptions = Apollo.BaseMutationOptions<CancelPaymentIntentMutation, CancelPaymentIntentMutationVariables>;
export const ChangeVehicleDocument = gql`
    mutation ChangeVehicle($id: ID!, $reg: String!) {
  changeVehicle(id: $id, reg: $reg) {
    quote {
      ...QuoteMain
    }
  }
}
    ${QuoteMainFragmentDoc}`;
export type ChangeVehicleMutationFn = Apollo.MutationFunction<ChangeVehicleMutation, ChangeVehicleMutationVariables>;

/**
 * __useChangeVehicleMutation__
 *
 * To run a mutation, you first call `useChangeVehicleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeVehicleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeVehicleMutation, { data, loading, error }] = useChangeVehicleMutation({
 *   variables: {
 *      id: // value for 'id'
 *      reg: // value for 'reg'
 *   },
 * });
 */
export function useChangeVehicleMutation(baseOptions?: Apollo.MutationHookOptions<ChangeVehicleMutation, ChangeVehicleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeVehicleMutation, ChangeVehicleMutationVariables>(ChangeVehicleDocument, options);
      }
export type ChangeVehicleMutationHookResult = ReturnType<typeof useChangeVehicleMutation>;
export type ChangeVehicleMutationResult = Apollo.MutationResult<ChangeVehicleMutation>;
export type ChangeVehicleMutationOptions = Apollo.BaseMutationOptions<ChangeVehicleMutation, ChangeVehicleMutationVariables>;
export const ClaimsDocument = gql`
    query Claims {
  claims {
    label
    value
  }
}
    `;

/**
 * __useClaimsQuery__
 *
 * To run a query within a React component, call `useClaimsQuery` and pass it any options that fit your needs.
 * When your component renders, `useClaimsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useClaimsQuery({
 *   variables: {
 *   },
 * });
 */
export function useClaimsQuery(baseOptions?: Apollo.QueryHookOptions<ClaimsQuery, ClaimsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ClaimsQuery, ClaimsQueryVariables>(ClaimsDocument, options);
      }
export function useClaimsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ClaimsQuery, ClaimsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ClaimsQuery, ClaimsQueryVariables>(ClaimsDocument, options);
        }
export type ClaimsQueryHookResult = ReturnType<typeof useClaimsQuery>;
export type ClaimsLazyQueryHookResult = ReturnType<typeof useClaimsLazyQuery>;
export type ClaimsQueryResult = Apollo.QueryResult<ClaimsQuery, ClaimsQueryVariables>;
export const CompletePurchaseDocument = gql`
    mutation CompletePurchase($id: ID!, $input: CompletePurchaseInput!) {
  completePurchase(id: $id, input: $input) {
    ... on CompletePurchaseSuccess {
      policyReference
    }
    ... on CompletePurchaseError {
      type
      message
    }
  }
}
    `;
export type CompletePurchaseMutationFn = Apollo.MutationFunction<CompletePurchaseMutation, CompletePurchaseMutationVariables>;

/**
 * __useCompletePurchaseMutation__
 *
 * To run a mutation, you first call `useCompletePurchaseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompletePurchaseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completePurchaseMutation, { data, loading, error }] = useCompletePurchaseMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompletePurchaseMutation(baseOptions?: Apollo.MutationHookOptions<CompletePurchaseMutation, CompletePurchaseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompletePurchaseMutation, CompletePurchaseMutationVariables>(CompletePurchaseDocument, options);
      }
export type CompletePurchaseMutationHookResult = ReturnType<typeof useCompletePurchaseMutation>;
export type CompletePurchaseMutationResult = Apollo.MutationResult<CompletePurchaseMutation>;
export type CompletePurchaseMutationOptions = Apollo.BaseMutationOptions<CompletePurchaseMutation, CompletePurchaseMutationVariables>;
export const ConvictionsDocument = gql`
    query Convictions($searchText: String!) {
  convictions(searchText: $searchText) {
    label
    value
  }
}
    `;

/**
 * __useConvictionsQuery__
 *
 * To run a query within a React component, call `useConvictionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useConvictionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useConvictionsQuery({
 *   variables: {
 *      searchText: // value for 'searchText'
 *   },
 * });
 */
export function useConvictionsQuery(baseOptions: Apollo.QueryHookOptions<ConvictionsQuery, ConvictionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ConvictionsQuery, ConvictionsQueryVariables>(ConvictionsDocument, options);
      }
export function useConvictionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ConvictionsQuery, ConvictionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ConvictionsQuery, ConvictionsQueryVariables>(ConvictionsDocument, options);
        }
export type ConvictionsQueryHookResult = ReturnType<typeof useConvictionsQuery>;
export type ConvictionsLazyQueryHookResult = ReturnType<typeof useConvictionsLazyQuery>;
export type ConvictionsQueryResult = Apollo.QueryResult<ConvictionsQuery, ConvictionsQueryVariables>;
export const CreatePaymentIntentDocument = gql`
    mutation CreatePaymentIntent($id: ID!, $nameOnCard: String!, $billingPostcode: String!) {
  createPaymentIntent(
    id: $id
    nameOnCard: $nameOnCard
    billingPostcode: $billingPostcode
  ) {
    clientSecret
    message
  }
}
    `;
export type CreatePaymentIntentMutationFn = Apollo.MutationFunction<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;

/**
 * __useCreatePaymentIntentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentIntentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentIntentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentIntentMutation, { data, loading, error }] = useCreatePaymentIntentMutation({
 *   variables: {
 *      id: // value for 'id'
 *      nameOnCard: // value for 'nameOnCard'
 *      billingPostcode: // value for 'billingPostcode'
 *   },
 * });
 */
export function useCreatePaymentIntentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>(CreatePaymentIntentDocument, options);
      }
export type CreatePaymentIntentMutationHookResult = ReturnType<typeof useCreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationResult = Apollo.MutationResult<CreatePaymentIntentMutation>;
export type CreatePaymentIntentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentIntentMutation, CreatePaymentIntentMutationVariables>;
export const CreateQuoteDocument = gql`
    mutation CreateQuote($reg: String!, $sid: String!, $qqid: String!) {
  createQuote(reg: $reg, sid: $sid, qqid: $qqid) {
    quote {
      ...QuoteMain
    }
  }
}
    ${QuoteMainFragmentDoc}`;
export type CreateQuoteMutationFn = Apollo.MutationFunction<CreateQuoteMutation, CreateQuoteMutationVariables>;

/**
 * __useCreateQuoteMutation__
 *
 * To run a mutation, you first call `useCreateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQuoteMutation, { data, loading, error }] = useCreateQuoteMutation({
 *   variables: {
 *      reg: // value for 'reg'
 *      sid: // value for 'sid'
 *      qqid: // value for 'qqid'
 *   },
 * });
 */
export function useCreateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<CreateQuoteMutation, CreateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQuoteMutation, CreateQuoteMutationVariables>(CreateQuoteDocument, options);
      }
export type CreateQuoteMutationHookResult = ReturnType<typeof useCreateQuoteMutation>;
export type CreateQuoteMutationResult = Apollo.MutationResult<CreateQuoteMutation>;
export type CreateQuoteMutationOptions = Apollo.BaseMutationOptions<CreateQuoteMutation, CreateQuoteMutationVariables>;
export const EmailCheckDocument = gql`
    query EmailCheck($email: String) {
  emailCheck(email: $email) {
    isMember
  }
}
    `;

/**
 * __useEmailCheckQuery__
 *
 * To run a query within a React component, call `useEmailCheckQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmailCheckQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmailCheckQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useEmailCheckQuery(baseOptions?: Apollo.QueryHookOptions<EmailCheckQuery, EmailCheckQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmailCheckQuery, EmailCheckQueryVariables>(EmailCheckDocument, options);
      }
export function useEmailCheckLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmailCheckQuery, EmailCheckQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmailCheckQuery, EmailCheckQueryVariables>(EmailCheckDocument, options);
        }
export type EmailCheckQueryHookResult = ReturnType<typeof useEmailCheckQuery>;
export type EmailCheckLazyQueryHookResult = ReturnType<typeof useEmailCheckLazyQuery>;
export type EmailCheckQueryResult = Apollo.QueryResult<EmailCheckQuery, EmailCheckQueryVariables>;
export const EmploymentDocument = gql`
    query Employment {
  employment {
    label
    value
  }
}
    `;

/**
 * __useEmploymentQuery__
 *
 * To run a query within a React component, call `useEmploymentQuery` and pass it any options that fit your needs.
 * When your component renders, `useEmploymentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useEmploymentQuery({
 *   variables: {
 *   },
 * });
 */
export function useEmploymentQuery(baseOptions?: Apollo.QueryHookOptions<EmploymentQuery, EmploymentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<EmploymentQuery, EmploymentQueryVariables>(EmploymentDocument, options);
      }
export function useEmploymentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<EmploymentQuery, EmploymentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<EmploymentQuery, EmploymentQueryVariables>(EmploymentDocument, options);
        }
export type EmploymentQueryHookResult = ReturnType<typeof useEmploymentQuery>;
export type EmploymentLazyQueryHookResult = ReturnType<typeof useEmploymentLazyQuery>;
export type EmploymentQueryResult = Apollo.QueryResult<EmploymentQuery, EmploymentQueryVariables>;
export const IndustriesDocument = gql`
    query Industries {
  industries {
    label
    value
  }
}
    `;

/**
 * __useIndustriesQuery__
 *
 * To run a query within a React component, call `useIndustriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useIndustriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIndustriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useIndustriesQuery(baseOptions?: Apollo.QueryHookOptions<IndustriesQuery, IndustriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, options);
      }
export function useIndustriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IndustriesQuery, IndustriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IndustriesQuery, IndustriesQueryVariables>(IndustriesDocument, options);
        }
export type IndustriesQueryHookResult = ReturnType<typeof useIndustriesQuery>;
export type IndustriesLazyQueryHookResult = ReturnType<typeof useIndustriesLazyQuery>;
export type IndustriesQueryResult = Apollo.QueryResult<IndustriesQuery, IndustriesQueryVariables>;
export const LicenceTypeDocument = gql`
    query licenceType {
  licenceType {
    label
    value
  }
}
    `;

/**
 * __useLicenceTypeQuery__
 *
 * To run a query within a React component, call `useLicenceTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useLicenceTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLicenceTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useLicenceTypeQuery(baseOptions?: Apollo.QueryHookOptions<LicenceTypeQuery, LicenceTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<LicenceTypeQuery, LicenceTypeQueryVariables>(LicenceTypeDocument, options);
      }
export function useLicenceTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<LicenceTypeQuery, LicenceTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<LicenceTypeQuery, LicenceTypeQueryVariables>(LicenceTypeDocument, options);
        }
export type LicenceTypeQueryHookResult = ReturnType<typeof useLicenceTypeQuery>;
export type LicenceTypeLazyQueryHookResult = ReturnType<typeof useLicenceTypeLazyQuery>;
export type LicenceTypeQueryResult = Apollo.QueryResult<LicenceTypeQuery, LicenceTypeQueryVariables>;
export const ModificationsDocument = gql`
    query Modifications {
  modifications {
    cosmetics {
      value
      label
    }
    accessories {
      value
      label
    }
    functional {
      value
      label
    }
    mechanical {
      value
      label
    }
  }
}
    `;

/**
 * __useModificationsQuery__
 *
 * To run a query within a React component, call `useModificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useModificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useModificationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useModificationsQuery(baseOptions?: Apollo.QueryHookOptions<ModificationsQuery, ModificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ModificationsQuery, ModificationsQueryVariables>(ModificationsDocument, options);
      }
export function useModificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ModificationsQuery, ModificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ModificationsQuery, ModificationsQueryVariables>(ModificationsDocument, options);
        }
export type ModificationsQueryHookResult = ReturnType<typeof useModificationsQuery>;
export type ModificationsLazyQueryHookResult = ReturnType<typeof useModificationsLazyQuery>;
export type ModificationsQueryResult = Apollo.QueryResult<ModificationsQuery, ModificationsQueryVariables>;
export const OccupationsDocument = gql`
    query Occupations {
  occupations {
    label
    value
  }
}
    `;

/**
 * __useOccupationsQuery__
 *
 * To run a query within a React component, call `useOccupationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useOccupationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOccupationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useOccupationsQuery(baseOptions?: Apollo.QueryHookOptions<OccupationsQuery, OccupationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OccupationsQuery, OccupationsQueryVariables>(OccupationsDocument, options);
      }
export function useOccupationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OccupationsQuery, OccupationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OccupationsQuery, OccupationsQueryVariables>(OccupationsDocument, options);
        }
export type OccupationsQueryHookResult = ReturnType<typeof useOccupationsQuery>;
export type OccupationsLazyQueryHookResult = ReturnType<typeof useOccupationsLazyQuery>;
export type OccupationsQueryResult = Apollo.QueryResult<OccupationsQuery, OccupationsQueryVariables>;
export const OwnersDocument = gql`
    query Owners {
  owners {
    label
    value
  }
}
    `;

/**
 * __useOwnersQuery__
 *
 * To run a query within a React component, call `useOwnersQuery` and pass it any options that fit your needs.
 * When your component renders, `useOwnersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useOwnersQuery({
 *   variables: {
 *   },
 * });
 */
export function useOwnersQuery(baseOptions?: Apollo.QueryHookOptions<OwnersQuery, OwnersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<OwnersQuery, OwnersQueryVariables>(OwnersDocument, options);
      }
export function useOwnersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<OwnersQuery, OwnersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<OwnersQuery, OwnersQueryVariables>(OwnersDocument, options);
        }
export type OwnersQueryHookResult = ReturnType<typeof useOwnersQuery>;
export type OwnersLazyQueryHookResult = ReturnType<typeof useOwnersLazyQuery>;
export type OwnersQueryResult = Apollo.QueryResult<OwnersQuery, OwnersQueryVariables>;
export const QuoteDocument = gql`
    query Quote($id: ID!) {
  quote(id: $id) {
    ...QuoteMain
  }
}
    ${QuoteMainFragmentDoc}`;

/**
 * __useQuoteQuery__
 *
 * To run a query within a React component, call `useQuoteQuery` and pass it any options that fit your needs.
 * When your component renders, `useQuoteQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useQuoteQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useQuoteQuery(baseOptions: Apollo.QueryHookOptions<QuoteQuery, QuoteQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<QuoteQuery, QuoteQueryVariables>(QuoteDocument, options);
      }
export function useQuoteLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<QuoteQuery, QuoteQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<QuoteQuery, QuoteQueryVariables>(QuoteDocument, options);
        }
export type QuoteQueryHookResult = ReturnType<typeof useQuoteQuery>;
export type QuoteLazyQueryHookResult = ReturnType<typeof useQuoteLazyQuery>;
export type QuoteQueryResult = Apollo.QueryResult<QuoteQuery, QuoteQueryVariables>;
export const RelationshipsDocument = gql`
    query Relationships {
  relationships {
    label
    value
  }
}
    `;

/**
 * __useRelationshipsQuery__
 *
 * To run a query within a React component, call `useRelationshipsQuery` and pass it any options that fit your needs.
 * When your component renders, `useRelationshipsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRelationshipsQuery({
 *   variables: {
 *   },
 * });
 */
export function useRelationshipsQuery(baseOptions?: Apollo.QueryHookOptions<RelationshipsQuery, RelationshipsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RelationshipsQuery, RelationshipsQueryVariables>(RelationshipsDocument, options);
      }
export function useRelationshipsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RelationshipsQuery, RelationshipsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RelationshipsQuery, RelationshipsQueryVariables>(RelationshipsDocument, options);
        }
export type RelationshipsQueryHookResult = ReturnType<typeof useRelationshipsQuery>;
export type RelationshipsLazyQueryHookResult = ReturnType<typeof useRelationshipsLazyQuery>;
export type RelationshipsQueryResult = Apollo.QueryResult<RelationshipsQuery, RelationshipsQueryVariables>;
export const RetrieveQuoteByIdDocument = gql`
    query RetrieveQuoteById($id: ID, $input: AuthenticationInput) {
  retrieveQuoteById(id: $id, input: $input) {
    id
    details {
      proposer {
        last_names
      }
      vehicle {
        reg
      }
    }
  }
}
    `;

/**
 * __useRetrieveQuoteByIdQuery__
 *
 * To run a query within a React component, call `useRetrieveQuoteByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveQuoteByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveQuoteByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetrieveQuoteByIdQuery(baseOptions?: Apollo.QueryHookOptions<RetrieveQuoteByIdQuery, RetrieveQuoteByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveQuoteByIdQuery, RetrieveQuoteByIdQueryVariables>(RetrieveQuoteByIdDocument, options);
      }
export function useRetrieveQuoteByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveQuoteByIdQuery, RetrieveQuoteByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveQuoteByIdQuery, RetrieveQuoteByIdQueryVariables>(RetrieveQuoteByIdDocument, options);
        }
export type RetrieveQuoteByIdQueryHookResult = ReturnType<typeof useRetrieveQuoteByIdQuery>;
export type RetrieveQuoteByIdLazyQueryHookResult = ReturnType<typeof useRetrieveQuoteByIdLazyQuery>;
export type RetrieveQuoteByIdQueryResult = Apollo.QueryResult<RetrieveQuoteByIdQuery, RetrieveQuoteByIdQueryVariables>;
export const RetrieveQuoteByRegDocument = gql`
    query RetrieveQuoteByReg($reg: String, $input: AuthenticationInput) {
  retrieveQuoteByReg(reg: $reg, input: $input) {
    id
    details {
      proposer {
        last_names
      }
      vehicle {
        reg
      }
    }
  }
}
    `;

/**
 * __useRetrieveQuoteByRegQuery__
 *
 * To run a query within a React component, call `useRetrieveQuoteByRegQuery` and pass it any options that fit your needs.
 * When your component renders, `useRetrieveQuoteByRegQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRetrieveQuoteByRegQuery({
 *   variables: {
 *      reg: // value for 'reg'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRetrieveQuoteByRegQuery(baseOptions?: Apollo.QueryHookOptions<RetrieveQuoteByRegQuery, RetrieveQuoteByRegQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RetrieveQuoteByRegQuery, RetrieveQuoteByRegQueryVariables>(RetrieveQuoteByRegDocument, options);
      }
export function useRetrieveQuoteByRegLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RetrieveQuoteByRegQuery, RetrieveQuoteByRegQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RetrieveQuoteByRegQuery, RetrieveQuoteByRegQueryVariables>(RetrieveQuoteByRegDocument, options);
        }
export type RetrieveQuoteByRegQueryHookResult = ReturnType<typeof useRetrieveQuoteByRegQuery>;
export type RetrieveQuoteByRegLazyQueryHookResult = ReturnType<typeof useRetrieveQuoteByRegLazyQuery>;
export type RetrieveQuoteByRegQueryResult = Apollo.QueryResult<RetrieveQuoteByRegQuery, RetrieveQuoteByRegQueryVariables>;
export const SendSaveQuoteEmailDocument = gql`
    mutation sendSaveQuoteEmail($id: ID!) {
  sendSaveQuoteEmail(id: $id) {
    success
  }
}
    `;
export type SendSaveQuoteEmailMutationFn = Apollo.MutationFunction<SendSaveQuoteEmailMutation, SendSaveQuoteEmailMutationVariables>;

/**
 * __useSendSaveQuoteEmailMutation__
 *
 * To run a mutation, you first call `useSendSaveQuoteEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendSaveQuoteEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendSaveQuoteEmailMutation, { data, loading, error }] = useSendSaveQuoteEmailMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useSendSaveQuoteEmailMutation(baseOptions?: Apollo.MutationHookOptions<SendSaveQuoteEmailMutation, SendSaveQuoteEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendSaveQuoteEmailMutation, SendSaveQuoteEmailMutationVariables>(SendSaveQuoteEmailDocument, options);
      }
export type SendSaveQuoteEmailMutationHookResult = ReturnType<typeof useSendSaveQuoteEmailMutation>;
export type SendSaveQuoteEmailMutationResult = Apollo.MutationResult<SendSaveQuoteEmailMutation>;
export type SendSaveQuoteEmailMutationOptions = Apollo.BaseMutationOptions<SendSaveQuoteEmailMutation, SendSaveQuoteEmailMutationVariables>;
export const TitlesDocument = gql`
    query Titles {
  titles {
    label
    value
  }
}
    `;

/**
 * __useTitlesQuery__
 *
 * To run a query within a React component, call `useTitlesQuery` and pass it any options that fit your needs.
 * When your component renders, `useTitlesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTitlesQuery({
 *   variables: {
 *   },
 * });
 */
export function useTitlesQuery(baseOptions?: Apollo.QueryHookOptions<TitlesQuery, TitlesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TitlesQuery, TitlesQueryVariables>(TitlesDocument, options);
      }
export function useTitlesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TitlesQuery, TitlesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TitlesQuery, TitlesQueryVariables>(TitlesDocument, options);
        }
export type TitlesQueryHookResult = ReturnType<typeof useTitlesQuery>;
export type TitlesLazyQueryHookResult = ReturnType<typeof useTitlesLazyQuery>;
export type TitlesQueryResult = Apollo.QueryResult<TitlesQuery, TitlesQueryVariables>;
export const UpdateQuoteDocument = gql`
    mutation UpdateQuote($id: ID!, $actions: [UpdateQuoteAction!], $refreshPrice: Boolean, $sendEmail: Boolean) {
  updateQuote(
    id: $id
    actions: $actions
    refreshPrice: $refreshPrice
    sendEmail: $sendEmail
  ) {
    quote {
      ...QuoteMain
    }
  }
}
    ${QuoteMainFragmentDoc}`;
export type UpdateQuoteMutationFn = Apollo.MutationFunction<UpdateQuoteMutation, UpdateQuoteMutationVariables>;

/**
 * __useUpdateQuoteMutation__
 *
 * To run a mutation, you first call `useUpdateQuoteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateQuoteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateQuoteMutation, { data, loading, error }] = useUpdateQuoteMutation({
 *   variables: {
 *      id: // value for 'id'
 *      actions: // value for 'actions'
 *      refreshPrice: // value for 'refreshPrice'
 *      sendEmail: // value for 'sendEmail'
 *   },
 * });
 */
export function useUpdateQuoteMutation(baseOptions?: Apollo.MutationHookOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateQuoteMutation, UpdateQuoteMutationVariables>(UpdateQuoteDocument, options);
      }
export type UpdateQuoteMutationHookResult = ReturnType<typeof useUpdateQuoteMutation>;
export type UpdateQuoteMutationResult = Apollo.MutationResult<UpdateQuoteMutation>;
export type UpdateQuoteMutationOptions = Apollo.BaseMutationOptions<UpdateQuoteMutation, UpdateQuoteMutationVariables>;