export function updatePricing() {
  const quotePricing = this.state.quotePricing
  const isMonthly = quotePricing.payment_frequency === 'monthly'
  const promo = quotePricing.promo.type === 'referAFriend' ? 0 : quotePricing.promo.amount
  const estimateMiles = (quotePricing.estimatedMiles || '').replace(',', '')
  const extras = quotePricing.extras
  const fixedUpfrontCost = (Number(quotePricing.fixedUpfront) + Number(extras)).toFixed(2)
  const fixedUpfrontCostMonthly = quotePricing.monthly
    ? (Number(quotePricing.monthly.upfront) + Number(extras)).toFixed(2)
    : null
  const netMileageCost = Math.max(Number(estimateMiles) * Number(quotePricing.perMile / 100) - (promo || 0), 0)
  const netMileageCostYearly = Math.max(Number(estimateMiles) * Number(quotePricing.annual?.pm) - (promo || 0), 0)

  const averageMonthlyYearly = quotePricing?.annual?.monthly_total_cost_estimate
  const averageMonthlyMonthly = quotePricing?.monthly?.monthly_total_cost_estimate
  const projectedAnnualYearly = (Number(quotePricing?.annual?.estimate) + Number(extras)).toFixed(2)
  const projectedAnnualMonthly = (Number(quotePricing?.monthly?.estimate) + Number(extras)).toFixed(2)

  // Used in sticky and breakdown, so it changes dynamically according to payment frequency
  quotePricing.projectedAnnual = isMonthly ? projectedAnnualMonthly : projectedAnnualYearly

  // these are used when both prices need to be presented side by side. Like in the case of the payment option overlay
  quotePricing.projectedAnnualYearly = projectedAnnualYearly
  quotePricing.projectedAnnualMonthly = projectedAnnualMonthly

  // Used in sticky and breakdown, so it changes dynamically according to payment frequency
  quotePricing.averageMonthly = isMonthly ? averageMonthlyMonthly : averageMonthlyYearly

  // these are used when both prices need to be presented side by side. Like in the case of the payment option overlay
  quotePricing.averageMonthlyYearly = averageMonthlyYearly
  quotePricing.averageMonthlyMonthly = averageMonthlyMonthly

  quotePricing.fixedUpfrontPure = quotePricing.fixedUpfront

  quotePricing.fixedUpfront = isMonthly ? fixedUpfrontCostMonthly : fixedUpfrontCost

  quotePricing.netMileageCost = netMileageCost

  // for BMMB with promo

  quotePricing.moneyBackPromoMileageCost =
    netMileageCostYearly === 0 || !promo ? netMileageCostYearly : netMileageCostYearly + promo
  quotePricing.fullPriceProjectedAnnual = quotePricing.promo.amount
    ? (quotePricing.moneyBackPromoMileageCost + Number(fixedUpfrontCost)).toFixed(2)
    : quotePricing.projectedAnnual

  this.setState({ quotePricing })
}
