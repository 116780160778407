export const randomString = (
  strLength = 5,
  charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789',
) => {
  const result = []
  while (strLength--) {
    result.push(charSet.charAt(Math.floor(Math.random() * charSet.length)))
  }
  return result.join('')
}
