import { theme } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'

const ExtrasImageStyles = styled.div`
  .key-image {
    width: 100%;
    border-radius: ${theme.grid.spacing.xxs}px;
  }
`

interface ExtrasImageProps {
  image: string
  altText: string
}

export const ExtrasImage = ({ image, altText }: ExtrasImageProps) => {
  return (
    <ExtrasImageStyles>
      <img className="key-image" alt={altText} src={`../v2/assets/extras/${image}`} />
    </ExtrasImageStyles>
  )
}
