import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

const { grid } = theme
const { resolution, spacing } = grid

const handlePositioning = (hideStickyPricing: boolean) => {
  return hideStickyPricing ? 'absolute' : 'relative'
}

export const FooterStyled = styled.footer<{ fixedFooter: boolean; hideStickyPricing: boolean }>`
  background-color: ${theme.primaryColours.aubergine.main};
  color: ${theme.tints.aubergine};
  position: ${({ fixedFooter, hideStickyPricing }) => (fixedFooter ? 'fixed' : handlePositioning(hideStickyPricing))};
  bottom: ${({ fixedFooter }) => (fixedFooter ? '0' : 'auto')};
  width: 100%;
  /* margin-bottom: 117px; // height of the sticky pricing component */
  .container {
    padding: ${spacing.l}px ${spacing.base}px;
    @media (min-width: ${resolution.tablet.width}px) {
      width: ${resolution.tablet.container}px;
      margin: auto;
      padding: ${theme.grid.spacing.base}px 0;
      padding: ${spacing.xl}px 0;
    }
    @media (min-width: ${resolution.desktop.width}px) {
      width: ${resolution.desktop.container}px;
    }
    .links {
      display: grid;
      gap: ${spacing.s}px;
      grid-auto-flow: column;
      grid-template-rows: 1fr 1fr 1fr;
      margin-bottom: ${spacing.md}px;
      @media (min-width: ${resolution.tablet.width}px) {
        gap: ${spacing.base}px ${spacing.md}px;
        display: flex;
        width: 550px;
        flex-wrap: wrap;
      }
      @media (min-width: ${resolution.desktop.width}px) {
        width: 100%;
      }
    }
  }
`
