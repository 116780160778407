import { ButtonGeneral, H2, H5, Input, ParagraphBody, theme, Toggle } from '@by-miles/bymiles-styles'
import { DataLayer } from '@by-miles/data-layer'
import { emailRegex } from '@by-miles/regex'
import { datadogRum } from '@datadog/browser-rum'
import React, { useContext, useState } from 'react'
import styled from 'styled-components'

import { useSendSaveQuoteEmailMutation } from '../../../generated/graphql'
import { updateQuoteMutation } from '../../../hooks'
import { useQuote } from '../../../hooks/useQuote'
import { ProviderContext } from '../../../Provider'
import { ConfirmationModal } from './ConfirmationModal'

const SaveQuoteStyle = styled.div`
  .margin-bottom {
    margin-bottom: ${theme.grid.spacing.base}px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-bottom: ${theme.grid.spacing.md}px;
    }
  }

  .paragraph {
    color: ${theme.primaryColours.aubergine.main};
    max-width: 338px;
  }

  .save-quote-input {
    margin-bottom: ${theme.grid.spacing.base}px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-bottom: ${theme.grid.spacing.md}px;
    }
  }
  .wrapper {
    position: relative;
    max-width: 388px;
    margin-bottom: ${theme.grid.spacing.s}px;
    &:first-child {
      border-bottom: 1px solid #bfb6c3;
    }
    &:last-child {
      margin-top: ${theme.grid.spacing.s}px;
    }
  }

  .toggle-title-wrapper {
    display: flex;
    justify-content: space-between;
  }
  .info-message-container {
    margin-bottom: ${theme.grid.spacing.s}px;
    div {
      position: relative;
    }
  }
`

export const SaveQuote = () => {
  const {
    handleCloseModal,
    state: { quoteEmail },
  } = useContext(ProviderContext)
  const [showConfirmation, setShowConfirmation] = useState(false)
  const [emailError, setEmailError] = useState('')
  const [showMismatch, setShowMismatch] = useState(false)
  const [toggle, setToggle] = useState(false)
  const quote = useQuote()
  const [sendSaveQuoteEmailMutation] = useSendSaveQuoteEmailMutation({
    onError: error => {
      datadogRum.addError('Save quote error', error)
    },
  })
  const { email } = quote.details.proposer?.marketing || {}

  const { updateQuote } = updateQuoteMutation()

  const handleEmailChange = value => {
    setShowMismatch(false)
    if (!value.match(emailRegex)) {
      setEmailError('Sorry, this doesn’t look like an email address. Please re-type and try again.')
    } else {
      setEmailError('')
    }
  }

  const handleEmailOnBlur = value => {
    if (value !== quoteEmail) {
      setShowMismatch(true)
    }
  }

  const handleSaveQuote = async () => {
    if (emailError) return
    DataLayer.customEvent({
      eventName: 'submit',
      section: 'save quote',
      eventType: 'form submit',
    })

    if (toggle) {
      DataLayer.customEvent({
        eventName: 'email yes',
        section: 'marketing preferences',
        eventType: 'form tracking',
      })
      updateQuote({
        variables: {
          id: quote.id,
          actions: [
            {
              setMarketingPreferences: { email: true },
            },
          ],
        },
      })
    }
    sendSaveQuoteEmailMutation({
      variables: {
        id: quote.id,
      },
    })
    setShowConfirmation(true)
  }

  const handleOnClose = async () => {
    handleCloseModal('confirmation-modal')
    setShowConfirmation(false)
    document.body.classList.remove('noScroll')
  }

  return (
    <SaveQuoteStyle>
      {showConfirmation ? (
        <ConfirmationModal onClose={handleOnClose} />
      ) : (
        <>
          <div className="wrapper LoNotSensitive">
            <H2 className="overlay-heading margin-bottom">Save your progress.</H2>
            <ParagraphBody className="paragraph margin-bottom">
              Enter your email address to save your progress. We'll email you a link that you can use to load up your
              details at any point in the next 30 days.
            </ParagraphBody>
            <Input
              className="save-quote-input margin-bottom"
              name="email"
              error={emailError}
              info={
                showMismatch && !emailError
                  ? `This doesn't match the email (${quoteEmail}) you provided in your main driver contact information.`
                  : ''
              }
              onBlur={value => handleEmailOnBlur(value)}
              valid={!emailError}
              type="email"
              placeHolder="Email Address"
              value={quoteEmail}
              onChange={value => handleEmailChange(value)}
            />
          </div>
          <div className="wrapper LoNotSensitive">
            {!email && (
              <>
                <div className="toggle-title-wrapper margin-bottom">
                  <H5 className="overlay-heading">Want to sign up for more?</H5>
                  <Toggle defaultValue={toggle} onChange={() => setToggle(!toggle)} />
                </div>
                <ParagraphBody className="paragraph margin-bottom">
                  Would you like us to send you occasional offers, tips and tricks to make owning your car easier? You
                  can unsubscribe at any time.
                </ParagraphBody>
              </>
            )}
            <ButtonGeneral label="save" type="large" onClick={handleSaveQuote} />
          </div>
        </>
      )}
    </SaveQuoteStyle>
  )
}
