import React from 'react'

import config from '../../config'
import { useQuote } from '../../hooks/useQuote'
import { AddExtraAction, RemoveExtraAction } from '../../utils/extrasOverlayTypes'
import { ExtrasOverlay } from './ExtrasOverlay'

export const CarCoverOverlay = () => {
  const quote = useQuote()
  const carHireCoverOptions = quote?.extras.filter(extra => extra?.code === 'courtesy_car')
  const tiers = carHireCoverOptions.map(tier => {
    return {
      ...tier,
      selected: carHireCoverOptions.some(tier => tier.selected),
      description: 'Courtesy Car Plus',
      aboveBullets: 'With Courtesy Car Plus, we’ll be able to get you back on the road quickly, whatever happens.',
    }
  })

  tiers.push({
    description: 'I Don’t need any, thanks',
    code: 'none',
    amountInPounds: 0,
    name: 'tier',
    amount: 0,
    category: 'false',
    selected: false,
  })
  return (
    <ExtrasOverlay
      heading="Do you need extra courtesy car cover?"
      openingPara="Get a higher level of courtesy car cover to keep you on the road."
      subHeading="Choose your level of cover:"
      linkHeading="Find out more"
      links={[
        {
          label: 'Courtesy Car Plus Booklet',
          href: config.docs.carHire.handbook,
        },
        {
          label: 'Courtesy Car Plus Insurance Product Information Document (IPID)',
          href: config.docs.carHire.ipid,
        },
      ]}
      type="car-hire"
      smallPrint={false}
      tiers={tiers}
      tierSelected={carHireCoverOptions.filter(tier => tier?.selected)}
      removeAction={RemoveExtraAction.RemoveCarHireCover}
      addAction={AddExtraAction.AddCarHireCover}
    />
  )
}
