import { H2 } from '@by-miles/bymiles-styles'
import React from 'react'

import config from '../../../config'
import { Insurer } from '../../../generated/graphql'
import { DocumentItem } from './DocumentItem'
import { MultiplePolicyDocStyles } from './MultiplePolicyDocStyles'
interface MultiplePolicyDocsProps {
  hasBreakdownCover: boolean
  hasLegalCover: boolean
  hasCarHireCover: boolean
  insurer: Insurer
}
export const MultiplePolicyDocs = ({
  hasBreakdownCover,
  hasLegalCover,
  hasCarHireCover,
  insurer,
}: MultiplePolicyDocsProps) => {
  const docs = [
    {
      title: 'By Miles Policy Handbook',
      href: `https://www.bymiles.co.uk/docs/policy-handbook?insurer=${insurer}`,
    },
    {
      title: 'Insurance Product Information Document',
      href: `https://www.bymiles.co.uk/docs/policy-ipid?insurer=${insurer}`,
    },
  ]

  if (hasBreakdownCover)
    docs.push(
      {
        title: 'RAC Breakdown Cover Policy Booklet',
        href: 'https://quote.bymiles.co.uk/rac/handbook',
      },
      {
        title: 'RAC Breakdown Cover Insurance Product Information Document (IPID)',
        href: 'https://quote.bymiles.co.uk/rac/ipid',
      },
    )

  if (hasLegalCover)
    docs.push(
      {
        title: 'ARC Legal Expense Cover Booklet',
        href: config.docs.legalCover.handbook,
      },
      {
        title: 'ARC Legal Expense Cover Insurance Product Information Document (IPID)',
        href: 'https://static.bymiles.co.uk/docs/legal-ipid.pdf',
      },
    )

  if (hasCarHireCover)
    docs.push(
      {
        title: 'Courtesy Car Plus Booklet',
        href: config.docs.carHire.handbook,
      },
      {
        title: 'Courtesy Car Plus Insurance Product Information Document (IPID)',
        href: config.docs.carHire.ipid,
      },
    )

  return (
    <MultiplePolicyDocStyles>
      <div className="LoNotSensitive">
        <H2 className="overlay-heading">My Policy documents.</H2>
        {docs.map(d => (
          <DocumentItem title={d.title} href={d.href} />
        ))}
      </div>
    </MultiplePolicyDocStyles>
  )
}
