export const simpleStateUpdater = function (statesAndValues = []) {
  const localState = {}
  statesAndValues.forEach(stateAndValue => {
    localState[stateAndValue.state] = stateAndValue.value
  })
  this.setState(prevState => ({
    ...prevState,
    ...localState,
  }))
}
