import { ParagraphBody } from '@by-miles/bymiles-styles'
import React from 'react'

export const SDP = () => (
  <>
    <ParagraphBody className="overlay-text">
      This covers things like visiting family and friends, school runs and trips to the shops. It’s any ordinary use
      that <span className="bold">isn’t</span> to do with work, or commuting to and from work. When you have this cover,
      it applies to all named drivers.
    </ParagraphBody>
  </>
)
