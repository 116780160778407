import Cookie from 'js-cookie'
import { useContext, useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { quoteId } from '../cache'
import config from '../config'
import { UpdateQuoteAction } from '../generated/graphql'
import { ProviderContext } from '../Provider'
import { setExperiments } from '../utils/setExperiment'
import { updateQuoteMutation } from './updateQuote'
import { queueQuoteUpdate } from './useQueueQuoteUpdates'

export const useInitialLoad = () => {
  const { state, simpleStateUpdater, setPageLoading } = useContext(ProviderContext)
  const [loading, setLoading] = useState(false)
  const { updateQuote, data } = updateQuoteMutation()
  const history = useHistory()
  const urlParams = new URLSearchParams(window.location.search)
  const PCWBreakdownSelection = urlParams.get('breakdown') || false
  const PCWLegalSelection = urlParams.get('legal') || false
  const isPCWClickThrough = urlParams.get('dtl') === 'comparison'
  const actions: UpdateQuoteAction[] = []

  useEffect(() => {
    const id = quoteId()
    if (!id) return
    const load = async () => {
      if (isPCWClickThrough) {
        actions.push(
          PCWBreakdownSelection
            ? { addBreakdownCover: { code: PCWBreakdownSelection } }
            : { removeBreakdownCover: true },
        )
        actions.push(
          PCWLegalSelection
            ? {
                addLegalCover: { code: PCWLegalSelection === 'legal_cover' ? 'legal_cover_1' : PCWLegalSelection },
              }
            : { removeLegalCover: true },
        )
      }
      await updateQuote({
        variables: {
          id,
          actions,
          refreshPrice: true,
          sendEmail: true,
        },
      })
      setLoading(false)
      let clientId
      let timer = 0
      const interval = 100
      while (!state.gaClientIdUpdated && !clientId && timer < 2000) {
        await new Promise(res => setTimeout(() => res(true), interval))
        timer += interval
        try {
          clientId = window.ga.getAll()[0].get('clientId')
          const quoteId = Cookie.get(config.quoteIdCookieName)
          if (!clientId || !quoteId) continue
          queueQuoteUpdate({ addGAClientId: { clientId } })
          simpleStateUpdater([{ state: 'gaClientIdUpdated', value: true }])
        } catch (error) {}
      }
    }
    load()
  }, [])

  useEffect(() => {
    const quote = data?.updateQuote.quote
    if (!quote?.experiments || !config.ga?.trackingId) return
    setExperiments(quote.experiments)
  }, [data?.updateQuote.quote])

  useEffect(() => {
    if (loading) return
    setPageLoading(loading)
    history.replace(window.location.pathname)
  }, [loading, setPageLoading, history])
}
