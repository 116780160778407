import React from 'react'
import styled from 'styled-components'

import { useAccordionContextProvider } from './Accordion'
import { AccordionBodyProps } from './types'

const AccordionBodyStyled = styled.div<{ expanded: boolean }>`
  display: ${({ expanded }) => (expanded ? 'block' : 'none')};
`

export const AccordionBody: React.FC<AccordionBodyProps> = ({ index, children }) => {
  if (!index) throw Error('Index missing for Accordion Body')
  const { expanded } = useAccordionContextProvider({ index })
  return (
    <AccordionBodyStyled className="accordion__body" expanded={expanded}>
      {children}
    </AccordionBodyStyled>
  )
}
