import config from '../config'

const luckyOrange = () => {
  window._loq = window._loq || []
  return window._loq
}

export const LuckyOrange = {
  tag: (tag: string, star?: boolean, overwrite?: boolean) =>
    config.enableLuckyOrange && luckyOrange().push(['tag', tag, star, overwrite]),
}
