import { AnchorFooterLink, ButtonGeneral, ButtonSecondary, H2, ParagraphBody } from '@by-miles/bymiles-styles'
import React from 'react'

import { IdScoreOverlayStyles } from './IdScoreOverlayStyles'

interface IdScoreOverlayProps {
  firstName: string
  lastName: string
  dob: string
  addressLineOne: string
  addressLineTwo: string
  addressCity: string
  addressPostcode: string
  onClickContinue: () => void
  onClickChange: () => void
}

export const IdScoreOverlay = ({
  firstName,
  lastName,
  dob,
  addressLineOne,
  addressLineTwo,
  addressCity,
  addressPostcode,
  onClickContinue,
  onClickChange,
}: IdScoreOverlayProps) => (
  <IdScoreOverlayStyles>
    <div className="id-score-modal-wrapper">
      <H2 className="overlay-heading LoNotSensitive">Sorry, we can’t seem to verify your details.</H2>
      <ParagraphBody className="intro-content">
        Are you sure that you've entered your full name, date of birth and home address correctly?
      </ParagraphBody>

      <div className="verify-details-container">
        <div className="details-section">
          <AnchorFooterLink className="label LoNotSensitive">Name</AnchorFooterLink>
          <ParagraphBody>
            {firstName} {lastName}
          </ParagraphBody>
        </div>
        <div className="details-section">
          <AnchorFooterLink className="label LoNotSensitive">Date of Birth</AnchorFooterLink>
          <ParagraphBody>{dob}</ParagraphBody>
        </div>
        <div className="details-section">
          <AnchorFooterLink className="label LoNotSensitive">Primary Address</AnchorFooterLink>
          <ParagraphBody>{addressLineOne}</ParagraphBody>
          <ParagraphBody>{addressLineTwo}</ParagraphBody>
          <ParagraphBody>{addressCity}</ParagraphBody>
          <ParagraphBody>{addressPostcode}</ParagraphBody>
        </div>
      </div>

      <div className="button-wrapper">
        <ButtonGeneral
          className="LoNotSensitive button continue-button"
          label="Yes, this is correct"
          onClick={onClickContinue}
        />
        <ButtonSecondary
          className="LoNotSensitive button edit-button"
          label="No, edit details"
          onClick={onClickChange}
        />
      </div>
    </div>
  </IdScoreOverlayStyles>
)
