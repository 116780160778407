import { ParagraphBody, theme } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'

const BannerStyle = styled.div`
  width: 265px;
  max-width: 100%;
  padding: 8px 8px 8px 16px;
  background: ${theme.tints.cyan};
  color: ${theme.primaryColours.aubergine.main};
  position: relative;
  border-left: 4px solid #0098c8;
  box-sizing: border-box;
  line-height: 23px;
  margin-bottom: ${theme.grid.spacing.s}px;
  p {
    font-size: 14px;
    margin-top: 0;
    &.bold {
      font-weight: bold;
      margin-bottom: 8px;
      font-size: 18px;
    }
  }
  a {
    color: #0098c8;
    text-decoration: none;
  }
`

const CXBanner = () => {
  return (
    <BannerStyle>
      <ParagraphBody className="bold">We’re upgrading our communications systems this week.</ParagraphBody>
      <ParagraphBody>
        You won’t be able to call our customer service line at the moment, but if it’s urgent you can reach us at{' '}
        <a href="mailto:support@bymiles.co.uk">support@bymiles.co.uk</a> or on live chat. The claims line is open 24/7
        as usual, and you can use the app or dashboard to change policy or payment details and retry payments without
        any need to get in touch. Have a question? We might have already answered it{' '}
        <a href="https://help.bymiles.co.uk/" rel="noopener noreferrer" target="_blank">
          here.
        </a>
      </ParagraphBody>
    </BannerStyle>
  )
}

export default CXBanner
