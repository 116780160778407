export const updateUserInputs = function (statesAndValues = [], onUpdated) {
  const updated = statesAndValues.reduce((carry, { state, value }) => {
    return {
      ...carry,
      [state]: value,
    }
  }, {})

  return this.setState(
    state => ({
      createAccount: {
        ...state.createAccount,
        userInputs: {
          ...state.createAccount.userInputs,
          ...updated,
        },
      },
    }),
    onUpdated,
  )
}

export const updateValidInputs = function (statesAndValues = [], onUpdated) {
  const updated = statesAndValues.reduce((carry, { state, value }) => {
    return {
      ...carry,
      [state]: value,
    }
  }, {})

  return this.setState(
    state => ({
      createAccount: {
        ...state.createAccount,
        validInputs: {
          ...state.createAccount.validInputs,
          ...updated,
        },
      },
    }),
    onUpdated,
  )
}

export const updateInputsErrors = function (statesAndValues = [], onUpdated) {
  const updated = statesAndValues.reduce((carry, { state, value }) => {
    return {
      ...carry,
      [state]: value,
    }
  }, {})
  return this.setState(
    state => ({
      createAccount: {
        ...state.createAccount,
        errors: {
          ...state.createAccount.errors,
          ...updated,
        },
      },
    }),
    onUpdated,
  )
}
