// make sure a value is the right type
export const forceType = function (type, value) {
  let correctValueType
  if (type === 'bool' || type === 'boolean') {
    correctValueType = value === 'true'
  } else if (type === 'number' || type === 'integer') {
    correctValueType = parseInt(value)
  } else {
    correctValueType = value
  }
  return correctValueType
}
