import { OverlayContainer } from '@by-miles/bymiles-styles'
import React, { useContext } from 'react'

import { ProviderContext } from '../../../Provider'

interface GenericOverlayProps {
  onClose?: (event?: any) => void
  cookieConsent?: boolean
  hideDismissIcon?: boolean
  overlay: JSX.Element
}
export const GenericOverlay = (props: GenericOverlayProps) => {
  const { simpleStateUpdater } = useContext(ProviderContext)
  const { onClose, cookieConsent, hideDismissIcon, overlay } = props

  const handleBackgroundClicked = (event: any) => {
    if (event.target.id === 'modal-background') {
      onClose && onClose(event)
      if (!hideDismissIcon) {
        event.preventDefault()
        simpleStateUpdater([{ state: 'showModal', value: false }])
      }
    }
  }

  return (
    <OverlayContainer
      handleBGClick={handleBackgroundClicked}
      handleCloseIconClick={() => {
        onClose && onClose()
        simpleStateUpdater([{ state: 'showModal', value: false }])
      }}
      cookieConsent={cookieConsent}
      hideDismissIcon={hideDismissIcon}
      overlay={overlay}
    />
  )
}
