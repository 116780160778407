export const numbersToLimitOptions = (limit: number) => {
  let optionsArray = []
  for (let a = 0; a < limit; a++) {
    optionsArray.push({ value: a, label: `${a}` })
  }
  optionsArray.push({ value: limit, label: `${limit}+` })
  return optionsArray
}

export const yearsToTenOptions = [
  { value: 0, label: 'None' },
  { value: 1, label: '1 year' },
  { value: 2, label: '2 years' },
  { value: 3, label: '3 years' },
  { value: 4, label: '4 years' },
  { value: 5, label: '5 years' },
  { value: 6, label: '6 years' },
  { value: 7, label: '7 years' },
  { value: 8, label: '8 years' },
  { value: 9, label: '9 years' },
  { value: 10, label: '10 years' },
]

export const genericDateErrorCopy = "Sorry, this doesn't look quite right. Please double check and try again."
export const genericErrorCopy = 'Please answer this question to continue.'

export const billingDayOptions = [
  { value: 1, label: '1st of each month' },
  { value: 7, label: '7th of each month' },
  { value: 14, label: '14th of each month' },
  { value: 21, label: '21st of each month' },
  { value: 28, label: '28th of each month' },
]
