import React, { useState } from 'react'
import styled from 'styled-components'

import { randomString } from '../../../utils/randomString'
import { useAccordionContextProvider } from './Accordion'
import { AccordionHeaderProps } from './types'

const AccessibleHeaderStyles = styled.div<{ focused?: boolean }>`
  .accordion__header {
    ${({ focused }) =>
      focused &&
      `
        outline-offset: 8px;
        outline: 3px solid #240232;
        border-radius: 8px;
      `}
  }
  .accordion-button-hidden {
    position: absolute;
    opacity: 0;
    z-index: -1;
  }
`

export const AccordionHeader: React.FC<AccordionHeaderProps> = ({
  index,
  closeOnOpen,
  tabFocusTrap,
  callback,
  children,
}) => {
  if (!index) throw Error('Index missing for Accordion Header')
  const accordionId = randomString()
  const { toggle, expanded } = useAccordionContextProvider({ index, closeOnOpen })
  const [focused, setFocused] = useState(false)

  return (
    <AccessibleHeaderStyles focused={focused}>
      <div
        id={accordionId}
        className="accordion__header"
        onClick={() => {
          toggle()
          callback && callback()
        }}
      >
        {children}
        <button
          type="button"
          className="accordion-button-hidden"
          onFocus={() => setFocused(true)}
          onBlur={() => setFocused(false)}
          onClick={() => toggle()}
          aria-labelledby={accordionId}
          aria-expanded={expanded}
          data-cy={`accordion-button-${accordionId}`}
          tabIndex={tabFocusTrap || 0}
        />
      </div>
    </AccessibleHeaderStyles>
  )
}
