import { createContext, FC, useCallback, useContext, useEffect, useState } from 'react'

import { AccordionBody } from './AccordionBody'
import { AccordionHeader } from './AccordionHeader'
import { AccordionItem } from './AccordionItem'
import { AccordionBody as AccordionBodyType, AccordionBodyIndex, AccordionContextInterface } from './types'

const AccordionContext = createContext<AccordionContextInterface>(null!)

const AccordionContextProvider: FC = ({ children }) => {
  const [accordionPanels, setAccordionPanels] = useState<AccordionBodyType[]>([])

  const registerAccordionPanel = useCallback(
    (index: AccordionBodyIndex, expanded = false) => {
      setAccordionPanels(state => {
        if (state.find(ap => ap.index === index)) return [...state]
        return [...state, { index, expanded }]
      })
    },
    [setAccordionPanels],
  )

  const unregisterAccordionPanel = useCallback(
    (index: AccordionBodyIndex) => {
      setAccordionPanels(state => state.filter(ap => ap.index !== index))
    },
    [setAccordionPanels],
  )

  const toggle = (index: AccordionBodyIndex, closeOnOpen: boolean) => {
    const handleOtherPanels = (panel: AccordionBodyType) => {
      return closeOnOpen ? { ...panel, expanded: false } : panel
    }
    const updatedPanels = accordionPanels.map(panel => {
      return panel.index === index
        ? {
            ...panel,
            expanded: !panel.expanded,
          }
        : handleOtherPanels(panel)
    })
    setAccordionPanels([...updatedPanels])
  }

  return (
    <AccordionContext.Provider
      value={{
        accordionPanels,
        registerAccordionPanel,
        unregisterAccordionPanel,
        toggle,
      }}
    >
      {children}
    </AccordionContext.Provider>
  )
}

export const useRegisterAccordionItem = ({ index, expanded }: { index: AccordionBodyIndex; expanded?: boolean }) => {
  const { registerAccordionPanel, unregisterAccordionPanel } = useContext(AccordionContext)
  useEffect(() => {
    registerAccordionPanel(index, expanded)
    return () => {
      unregisterAccordionPanel(index)
    }
  }, [index, expanded, registerAccordionPanel, unregisterAccordionPanel])
}

export const useAccordionContextProvider = ({
  index,
  closeOnOpen,
}: {
  index: AccordionBodyIndex
  closeOnOpen?: boolean
}) => {
  const { accordionPanels, toggle } = useContext(AccordionContext)
  const currentAccordionPanel = accordionPanels.find((ap: AccordionBodyType) => ap.index === index)
  return {
    expanded: currentAccordionPanel ? currentAccordionPanel.expanded : false,
    toggle: () => toggle(index, closeOnOpen),
  }
}

export default Object.assign(AccordionContextProvider, {
  Item: AccordionItem,
  Header: AccordionHeader,
  Body: AccordionBody,
})
