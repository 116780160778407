import { Logo, theme } from '@by-miles/bymiles-styles'
import React from 'react'
import Helmet from 'react-helmet'
import styled from 'styled-components'

import { ReactComponent as ImgBrokenCar } from '../svg/broken-car.svg'
// TODO: This is old CSS code copied from bymiles-quote
const NotFoundStyled = styled.div`
  margin: 0 auto;
  @media (min-width: ${theme.grid.resolution.tablet.width}px) {
    width: ${theme.grid.resolution.tablet.width}px;
  }
  @media (min-width: ${theme.grid.resolution.desktop.width}px) {
    width: ${theme.grid.resolution.desktop.width}px;
  }
  .header {
    padding: 75px 15px;
  }
  .logo {
    width: 130px;
    height: 34px;
    border-radius: 0;
  }
  .body {
    position: relative;
    text-align: center;
    width: 100%;
    max-width: 600px;
    padding: 0 15px;
    margin: auto;
  }
  .lg-txt {
    font-size: 36px;
    font-family: BM-Biotif;
    font-weight: bold;
    color: ${theme.primaryColours.aubergine.darkAubergine};
    margin-bottom: 25px;
    @media (min-width: ${theme.grid.resolution.mobile.width}px) {
      font-size: 36px;
    }
  }
  .sm-txt {
    font-size: 21px;
    font-family: Roboto;
  }
  .pic {
    height: 80px;
    margin-top: 50px;
    margin-bottom: 50px;
    height: 100px;
  }
`
export const Maintenance = () => (
  <NotFoundStyled>
    <Helmet>
      <title>Error</title>
    </Helmet>
    <div className="header">
      <div className="logo">
        <Logo />
      </div>
    </div>
    <div className="body">
      <p className="lg-txt">Sorry, our quote site is currently undergoing maintenance.</p>

      <ImgBrokenCar className="pic" />
    </div>
  </NotFoundStyled>
)

export default Maintenance
