import config from './config'

export const GTMScript = () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    originalLocation:
      document.location.protocol +
      '//' +
      document.location.hostname +
      document.location.pathname +
      document.location.search,
  })
  return (function (w: any, d: any, s, l, i) {
    w[l] = w[l] || []
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    var f = d.getElementsByTagName(s)[0],
      j = d.createElement(s),
      dl = l !== 'dataLayer' ? '&l=' + l : ''
    j.async = true
    j.src =
      'https://www.googletagmanager.com/gtm.js?id=' +
      i +
      dl +
      `&gtm_auth=${config.gtmConfig?.gtmAuth}&gtm_preview=env-${config.gtmConfig?.gtmPreviewEnv}&gtm_cookies_win=x'=`
    f.parentNode.insertBefore(j, f)
  })(window, document, 'script', 'dataLayer', 'GTM-W6N6ZWK')
}
