import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const PolicyUsageOverlayStyles = styled.div`
  .overlay-heading {
    margin: ${theme.grid.spacing.s}px 0 ${theme.grid.spacing.base}px;
  }
  .overlay-subheading {
    margin-bottom: ${theme.grid.spacing.s}px;
  }
  .overlay-text {
    color: ${theme.primaryColours.aubergine.main};
    margin-bottom: ${theme.grid.spacing.base}px;
    .bold {
      font-weight: 700;
    }
  }
`
