import config from '../config'
const tracking = () => console.log('Tracking')

export const footerText =
  'Authorised and regulated by the Financial Conduct Authority (FCA) number 773046. By Miles Ltd, Churchill Court, Westmoreland Road, Bromley BR1 1DP is a registered company number 09498559 incorporated in the United Kingdom.'

export const footerLinks = [
  {
    label: 'TERMS OF BUSINESS',
    href: `${config.getBrochureOrigin}/terms-of-business`,
    tracking,
  },
  {
    label: 'PRIVACY NOTICE',
    href: `${config.getBrochureOrigin}/privacy-policy`,
    tracking,
  },
  {
    label: 'INFORMATION SECURITY',
    href: `${config.getBrochureOrigin}/information-security`,
    tracking,
  },
  {
    label: 'COOKIES',
    href: `${config.getBrochureOrigin}/cookies`,
    tracking,
  },
  {
    label: 'TERMS OF USE',
    href: `${config.getBrochureOrigin}/terms`,
    tracking,
  },
]
