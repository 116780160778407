import dayjs from 'dayjs'
import Cookie from 'js-cookie'
import first from 'lodash/first'
import isArray from 'lodash/isArray'
import ms from 'ms'
import queryString, { ParsedQuery } from 'query-string'
import yn from 'yn'

import config from '../config'

const cookieYesConsentCookieName = 'cookieyes-consent'

enum Categories {
  Necessary = 'necessary',
  Analytics = 'analytics',
}

type Consent = {
  necessary?: boolean
  functional?: boolean
  analytics?: boolean
  performance?: boolean
  advertisement?: boolean
  other?: boolean
}

const cookies: {
  [index: string]: {
    category: Categories
    cookieName: string
    duration?: string
    queryParam?: string
    defaultValue?: string
    overwritable?: boolean
    ignoreIfAdmin?: boolean
    setTimestamp?: boolean
    transform?: Function
    setLocalsOnly?: boolean
  }
} = {
  ots: {
    category: Categories.Necessary,
    cookieName: 'BM-ots-tracking',
    duration: '999 days',
    queryParam: 'ots',
    defaultValue: 'direct',
    overwritable: false,
    ignoreIfAdmin: true,
    setTimestamp: true,
    transform: (urlValue: string) => {
      let headerReferrer = (document.referrer || '').replace(/^(https?):\/\//, '')
      if (headerReferrer) headerReferrer = `direct-${headerReferrer}`
      return urlValue || headerReferrer
    },
  },
  cid: {
    category: Categories.Necessary,
    cookieName: 'BM-cid-tracking',
    duration: '60 days',
    queryParam: 'cid',
    overwritable: true,
    ignoreIfAdmin: true,
    setTimestamp: false,
  },
  dtl: {
    category: Categories.Necessary,
    cookieName: 'BM-dtl-tracking',
    duration: '60 days',
    queryParam: 'dtl',
    overwritable: true,
    ignoreIfAdmin: true,
    setTimestamp: true,
  },
  ppp: {
    category: Categories.Necessary,
    cookieName: 'BM-ppp-tracking',
    duration: '60 days',
    queryParam: 'ppp',
    overwritable: true,
    ignoreIfAdmin: true,
    setTimestamp: true,
  },
  rtg: {
    category: Categories.Necessary,
    cookieName: 'BM-rtg-tracking',
    duration: '60 days',
    queryParam: 'rtg',
    overwritable: true,
    ignoreIfAdmin: true,
    setTimestamp: true,
  },
  ref: {
    category: Categories.Necessary,
    cookieName: 'BM-ref-tracking',
    duration: '60 days',
    queryParam: 'ref',
    overwritable: true,
    ignoreIfAdmin: true,
    setTimestamp: true,
  },
  quoteId: {
    category: Categories.Necessary,
    cookieName: config.quoteIdCookieName,
    duration: '1 hour',
    queryParam: 'quote_id',
    overwritable: true,
  },
  quoteToken: {
    category: Categories.Necessary,
    cookieName: config.quoteTokenCookieName,
    duration: '1 hour',
    queryParam: 'quote_token',
    overwritable: true,
  },
  promo: {
    category: Categories.Necessary,
    cookieName: 'BM-promo',
    duration: '3 days',
    queryParam: 'promo',
    overwritable: true,
    ignoreIfAdmin: true,
    transform: (queryValue: string) => queryValue?.toUpperCase().replace(/\s+/g, ''),
  },
  'cookie-consented': {
    category: Categories.Necessary,
    cookieName: '',
    setLocalsOnly: true,
  },
  qqid: {
    category: Categories.Necessary,
    cookieName: '',
    queryParam: 'qqid',
    setLocalsOnly: true,
  },
  sid: {
    category: Categories.Necessary,
    cookieName: '',
    queryParam: 'sid',
    setLocalsOnly: true,
  },
  reg: {
    category: Categories.Necessary,
    cookieName: '',
    queryParam: 'reg',
    setLocalsOnly: true,
  },
  gaShort: {
    category: Categories.Analytics,
    cookieName: 'gaVisitorTypeShort',
    duration: '30 mins',
    defaultValue: 'prospect',
    overwritable: true,
    ignoreIfAdmin: true,
    setTimestamp: false,
    transform: () => Cookie.get('gaVisitorTypeShort') || Cookie.get('gaVisitorTypeLong'),
    setLocalsOnly: false,
  },
  gaLong: {
    category: Categories.Analytics,
    cookieName: 'gaVisitorTypeLong',
    duration: '2 years',
    defaultValue: 'prospect',
    overwritable: false,
    ignoreIfAdmin: true,
    setTimestamp: false,
    setLocalsOnly: false,
  },
}

function getCookieConsent(): Consent {
  const val = Cookie.get(cookieYesConsentCookieName)

  const result: { [index: string]: boolean } = {
    necessary: true,
    functional: false,
    analytics: false,
    performance: false,
    advertisement: false,
    other: false,
  }
  if (!val) return result

  val
    .split(',')
    .map(s => s.split(':'))
    .forEach(([type, opt]) => {
      const onOrOff = yn(opt)
      if (typeof onOrOff !== 'undefined') result[type] = onOrOff || false
    })
  return result
}

function getValue(query: ParsedQuery, queryParam: any, transform?: Function, defaultValue?: string) {
  let urlValue = isArray(query[queryParam]) ? first(query[queryParam])?.toString() : query[queryParam]?.toString()
  if (transform) urlValue = transform((urlValue || '').toString())
  return urlValue || defaultValue
}

export function cookieMonster(
  domain: string = config.strippedDomain,
  query: ParsedQuery = queryString.parse(document.location.search),
) {
  const cookieMonster: { [index: string]: any } = {}

  Object.keys(cookies).forEach(key => {
    const {
      cookieName,
      duration,
      queryParam,
      transform,
      overwritable,
      defaultValue,
      setLocalsOnly,
      ignoreIfAdmin,
      setTimestamp,
      category,
    } = cookies[key]
    const consent = getCookieConsent()
    const cookie = Cookie.get(cookieName)
    const isAdmin = Cookie.get('BM-admin')

    if (isAdmin && ignoreIfAdmin) {
      if (cookie) Cookie.remove(cookieName, { domain })
      return true
    }

    const isComparison =
      (query.dtl || '').includes('comparison') || (Cookie.get(cookies.dtl.cookieName) || '').includes('comparison')

    const isExperian =
      (query.ppp || '').includes('experian') || (Cookie.get(cookies.ppp.cookieName) || '').includes('experian')
    const isExperianPromo = (promo = '') => promo.toLowerCase() === 'experian20'

    const bannedExperianPromos = !(
      isExperian &&
      (isExperianPromo(Cookie.get(cookies.promo.cookieName)) ||
        isExperianPromo(getValue(query, queryParam, transform, defaultValue)))
    )
    if (key === 'promo' && isComparison && bannedExperianPromos) {
      if (cookie) Cookie.remove(cookieName, { domain })
      return true
    }

    if (setLocalsOnly && !queryParam) {
      cookieMonster[key] = Cookie.get(key)
      return true
    }

    if (setLocalsOnly && queryParam) {
      cookieMonster[key] = query[queryParam]
      return true
    }

    let value = getValue(query, queryParam, transform, defaultValue)

    if (setTimestamp && value) {
      const timestamp = dayjs().unix()

      const unixTimestampAfterColon = /(^.*):(\d{10})/
      const matches = value.match(unixTimestampAfterColon)

      if (!matches) {
        value += `:${timestamp}`
      } else {
        value.replace(unixTimestampAfterColon, timestamp.toString())
      }
    }
    if ((!cookie && value) || (overwritable && value)) {
      if (consent[category]) {
        let expires
        if (duration) {
          expires = dayjs().add(ms(duration), 'ms').toDate()
        }

        Cookie.set(cookieName, value, {
          expires,
          domain,
        })
      }
    }

    if (!consent[category]) Cookie.remove(cookieName)

    if (cookie && !overwritable) {
      cookieMonster[key] = cookie
    } else if (value) {
      cookieMonster[key] = value
    } else if (cookie) {
      cookieMonster[key] = cookie
    }
  })

  return cookieMonster
}
