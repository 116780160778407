import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const TrustQualifiersStyles = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  padding: 24px;
  border-radius: 8px;
  background: ${theme.primaryColours.white.main};
`
