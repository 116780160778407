import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

interface SkeletonLoaderProps {
  width?: {
    mobile?: string
    tablet?: string
    desktop?: string
  }
  height?: {
    mobile?: string
    tablet?: string
    desktop?: string
  }
}

export const SkeletonLoader = styled.div<SkeletonLoaderProps>`
  margin-top: 4px;
  position: relative;
  left: -1px;
  width: ${props => (props.width && props.width.mobile ? props.width.mobile : '121px')};
  height: ${props => (props.height && props.height.mobile ? props.height.mobile : '36px')};
  @media (min-width: ${theme.grid.resolution.tablet.width}px) {
    width: ${props => (props.width && props.width.tablet ? props.width.tablet : '182px')};
    height: ${props => (props.height && props.height.tablet ? props.height.tablet : '32px')};
  }
  @media (min-width: ${theme.grid.resolution.desktop.width}px) {
    width: ${props => (props.width && props.width.desktop ? props.width.desktop : '197px')};
    height: ${props => (props.height && props.height.desktop ? props.height.desktop : '34px')};
  }
  background: -webkit-gradient(
    linear,
    left top,
    right top,
    color-stop(25%, #f2f2f2),
    color-stop(37%, #e6e6e6),
    color-stop(63%, #f2f2f2)
  );
  background: -webkit-linear-gradient(left, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 37%, #f2f2f2 63%);
  -webkit-animation: ant-skeleton-loading 0.4s ease infinite;
  animation: skeleton-loading 1.4s ease infinite;
  background-size: 400% 100%;
  @keyframes skeleton-loading {
    0% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0 50%;
    }
  }
`
