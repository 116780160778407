interface sanitizeCarModelProps {
  make: string | undefined
  model: string | undefined
  trim: string | undefined | null
}

export const sanitizeCarModel = ({ make, model, trim }: sanitizeCarModelProps) => {
  if (!make && !model && !trim) return ''
  if (model === 'MINI' && trim) return trim
  if (make === 'DS') return model?.replace('DS ', '')
  return model
}
