import { theme } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'

import Accordion from '../shared/AccordionV2/Accordion'

const FAQAccordionBodyStyled = styled.div<{ background?: string }>`
  background: ${({ background }) => (background ? background : theme.primaryColours.white.main)};
  color: ${theme.primaryColours.aubergine.main};
  height: auto;
  overflow: visible;
  padding: 12px 24px 24px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  margin-bottom: ${theme.grid.spacing.s}px;
`

export const FAQAccordionBody: React.FC<{ background?: string }> = ({ children, background, ...props }) => {
  return (
    <Accordion.Body {...props}>
      <FAQAccordionBodyStyled background={background}>{children}</FAQAccordionBodyStyled>
    </Accordion.Body>
  )
}
