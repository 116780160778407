export function getAnnualEstimate(estimateMiles) {
  const quotePricing = this.state.quotePricing
  const promo = quotePricing.promo.type === 'referAFriend' ? 0 : quotePricing.promo.amount
  const extras = quotePricing.extras
  const monthlyCost =
    quotePricing.payment_frequency === 'monthly' && quotePricing.monthly ? quotePricing.monthly.mon * 11 : 0
  const fixedUpfrontCost =
    quotePricing.payment_frequency === 'monthly' && quotePricing.monthly
      ? parseFloat(monthlyCost + parseFloat(quotePricing.fixedUpfront))
      : quotePricing.fixedUpfront
  const netMileageCost = Math.max(Number(estimateMiles) * Number(quotePricing.perMile / 100) - (promo || 0), 0)

  const fixedUpfrontCostMonthly = quotePricing.monthly
    ? (Number(quotePricing.monthly.upfront) + Number(extras)).toFixed(2)
    : null
  const averageMonthlyMonthly = quotePricing.monthly
    ? ((netMileageCost + quotePricing.monthly.mon * 11) / 12).toFixed(2)
    : null
  const projectedMonthlyAnnual = (averageMonthlyMonthly * 12 + Number(fixedUpfrontCostMonthly)).toFixed(2)

  return quotePricing.payment_frequency === 'monthly'
    ? projectedMonthlyAnnual
    : (netMileageCost + Number(fixedUpfrontCost)).toFixed(2)
}
