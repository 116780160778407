import {
  ButtonGeneral,
  H2,
  H5,
  IconStar,
  LabelSmall,
  MultiVariantLabel,
  ParagraphBody,
  RadioCardList,
  theme,
} from '@by-miles/bymiles-styles'
import { DataLayer } from '@by-miles/data-layer'
import React, { ReactNode, useContext, useState } from 'react'

import { genericErrorCopy } from '../../../constants'
import { Extra } from '../../../generated/graphql'
import { updateQuoteMutation } from '../../../hooks'
import { useQuote } from '../../../hooks/useQuote'
import { ProviderContext } from '../../../Provider'
import { AddExtraAction, RemoveExtraAction } from '../../../utils/extrasOverlayTypes'
import { Link } from '../../../utils/links'
import { ExtrasLogo } from '../../Extras/ExtrasLogo'
import { ExternalLinks } from '../../shared/ExternalLinks'
import { GenericOverlay } from '../GenericOverlay/GenericOverlay'
import { ExtrasOverlayStyles } from './ExtrasOverlayStyles'
import { ExtrasRadioCardContent } from './ExtrasRadioCardContent'
import { getBulletCopy } from './utils/copy'

interface ExtrasOverlayProps {
  heading: string
  openingPara: string
  subHeading: string
  linkHeading: string
  links: Link[]
  smallPrint: ReactNode
  logo: string
  type: 'breakdown' | 'legal' | 'car-hire'
  tiers: Extra[]
  tierSelected: Extra[]
  removeAction: RemoveExtraAction
  addAction: AddExtraAction
}

export const ExtrasOverlay = ({
  heading,
  openingPara,
  subHeading,
  linkHeading,
  links,
  smallPrint,
  logo,
  type,
  tiers,
  tierSelected,
  removeAction,
  addAction,
}: ExtrasOverlayProps) => {
  const { state, simpleStateUpdater, setIsFetching } = useContext(ProviderContext)
  const { updateQuote } = updateQuoteMutation()
  const quote = useQuote()
  const offeredFreeLegalCover = quote?.details?.site?.wasOfferedFreeLegal

  const defaultCoverLevel = tierSelected.map(tier => tier?.code)[0]

  const [extrasError, setExtrasError] = useState({ buttonError: false, panelError: false })
  const [coverLevel, setCoverLevel] = useState(defaultCoverLevel)

  const scrollToButton = () => {
    const btn = document.querySelector('.cta')
    if (btn) {
      setTimeout(() => {
        btn.scrollIntoView({ behavior: 'smooth' })
      }, 1000)
    }
  }

  const handleSelection = (value: string) => {
    setCoverLevel(value)
    setExtrasError({ buttonError: false, panelError: false })
    scrollToButton()
  }

  const closeModal = () => {
    // this was done for the extras POPUP in the cover page.
    // if the popup is showing, closing the extras overlay does not close the pop up, but returns to it.
    if (state.shouldShowExtrasPopUp) {
      simpleStateUpdater([{ state: 'activeModal', value: 'extras-popup' }])
    } else {
      simpleStateUpdater([{ state: 'showModal', value: false }])
    }
  }

  const handleExtras = async () => {
    setIsFetching(true)
    let actions
    if (coverLevel === 'none') {
      actions = {
        [removeAction]: true,
      }
    } else {
      actions = {
        [addAction]: { code: coverLevel },
      }
    }
    await updateQuote({
      variables: {
        id: state.quoteId,
        actions,
      },
    })
    setIsFetching(false)
  }

  const handleBackgroundClicked = (elementClicked: string) => {
    if (elementClicked === 'modal-background') {
      if (defaultCoverLevel && coverLevel && defaultCoverLevel === coverLevel) {
        closeModal()
      } else {
        if (coverLevel) {
          setExtrasError({ buttonError: true, panelError: false })
        } else {
          setExtrasError({ buttonError: false, panelError: true })
        }
      }
    }
  }

  const trackExtraSelected = (elementClicked: string) => {
    DataLayer.customEvent({
      eventName: elementClicked === 'none' ? `remove ${type} cover` : `add ${type} cover`,
      section: 'extras',
      eventType: 'form tracking',
    })
  }

  const handleConfirmButton = () => {
    if (coverLevel) {
      setExtrasError({ buttonError: false, panelError: false })
      trackExtraSelected(coverLevel)
      handleExtras()
      closeModal()
      return
    }
    setExtrasError({ buttonError: true, panelError: true })
  }

  const radioSections = tiers.map(extra => {
    const { code, description, popular, amountInPounds } = extra
    return {
      label: (
        <MultiVariantLabel
          text={description}
          className="heading"
          resolutions={{
            mobile: 'LabelMedium',
            tablet: 'LabelLarge',
            desktop: 'LabelLarge',
          }}
        />
      ),
      value: code,
      tag: popular && (
        <div className="badge">
          <div className="flex">
            <IconStar fill={theme.primaryColours.mint.darkMint} />
            <LabelSmall>COMPLETE UK COVER</LabelSmall>
          </div>
        </div>
      ),
      content: (
        <ExtrasRadioCardContent
          offeredFreeLegalCover={!!offeredFreeLegalCover}
          isLegalCover={type === 'legal'}
          amountInPounds={amountInPounds}
          {...getBulletCopy(code)}
          code={code}
        />
      ),
    }
  })

  return (
    <GenericOverlay
      onClose={event => handleBackgroundClicked(event.target.id)}
      hideDismissIcon={true}
      overlay={
        <ExtrasOverlayStyles>
          <div className="LoNotSensitive">
            {logo && <ExtrasLogo logo={logo} altText={`${type} cover logo`} />}
            <H2 className="overlay-heading">{heading}</H2>
            <ParagraphBody className="opening-para">{openingPara}</ParagraphBody>
            <H5 className="sub-heading">{subHeading}</H5>
            <RadioCardList
              name="extras-radio-list"
              sections={radioSections}
              onClick={value => handleSelection(value)}
              preFilled={defaultCoverLevel}
              error={extrasError.panelError ? genericErrorCopy : ''}
            />
            <ButtonGeneral className="cta" onClick={handleConfirmButton} label="Confirm" />
            <H5 className="small-print-heading">{linkHeading}</H5>
            {links &&
              links.map(l => (
                <div className="links">
                  <ExternalLinks label={l.label} href={l.href} />
                </div>
              ))}
            {smallPrint && smallPrint}
          </div>
        </ExtrasOverlayStyles>
      }
    />
  )
}
