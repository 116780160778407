import { ParagraphBody } from '@by-miles/bymiles-styles'
import React from 'react'

export const SDPCPH = () => (
  <>
    <ParagraphBody className="overlay-text">
      This covers the policyholder and all named drivers for things like visiting family and friends, school runs and
      trips to the shops.
    </ParagraphBody>
    <ParagraphBody className="overlay-text">
      In addition, the policyholder can use the car to travel to and from their usual place of work. But it{' '}
      <span className="bold">doesn’t</span> cover any driving the policyholder may need to do as part of their job -
      like visiting clients, travelling to meetings, or driving to different sites, or locations in connection with
      work.
    </ParagraphBody>
  </>
)
