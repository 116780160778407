import { H2, ParagraphBody, theme } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'

import { PerMile } from '../../PerMile'
import { DynamicOverlayTable } from '../DynamicOverlayTable'
import { calculateMonthlyCost } from './calculateMonthlyCost'

const FlexiblePricingOverlayStyle = styled.div`
  width: 100%;
  border-radius: 8px;
  background: transparent;
  color: ${theme.primaryColours.aubergine.main};
  .overlay-heading {
    margin-bottom: 24px;
  }

  .per-mile-container {
    background: ${theme.tints.lightAubergine};
    margin-top: ${theme.grid.spacing.md}px;
    .flexible-per-mile {
      width: 100%;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        width: 100%;
      }
      @media (min-width: ${theme.grid.resolution.desktop.width}px) {
        width: 100%;
      }
    }
  }
`
export const FlexiblePricingOverlay = ({ estimatedMiles, perMileCost }) => {
  const calculatedMilesPrices = calculateMonthlyCost(parseInt(estimatedMiles), perMileCost)

  return (
    <FlexiblePricingOverlayStyle>
      <div className="LoNotSensitive">
        <H2 className="overlay-heading">How does our flexible pricing work?</H2>
        <ParagraphBody className="overlay-content">
          After you’ve paid your upfront cost (whether you’re paying in one go at the start of the year or splitting it
          monthly), then you just need to pay for the miles you’ve driven each month at your per-mile rate. You can see
          in the table below how much you’d be likely to pay each month if you drive more or less than you’ve estimated.
        </ParagraphBody>
        <div className="per-mile-container">
          <PerMile pence={perMileCost} background="transparent" className="flexible-per-mile" />
        </div>
        <div className="projected-table-container">
          <DynamicOverlayTable
            mileageTitle="MONTHLY MILEAGE"
            costTitle="TOTAL MONTHLY COST"
            prettyEstimates={calculatedMilesPrices}
          />
        </div>
      </div>
    </FlexiblePricingOverlayStyle>
  )
}
