import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const MultiplePolicyDocStyles = styled.div`
  width: 100%;
  border-radius: 8px;
  background: transparent;
  color: ${theme.primaryColours.aubergine.main};

  .overlay-heading {
    max-width: 200px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-bottom: 8px;
      max-width: none;
    }
    @media (min-width: ${theme.grid.resolution.desktop.width}px) {
      margin-bottom: 24px;
    }
  }

  .overlay-button {
    width: 100%;
    margin-top: 24px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      width: auto;
    }
  }

  .item {
    position: relative;
    padding: 24px 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .icon {
      display: flex;
    }
  }
`
