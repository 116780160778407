import { theme } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'

const { resolution } = theme.grid

const LogoStyle = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  line-height: 0;
  ${({ style }) => style && style}
  svg {
    height: 26.7px;
    width: 100px;
    @media (min-width: ${resolution.tablet.width}px) {
      width: 135px;
      height: 35.2px;
    }
    @media (min-width: ${resolution.desktop.width}px) {
      width: 130px;
      height: 34px;
    }
    ${({ svgStyle }) => svgStyle && svgStyle}
  }
  img {
    height: auto;
    width: 168px;
    max-width: 200px;
    @media (min-width: ${resolution.tablet.width}px) {
      height: 60px;
      width: 200px;
    @media (min-width: ${resolution.desktop.width}px) {
      height: 60px;
      width: 200px;
    }
  }
`

export const Logo = props => {
  return (
    <LogoStyle {...props}>
      {props.showChurchill ? (
        <img src={require('../../png/bymiles-churchill@2x.png')} alt="" />
      ) : (
        <svg xmlns="http://www.w3.org/2000/svg" width="959" height="247" viewBox="0 0 959 247">
          <g fill="none" fillRule="evenodd">
            <path
              fill="#320B42"
              d="M101.613 133.32c0-15.172-11.192-24.87-26.858-24.87H34.469v49.24h40.286c16.412 0 26.858-9.7 26.858-24.37zM34.469 33.349v49.736h38.048c18.402 0 26.858-11.937 26.858-24.619-.25-12.435-8.456-25.117-26.858-25.117H34.469zM134.935 138.79c0 30.59-23.624 47.74-58.937 47.74H.898V4.502H75.75c35.809 0 57.196 20.144 57.196 49.487 0 21.386-11.191 35.063-24.868 41.779 14.423 6.962 26.857 20.64 26.857 43.022zM281.538 63.19l-58.15 149.77c-14.817 37.29-44.689 33.5-69.418 33.5l.17-27.63c16.855 0 34.042 2.54 41.605-14.64l3.979-10.44-52.721-130.56h34.319l33.571 89.03 32.825-89.03h33.82M518.28 4.254V186.53h-33.32V80.1l-42.028 106.43h-32.328L369.075 80.846V186.53h-32.577V4.254h32.577l57.692 142.736L484.96 4.254h33.32M546.04 186.53h32.57V63.19h-32.57zM606.87 186.53h32.58V.773h-32.58zM691.59 113.921h59.44c-1.24-18.651-12.68-27.604-28.6-27.604-16.91 0-27.85 9.699-30.84 27.604zM784.1 136.3h-93c1.99 18.4 16.41 25.86 31.33 25.86 14.92 0 24.37-6.96 29.84-17.65l26.86 10.69c-8.95 21.64-30.84 34.57-56.7 34.57-36.56 0-61.92-25.86-61.92-64.91 0-38.791 24.87-65.4 62.42-65.4 36.8 0 61.17 25.863 61.17 65.4v11.44zM847.84 163.91c9.95 0 17.66-3.74 17.66-12.44 0-6.46-4.23-9.95-11.94-11.94l-16.41-4.47c-26.61-6.96-35.81-20.144-35.81-36.308 0-22.133 16.91-39.292 46.75-39.292 24.37 0 43.52 11.688 47.25 32.577l-29.09 6.715c-1-8.456-7.96-13.181-17.16-13.181-9.45 0-15.92 4.725-15.92 12.683 0 5.969 3.98 9.698 13.93 12.434l16.16 4.228c22.63 5.967 34.82 18.404 34.82 36.554 0 22.63-17.91 38.3-49.74 38.3-30.84 0-47.5-14.92-50.48-35.07l29.84-4.97c.5 8.7 8.21 14.18 20.14 14.18M916.8 170.12c0-11.19 9.21-20.64 20.89-20.64 10.94 0 20.64 9.45 20.64 20.64 0 11.19-9.7 20.39-20.64 20.39-11.68 0-20.89-9.2-20.89-20.39M541.56 24.895c0-11.191 9.2-20.641 20.89-20.641 10.94 0 20.64 9.45 20.64 20.641 0 11.19-9.7 20.392-20.64 20.392-11.69 0-20.89-9.202-20.89-20.392"
            />
            <path
              fill="#F9644B"
              d="M336.816 246.63c-7.63 0-13.816-6.19-13.816-13.82S329.186 219 336.816 219h561.582c7.63 0 13.81 6.18 13.81 13.81 0 7.63-6.18 13.82-13.81 13.82H336.816z"
            />
          </g>
        </svg>
      )}
    </LogoStyle>
  )
}
