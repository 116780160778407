import { theme } from '@by-miles/bymiles-styles'
import { IconCaretUp } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'

const AccordionStyle = styled.div`
  .accordion-step-wrapper {
    background: ${theme.primaryColours.white.main};
    color: ${theme.primaryColours.aubergine.main};
    width: 100%;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    position: relative;
    margin-bottom: ${props => (props.type === 'smart' ? 0 : theme.grid.spacing.s)}px;
    .accordion-title {
      position: relative;
      padding: ${props => (props.type === 'smart' ? '16px 16px' : '12px 24px')};
      width: 100%;
      min-height: 37px;
      border-left: ${props => (props.type === 'smart' ? 'none' : `3px solid ${theme.primaryColours.aubergine.main}`)};
      cursor: pointer;
      p {
        width: 97%;
      }
      .simple-icon {
        display: ${props => (props.type === 'smart' ? 'none' : 'block')};
        position: absolute;
        top: 13px;
        right: 23px;
        svg {
          ${props => !props.active && `transform: rotate(180deg);`}
        }
      }
      .smart-title {
        display: flex;
        width: 100%;
        flex-grow: 1;
        justify-content: space-between;
        .edit-button {
          display: ${props => (props.type === 'smart' && props.completed ? 'block' : 'none')};
          .icon__caret-down {
            position: relative;
            left: -5px;
            top: 3px;
          }
        }
      }
    }
  }

  .accordion-body {
    height: ${props => (props.active ? 'auto' : '0')};
    overflow: ${props => (props.active ? 'visible' : 'hidden')};
    padding: ${props => (props.active ? '0px 24px' : '0')};
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    padding-bottom: ${props => (props.type === 'smart' && props.active ? '24px' : '0')};
    .body-title {
      margin-bottom: 8px;
    }
    &.no-padding {
      padding-right: 0;
      padding-left: 0;
    }
  }

  .confirm-accordion {
    position: relative;
    left: ${theme.grid.spacing.base}px;
  }

  ${({ style }) => style}
`

export const AccordionStep = ({
  type = 'simple',
  step = {},
  active = false,
  completed = false,
  onClick = () => {
    console.log('clicked!')
  },
  style,
  stepTitleStyling,
  completedIcon = <span>&#10003;</span>,
  noPaddingBody = false,
}) => {
  return (
    <AccordionStyle active={active} style={style} type={type} completed={completed} stepTitleStyling={stepTitleStyling}>
      <div className="accordion-step-wrapper LoNotSensitive">
        <div className="accordion-title" onClick={onClick}>
          <div>
            {completed && <span className="completed-icon">{completedIcon}</span>} {step.title}
          </div>
          {type === 'simple' && <IconCaretUp className="simple-icon" />}
        </div>
        <div className={`accordion-body ${noPaddingBody ? 'no-padding' : ''}`}>{step.body}</div>
      </div>
    </AccordionStyle>
  )
}
