export const carValidationInputs = {
  hasModifications: false,
  modifications: true,
  confirmPurchaseDate: false,
  customPurchaseDate: true,
  legalOwner: false,
  keeper: false,
  parkedAtHome: false,
  postcode: true,
  mileage: false,
}
export const driverValidationInputs = {
  title: false,
  firstName: false,
  lastName: false,
  dob: false,
  bornInUk: false,
  residencyDate: true,
  children: false,
  address: false,
  email: false,
  mobileNumber: false,
  employment: false,
  business: true,
  occupation: true,
  licenceType: false,
  licenceIssuedDate: false,
  ncd: false,
  drivesOtherVehicle: true,
  hasMedicalConditions: false,
  hasInformedDvlaMedicalConditions: true,
  hasClaims: false,
  hasDrivingConvictions: false,
  hasCriminalConviction: false,
}
export const additionalDriverValidationInputs = {
  title: false,
  firstName: false,
  lastName: false,
  dob: false,
  bornInUk: false,
  residencyDate: true,
  relationship: false,
  employment: false,
  business: true,
  occupation: true,
  licenceType: false,
  licenceIssuedDate: false,
  hasClaims: false,
  hasMedicalConditions: false,
  hasInformedDvlaMedicalConditions: true,
  hasDrivingConvictions: false,
  hasCriminalConviction: false,
}
export const coverValidationInputs = {
  policyStart: false,
  excess: false,
  paymentType: false,
}
