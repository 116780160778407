import { ButtonSecondary } from '@by-miles/bymiles-styles'
import { theme } from '@by-miles/bymiles-styles'
import React, { useContext } from 'react'
import styled from 'styled-components'

import { ProviderContext } from '../../Provider'
import { GenericOverlay } from '../Overlays'
import { SaveQuote } from '../Overlays/SaveQuote'
import { Logo } from './Logo'

const { resolution } = theme.grid

const HeaderStyle = styled.header`
  width: 100%;
  box-sizing: border-box;
  background: white;

  @media (min-width: ${resolution.desktop.width}px) {
    padding: ${theme.grid.spacing.base}px 0;
  }

  #modal-background {
    z-index: 15;
  }
  .container {
    display: flex;
    align-items: center;
    width: 100%;
    padding: ${theme.grid.spacing.xs}px ${resolution.mobile.margin}px;
    box-sizing: border-box;

    @media (min-width: ${resolution.tablet.width}px) {
      width: ${resolution.tablet.container}px;
      margin: auto;
      padding: ${theme.grid.spacing.base}px 0;
    }

    @media (min-width: ${resolution.desktop.width}px) {
      width: ${resolution.desktop.width}px;
      margin: auto;
      padding: 0;
    }
  }
`
interface headerTypes {
  onClick: () => void
  onSaveQuoteClick: () => void
  hideSaveQuote: boolean
}

export const Header = ({ onClick, onSaveQuoteClick, hideSaveQuote }: headerTypes) => {
  const { state, handleCloseModal } = useContext(ProviderContext)

  return (
    <HeaderStyle>
      <div className="container">
        <Logo onClick={onClick} svgStyle={{ cursor: 'pointer' }} showChurchill={state.showChurchill} />
        {!hideSaveQuote && (
          <ButtonSecondary label="Save&nbsp;Quote" onClick={onSaveQuoteClick} tabFocusTrap={state.showModal ? -1 : 0} />
        )}
      </div>
      {state.showModal && state.activeModal === 'saveQuoteModal' && (
        <GenericOverlay onClose={() => handleCloseModal('saveQuoteModal')} overlay={<SaveQuote />} />
      )}
    </HeaderStyle>
  )
}
