import { numbersWithCommas } from '../../../utils/numbersWithComas'
export const calculateMonthlyCost = (estimatedMiles, perMileCost) => {
  const calculationArray = []
  let numbers
  const milesPerMonth = estimatedMiles > 0 ? (estimatedMiles / 12).toFixed(2) : 0

  if (milesPerMonth) {
    if (milesPerMonth > 100) {
      let firstNumber
      let beforeFirstNumber

      beforeFirstNumber =
        Math.floor(parseFloat(milesPerMonth) / 100) * 100 === parseFloat(milesPerMonth)
          ? Math.floor((parseFloat(milesPerMonth) - 100) / 100) * 100
          : Math.floor(parseFloat(milesPerMonth) / 100) * 100

      firstNumber =
        Math.floor((parseFloat(milesPerMonth) - 100) / 100) * 100 < 100
          ? Math.floor((parseFloat(milesPerMonth) - 10) / 100) * 100 - 10
          : Math.floor((parseFloat(milesPerMonth) - 100) / 100) * 100

      if (beforeFirstNumber === firstNumber) {
        if (beforeFirstNumber <= 100) {
          firstNumber = Math.floor((beforeFirstNumber - 10) / 100) * 100 - 10
        } else {
          firstNumber = Math.floor((beforeFirstNumber - 100) / 100) * 100
        }
      }

      numbers = [
        firstNumber > 0 ? firstNumber : false,
        beforeFirstNumber,
        parseFloat(milesPerMonth),
        Math.floor((parseFloat(milesPerMonth) + 100) / 100) * 100,
        Math.floor((parseFloat(milesPerMonth) + 200) / 100) * 100,
      ]
    } else if (milesPerMonth <= 100 && milesPerMonth > 10) {
      const beforeLastNumber =
        Math.ceil(milesPerMonth / 100) * 100 === parseFloat(milesPerMonth)
          ? Math.ceil(parseFloat(milesPerMonth) / 100) * 100 + 100
          : Math.ceil(parseFloat(milesPerMonth) / 100) * 100

      const lastNumber = Math.ceil(beforeLastNumber / 100) * 100 + 100

      numbers = [
        Math.round((parseFloat(milesPerMonth) - 20) / 10) * 10,
        Math.round((parseFloat(milesPerMonth) - 10) / 10) * 10,
        parseFloat(milesPerMonth),
        beforeLastNumber,
        lastNumber,
      ]
    } else if (milesPerMonth <= 10) {
      if (parseFloat(milesPerMonth) === 10) {
        numbers = [parseFloat(milesPerMonth), 20, 30, 40]
      } else {
        numbers = [parseFloat(milesPerMonth), 10, 20, 30]
      }
    }

    if (numbers) {
      numbers.forEach(number => {
        let estimateCost
        if (number >= 833) {
          estimateCost = ((833 * perMileCost) / 100).toFixed(2).toString()
        } else {
          estimateCost = ((number * perMileCost) / 100).toFixed(2).toString()
        }
        if (number > 0) {
          calculationArray.push({
            estimateMiles: numbersWithCommas(parseInt(number)),
            estimateCost,
            active: Math.max(numbers) === number || number === parseFloat(milesPerMonth),
          })
        }
      })
    }
  }

  return calculationArray
}
