import { Quote } from '../generated/graphql'

export const setPricingState = function (quote: Quote) {
  const costOfExtras =
    quote?.extras
      .filter(e => e?.selected)
      .map(e => e?.amountInPounds || 0)
      .reduce((a, b) => a + b, 0)
      .toFixed(2) || 0
  //@ts-ignore
  this.setState({
    quotePricing: {
      fixedUpfront: quote?.pricing?.fixedUpfrontCost,
      fixedUpfrontPure: quote?.pricing?.fixedUpfrontCost,
      perMile: quote.pricing?.perMileRate,
      estimatedMiles: quote.details?.vehicle?.estimated_mileage?.toString(),
      extras: costOfExtras,
      promo: {
        amount: quote.promo?.amount,
        type: quote.promo?.type,
      },
      cancellationFee: quote.pricing?.cancellationFee,
      milesTrackerNotReturnedFee: quote.pricing?.trackerNotReturnedFee,
      annual: quote.pricing?.annual,
      monthly: quote.pricing?.monthly,
      payment_frequency: quote.details.policy?.payment_frequency || 'yearly',
      isMonthlyEligible: quote.pricing?.isMonthlyEligible,
      monthlyMileageCap: null,
    },
  })
}
