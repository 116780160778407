import { ButtonPrimary, H2, ParagraphBody, theme } from '@by-miles/bymiles-styles'
import React from 'react'

export const ConfirmationModal = ({ onClose }) => {
  return (
    <div className="LoNotSensitive">
      <H2 className="overlay-heading margin-bottom">Saved. See you around.</H2>
      <ParagraphBody style={{ color: theme.primaryColours.aubergine.main }} className="margin-bottom">
        We've sent you an email. Just click the button to come back in the next 30 days and all your details will be
        waiting for you.
      </ParagraphBody>
      <ButtonPrimary label="Close" onClick={onClose} />
    </div>
  )
}
