import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const IdScoreOverlayStyles = styled.div`
  width: 100%;
  border-radius: 8px;
  background: transparent;
  color: ${theme.primaryColours.aubergine.main};

  a {
    color: ${theme.primaryColours.aubergine.main};
    font-weight: bold;
  }

  .overlay-heading {
    margin-bottom: 24px;
  }

  .intro-content {
    margin-bottom: 24px;
  }

  .verify-details-container {
    background: ${theme.tints.lightAubergine};
    padding: 24px;
    margin-bottom: 24px;
  }

  .details-section {
    margin-bottom: 16px;
    position: relative;
    .label {
      text-transform: uppercase;
    }
    &:after {
      content: '';
      position: absolute;
      bottom: -8px;
      width: 100%;
      height: 1px;
      background: ${theme.tints.darkAubergine};
    }
    &:nth-child(3) {
      margin-bottom: 0;
      &:after {
        display: none;
      }
    }
  }

  .button-wrapper {
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      display: flex;
    }
    .button {
      display: block;
      width: 100%;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        width: auto;
      }
    }
    .continue-button {
      margin-right: 8px;
      margin-bottom: 24px;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        margin-bottom: 0;
      }
    }
  }
`
