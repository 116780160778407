import { CheckButtonTheme, theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

const {
  grid: { resolution },
  primaryColours,
  tints,
} = theme

export const StepIndicatorStyles = styled.div<{ buttonTheme: CheckButtonTheme }>`
  .step-indicator-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    .icon {
      @media (max-width: ${resolution.mobile.width}px) {
        width: 22px;
      }
    }
  }
  .step-wrapper {
    display: flex;
    justify-content: center;
    .breadcrumb-wrapper {
      .breadcrumb-container {
        display: flex;
        align-items: center;
        .icon {
          width: 28px;
          margin: 0;
        }
      }
      .divider {
        ${({ buttonTheme }) =>
          `background-color: ${buttonTheme === CheckButtonTheme.Light ? primaryColours.white.main : tints.aubergine};`};
        height: 1px;
        width: 39px;
      }
    }
  }
  .name-wrapper {
    display: flex;
    justify-content: center;
    gap: 9px;
    ${({ buttonTheme }) =>
      `color: ${buttonTheme === CheckButtonTheme.Light ? primaryColours.white.main : primaryColours.aubergine.main};`};
    .stage-title {
      display: flex;
      justify-content: center;
      width: 58px;
      padding-top: 6px;
    }
  }
`

export const BreadcrumbStyled = styled.div<{
  clickable: boolean
}>`
  .breadcrumb,
  .stage-title {
    ${({ clickable }) => `cursor: ${clickable ? 'pointer' : 'auto'};`};
  }
`
