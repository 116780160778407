import React from 'react'

import { Trustpilot } from './Trustpilot'
import { TrustQualifiersStyles } from './TrustQualifiersStyles'

interface TrustQualifiersProps {
  trustpilotAvailable: boolean
}

export const TrustQualifiers = ({ trustpilotAvailable }: TrustQualifiersProps) => {
  return (
    <TrustQualifiersStyles>
      <Trustpilot trustpilotAvailable={trustpilotAvailable} />
    </TrustQualifiersStyles>
  )
}
