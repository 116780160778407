import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

interface ContactUsWrapperProps {
  // isOpen: boolean
  CXBannerEnabled?: boolean
}

export const Wrapper = styled.div<ContactUsWrapperProps>`
  .container {
    width: 100%;
    padding: 32px 24px;
    border-radius: 4px;
    background: white;
    .image {
      display: none;
    }

    a {
      text-decoration: none;
    }

    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      background: transparent;
      display: flex;
      width: 100%;
      height: ${props => (props.CXBannerEnabled ? 'auto' : '362px')}
      margin: 0;
      border-radius: 0;
      padding: 0;
      .image-container {
        background: ${theme.tints.aubergine};
        width: 50%;
        display: flex;
        position: relative;
        border-radius: 4px 0 0 4px;
        height: ${props => (props.CXBannerEnabled ? 'auto' : '100%')};
        .image {
          pointer-events: none;
          padding: 64px;
          display: block;
          width: 100%;
          max-height: 412px;
          top: ${props => (props.CXBannerEnabled ? '20%' : '0')};
          position: relative;
        }
      }
    }
    @media (min-width: ${theme.grid.resolution.desktop.width}px) {
      height: ${props => (props.CXBannerEnabled ? 'auto' : '412px')}
    }
    .details {
      background: white;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        padding-left: 32px;
        padding-top: 32px;
        border-radius: 0 4px 4px 0;
        width: 50%;
      }

      @media (min-width: ${theme.grid.resolution.desktop.width}px) {
        padding-top: 49px;
      }

      .heading {
        color: ${theme.primaryColours.aubergine.main};
        margin-bottom: 16px;

        @media (min-width: ${theme.grid.resolution.tablet.width}px) {
          margin-bottom: 0;

          &.main {
            margin-bottom: 16px;
          }

          &.sub {
            margin-bottom: 4px;
          }

          &.telephone {
            margin-bottom: 8px;
          }
        }
      }

      .hide-tablet {
        @media (min-width: ${theme.grid.resolution.tablet.width}px) {
          display: none;
        }
      }

      .phone-wrapper {
        margin-top: 24px;
        margin-bottom: ${theme.grid.spacing.s}px;
        @media (min-width: ${theme.grid.resolution.tablet.width}px) {
          margin-bottom:${props => (props.CXBannerEnabled ? 'auto' : `${theme.grid.spacing.xs}px`)};
          margin-top: ${props => (props.CXBannerEnabled ? 'auto' : '48px')};
        }
        @media (min-width: ${theme.grid.resolution.desktop.width}px) {
          margin-top: ${theme.grid.spacing.l}px;
        }
      }

      .call-us-paragraph {
        margin-bottom: ${theme.grid.spacing.md}px;
        @media (min-width: ${theme.grid.resolution.desktop.width}px) {
          margin-bottom: ${theme.grid.spacing.l}px;
        }
      }

      .opening-times-details {
        margin-top: 16px;
      }
    }
  }
`
// * Left incase of future use
// .indicator {
//   position: relative;
//   &:after {
//     content: ' ';
//     position: absolute;
//     width: 100%;
//     height: 2px;
//     background-color: ${props =>
//       props.isOpen ? theme.primaryColours.mint.main : theme.primaryColours.tangerine.darkTangerine};
//     border-radius: 10px;
//     left: 0;
//     bottom: -4px;
//   }
// }
