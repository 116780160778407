import { LegacyQuoteHeader } from '@by-miles/bymiles-styles'
import React from 'react'

const LegacyHeader = props => {
  const headerProps = {
    logoState: false,
    currentUrl: '/car',
    index: 0,
    savePages: ['/car', '/drivers', '/cover', '/quote', '/pay'],
    Links: [
      { text: 'Car', link: '/car' },
      { text: 'Driver', link: '/drivers' },
      { text: 'Cover', link: '/cover' },
      { text: 'Quote', link: '/quote' },
    ],
  }
  return (
    <div>
      <LegacyQuoteHeader {...headerProps} {...props} />
    </div>
  )
}

export default LegacyHeader
