export const handleShowChurchill = (sid: string) => {
  switch (sid) {
    case 'ctm':
    case 'msm':
    case 'confused':
      return true
    default:
      return false
  }
}
