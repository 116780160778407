import { H2, H5, MessageBanner, ParagraphBody, theme } from '@by-miles/bymiles-styles'
import React from 'react'
import styled from 'styled-components'

import { PluginListItem } from './ListItem'

const PluginOverlayStyles = styled.div`
  width: 100%;
  border-radius: 8px;
  background: transparent;
  color: ${theme.primaryColours.aubergine.main};

  a {
    color: ${theme.primaryColours.aubergine.main};
    font-weight: bold;
  }

  .overlay-heading {
    margin-bottom: 24px;

    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-bottom: 32px;
    }

    @media (min-width: ${theme.grid.resolution.desktop.width}px) {
      margin-bottom: 48px;
    }
  }

  .overlay-sub-heading {
    margin-bottom: 16px;

    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-bottom: 24px;
    }
  }

  .overlay-content {
    margin-bottom: 24px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-bottom: 32px;
    }

    @media (min-width: ${theme.grid.resolution.desktop.width}px) {
      margin-bottom: 48px;
    }
  }

  .bullets-wrapper {
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      max-width: 450px;
    }

    @media (min-width: ${theme.grid.resolution.desktop.width}px) {
      max-width: 520px;
    }
  }

  .item-wrapper {
    margin-bottom: 24px;
  }

  .trackerless-activation-info {
    margin-top: 24px;
  }
`

export const PluginOverlay = ({
  heading,
  subHeadingOne,
  subHeadingTwo,
  content,
  activating,
  unplugged,
  isTrackerless,
}) => (
  <PluginOverlayStyles>
    <div className="LoNotSensitive">
      <H2 className="overlay-heading">{heading}</H2>
      <ParagraphBody className="overlay-content">{content}</ParagraphBody>
      <H5 className="overlay-sub-heading">{subHeadingOne}</H5>

      <div className="bullets-wrapper">
        <div className="item-wrapper">
          {activating.map(item => (
            <PluginListItem
              icon={{
                status: 'complete',
                background: theme.primaryColours.mint.superDarkMint,
                fill: theme.primaryColours.white.main,
              }}
              item={item}
            />
          ))}
        </div>
        <H5 className="overlay-sub-heading">{subHeadingTwo}</H5>
        {unplugged.map(item => (
          <PluginListItem
            icon={{
              status: 'closed',
              background: theme.primaryColours.tangerine.main,
              fill: theme.primaryColours.white.main,
            }}
            item={item}
          />
        ))}
        {isTrackerless &&
          'That last bit might sound a bit scary, but we’ll always be on hand to help if you need anything so there shouldn’t be any nasty surprises. '}

        <MessageBanner
          className="trackerless-activation-info"
          text={`Please remember, this policy does not cover you for incidents caused by using remote control features of your car (such as 'Summon' or 'Autopark') where you or a named driver is not behind the steering wheel.`}
          backgroundColor={theme.tints.lightAubergine}
          borderColor={theme.primaryColours.aubergine.main}
          icon=""
        />
      </div>
    </div>
  </PluginOverlayStyles>
)
