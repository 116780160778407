import { LegacyHeadlinesWithIcons, LegacyQuoteRetrievalBreadcrumbs } from '@by-miles/bymiles-styles'
import { DataLayer } from '@by-miles/data-layer'
import React, { useEffect } from 'react'
import Helmet from 'react-helmet'

import LegacyHeader from '../components/LegacyHeader'
import { RetrievalForm } from '../components/retrievalPage/RetrievalForm'
import TrustPilotWidget from '../components/retrievalPage/TrustPilotWidget'
import { Footer } from '../components/shared'
import config from '../config'
import useChatWidget from '../hooks/useChatWidget'
import { ReactComponent as AnApp } from '../icons/retrieval-page/AnApp.svg'
import { ReactComponent as AwardWinning } from '../icons/retrieval-page/AwardWinning.svg'
import { ReactComponent as PayAsYouDrive } from '../icons/retrieval-page/PayAsYouDrive.svg'
import { footerLinks, footerText } from '../utils/FooterLinks'
import { randomCustomerName } from '../utils/randomCustomerNames'
import { RetrievalPageStyle } from './styles/RetrievalPageStyle'

const breadcrumbs = [{ name: 'DETAILS' }, { name: 'QUOTE' }, { name: 'CHECKOUT', complete: false }]

const headlines = [
  {
    Icon: PayAsYouDrive,
    headline: 'Priced by the mile, not driving style.',
  },
  {
    Icon: AwardWinning,
    headline: 'Award-winning customer service.',
  },
  {
    Icon: AnApp,
    headline: 'An app that puts you in control.',
  },
]

const RetrievalPage = ({ cold = false }) => {
  const urlParams = new URLSearchParams(window.location.search)
  const quoteId = urlParams.get('quote_id') || ''

  if (!cold && !quoteId) {
    window.location.href = `${config.checkoutOrigin}/details/car`
  }

  useEffect(() => {
    const pageName = cold ? '/ga-virtual/retrieve' : '/ga-virtual/retrieval'
    DataLayer.customEvent({
      eventName: pageName,
      section: 'retrieval',
      eventType: 'virtual page view',
    })
    document.body.classList.add('retrieval-body')
    const root = document.querySelector('html')
    root?.classList.add('retrieval-body')
  }, [])

  const { setChatEmail, openChatWidget } = useChatWidget()

  const openChat = () => {
    setChatEmail(randomCustomerName())
    openChatWidget()
  }

  return (
    <>
      <div className="legacy-wrapper">
        <LegacyHeader
          hideBreadCrumbs
          hideQuestion
          showCallUs
          noBoxShadow
          showChatWithUs
          slim
          label="CHAT TO US"
          desktopPosition="relative"
          mobilePosition="relative"
          style={{ maxWidth: '1170px' }}
          toggleIntercom={openChat}
        />
      </div>
      <RetrievalPageStyle>
        <Helmet>
          <meta charSet="utf-8" />
          <title>Let's get you back to your quote</title>
          <meta name="description" content="" />
        </Helmet>
        <div className="hero-wrapper">
          <div className="desktop-wrapper">
            <div className="hero-container">
              <div className="breadcrumbs-wrapper">
                <LegacyQuoteRetrievalBreadcrumbs breadcrumbs={breadcrumbs} backgroundColor="" />
              </div>
              <div className="flex">
                <div className="title-headlines">
                  <div className="hero-title">
                    A fairer kind of car insurance that you <span className="line-behind"> pay by the mile.</span>
                  </div>
                  <div className="headlines-wrapper">
                    <LegacyHeadlinesWithIcons className="headlines" headlines={headlines} />
                  </div>
                  <div className="desktop-trustpilot">
                    <TrustPilotWidget className="trustpilot" />
                  </div>
                </div>

                <div className="desktop-form">
                  <RetrievalForm className="none-mobile-form" cold={cold} quoteId={quoteId} />
                </div>
              </div>
            </div>

            <div className="tablet-form">
              <div className="retrieval-container" style={{ padding: 0 }}>
                <RetrievalForm className="none-mobile-form" cold={cold} quoteId={quoteId} />
                <TrustPilotWidget />
              </div>
            </div>
          </div>
        </div>

        <div className="retrieval-container mobile">
          <RetrievalForm className="retrieval-form" cold={cold} quoteId={quoteId} />
        </div>

        <div className="mobile-trustPilot-section">
          <TrustPilotWidget />
        </div>
        <Footer className="retrieval-footer" links={footerLinks} text={footerText} />
      </RetrievalPageStyle>
    </>
  )
}

export default RetrievalPage
