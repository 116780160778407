import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import config from '../../config'
import { GenericOverlay } from './GenericOverlay/GenericOverlay'
import { PluginOverlay } from './PluginOverlay'
dayjs.extend(utc)

interface WhyPluginOverlayProps {
  isTrackerless: boolean
  inceptionDate: string | null | undefined
}

export const WhyPluginOverlay = ({ isTrackerless, inceptionDate }: WhyPluginOverlayProps) => {
  const activationDate = isTrackerless
    ? dayjs(inceptionDate).add(2, 'day')
    : dayjs(inceptionDate).add(config.christmasTime ? 14 : 7, 'day')

  const formatedActivationDate = dayjs(activationDate).format('Do MMMM YYYY')

  return (
    <GenericOverlay
      overlay={
        <PluginOverlay
          heading={isTrackerless ? 'Why do I need to activate?' : 'Why do I need to plug in?'}
          content={
            isTrackerless
              ? [
                  `From the second your policy starts, you’re covered by our comprehensive policy whether you’re parked or driving around. You just need to keep up your end of the bargain and activate your policy by connecting with your car as soon as you can.`,
                ]
              : [
                  `You’re covered by our comprehensive insurance from the second your policy starts. We’ll pop your Miles Tracker in the post and you’ll need to plug it in by ${formatedActivationDate}.`,
                ]
          }
          subHeadingOne={isTrackerless ? 'Activating is easy:' : 'Activating is easy:'}
          activating={
            isTrackerless
              ? [
                  'We’ll give you full instructions for your car in the app and web dashboard.',
                  'It will take a matter of minutes to connect your car’s account and activate, no need to go driving.',
                  'If you’re activated early, you won’t be charged for miles until your policy start date.',
                ]
              : [
                  'It will take a matter of minutes to plug in and activate, no need to go driving.',
                  'We’ll give you full instructions for your car in the app and web dashboard.',
                  'If you’re plugged in early, we won’t charge you for journeys until your policy start date.',
                ]
          }
          subHeadingTwo={`If you’re still ${isTrackerless ? 'not activated' : 'unplugged'} after ${dayjs(
            activationDate,
          ).format('Do MMMM YYYY')}:`}
          unplugged={
            isTrackerless
              ? [
                  'If your policy starts, we may start estimating your mileage at 150 miles a day.',
                  'Your excess will increase by £500 if you have an accident while it’s not activated.',
                  'We may cancel your policy after 14 days.',
                ]
              : [
                  'If your policy starts, we may start estimating your mileage at 150 miles a day.',
                  'Your excess will increase by £500 if you have an accident while it’s unplugged.',
                  'We may cancel your policy after 14 days.',
                ]
          }
          isTrackerless={isTrackerless}
        />
      }
    />
  )
}
