import { isProd } from '../config'

export const onlyImmobiliserIsIdScore = (immobilisers: any) => {
  if (isProd) {
    if (immobilisers?.length === 1 && immobilisers[0].type === 'checkIdScore') return true
    if (immobilisers?.length > 1 || !immobilisers?.length) return false
  } else {
    const filteredImmobilisers = immobilisers.filter(
      (i: any) => i.type === 'checkIdScore' || i.type === 'GLvalidateIdScore',
    )
    return (filteredImmobilisers?.length === 1 || filteredImmobilisers?.length === 2) &&
      filteredImmobilisers?.length === immobilisers?.length
      ? true
      : false
  }
}
