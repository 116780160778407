import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const DifficultPluginStyles = styled.div`
  .difficult-plugin-modal-wrapper {
    width: 100%;
    border-radius: 8px;
    background: transparent;
    color: ${theme.primaryColours.aubergine.main};
  }

  .difficult-plugin-heading {
    margin-bottom: 24px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-bottom: 32px;
    }
  }

  .obd-gif-wrapper {
    width: 100%;
    background: ${theme.tints.lightAubergine};
    padding: 21px 42px;
    margin: 24px 0 8px 0;
    display: flex;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin: 32px 0 8px 0;
    }

    .gif {
      width: 217px;
      margin: 0 auto;
    }
  }

  .lead-paragraph {
    margin-bottom: ${theme.grid.spacing.base}px;
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin-bottom: 16px;
    }
  }

  .plugin-checkbox-button {
    width: 100%;
  }

  .button-wrapper {
    margin-top: ${theme.grid.spacing.base}px;
    display: grid;
    grid-gap: ${theme.grid.spacing.base}px;

    .proceed-btn {
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        grid-column: 1;
        grid-row: 1;
      }
    }

    .save-quote-btn {
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        grid-column: 2;
        grid-row: 1;
      }
    }
    button {
      width: 100%;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        width: auto;
      }
    }
  }

  .intercom-link {
    margin-bottom: 24px;
  }

  a {
    color: ${theme.primaryColours.aubergine.main};
    font-weight: bold;
  }
`
