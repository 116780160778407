import { CheckButtonTheme, IconCheckButton, LabelLarge, Tag, theme } from '@by-miles/bymiles-styles'
import { ButtonGeneral, ButtonSecondary, H5, LabelMedium, ParagraphBody } from '@by-miles/bymiles-styles'
import React from 'react'

import { useQuote } from '../../hooks/useQuote'
import { ExtrasImage } from './ExtrasImage'
import { ExtrasLogo } from './ExtrasLogo'
import { ExtrasPanelStyles } from './ExtrasPanelStyles'
import { getDefaultCostOfExtras } from './utils/getDefaultCostOfExtras'
import { getExtrasDescription } from './utils/getExtrasDescription'
import { getSelectedCostOfExtras } from './utils/getSelectedCostOfExtras'

interface ExtrasPanelProps {
  logo?: string
  image: string
  hasSelectedTier: boolean
  tiers: any[]
  handleOnClick: () => void
  title: string
  type: string
  customDescription: string | null
  tabFocusTrap?: number
}

export const ExtrasPanel = ({
  type,
  image,
  logo,
  title,
  hasSelectedTier,
  tiers,
  handleOnClick,
  customDescription = null,
  tabFocusTrap,
}: ExtrasPanelProps) => {
  const quote = useQuote()
  const category = tiers?.length > 0 ? tiers[0].category : []
  const coverDescription = customDescription || getExtrasDescription(tiers)
  const offeredFreeLegalCover = quote?.details?.site?.wasOfferedFreeLegal
  const isLegalCover = category === 'legal'
  const coverAmount = hasSelectedTier ? getSelectedCostOfExtras(tiers) : getDefaultCostOfExtras(tiers, isLegalCover)

  return (
    <ExtrasPanelStyles className={`${type}-container`}>
      {hasSelectedTier && (
        <div className="selected-banner">
          <LabelLarge style={{ color: theme.primaryColours.mint.superDarkMint }}>ADDED</LabelLarge>
          <IconCheckButton
            status="complete"
            iconFill={theme.primaryColours.white.main}
            backgroundFill={theme.primaryColours.mint.superDarkMint}
            buttonTheme={CheckButtonTheme.Dark}
          />
        </div>
      )}
      <ExtrasImage image={image} altText="breakdown-cover-illustration" />
      <div className="content-wrapper">
        <div>
          <H5 className="extra-heading">{title}</H5>
          <ParagraphBody className="extra-description">{coverDescription}</ParagraphBody>
        </div>
        <div>
          <div className="flex">
            {logo && <ExtrasLogo logo={logo} altText={`${category} cover logo`} />}
            {offeredFreeLegalCover && isLegalCover && <Tag text="free this year!" />}
          </div>
          {offeredFreeLegalCover && isLegalCover ? (
            <>
              <LabelMedium className="extras-price">
                for{' '}
                <span
                  style={{ textDecoration: 'line-through', textDecorationThickness: '2px' }}
                >{`£${getDefaultCostOfExtras(tiers, true)}`}</span>{' '}
                £0.00
              </LabelMedium>
            </>
          ) : (
            <LabelMedium className="extras-price">{`${
              type === 'rac' && !hasSelectedTier ? 'from' : 'for'
            } £${coverAmount}`}</LabelMedium>
          )}
          {hasSelectedTier ? (
            <ButtonSecondary
              size="extraSmall"
              label="EDIT"
              stretch={true}
              onClick={handleOnClick}
              dataCy={`edit-${type}`}
              className="button-edit"
              tabFocusTrap={tabFocusTrap}
            />
          ) : (
            <ButtonGeneral
              stretch={true}
              size="extraSmall"
              label="ADD"
              onClick={handleOnClick}
              dataCy={`add-${type}`}
              className="button-edit"
              tabFocusTrap={tabFocusTrap}
            />
          )}
        </div>
      </div>
    </ExtrasPanelStyles>
  )
}
