import { Helmet } from 'react-helmet'

import config from '../../config'

const ChatWidget = () => {
  if (config.zendeskChatEnabled) {
    return (
      <Helmet>
        <script id="ze-snippet" src={`https://static.zdassets.com/ekr/snippet.js?key=${config.zendeskId}`} />
      </Helmet>
    )
  }

  return null
}

export default ChatWidget
