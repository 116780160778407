import { useHistory } from 'react-router-dom'

import config from '../config'

type ChatOffset = {
  vertical?: string
  horizontal?: string
}

const waitForChatWidget = () => {
  let attempts = 0
  const ATTEMPT_LIMIT = 50

  return new Promise<void>((resolve, reject) => {
    const interval = setInterval(() => {
      attempts++

      if (window.zE) {
        clearInterval(interval)
        resolve(window.zE)
      }

      if (attempts >= ATTEMPT_LIMIT) {
        clearInterval(interval)
        reject('Failed to load chat widget')
      }
    }, 100)
  })
}

const useChatWidget = () => {
  const history = useHistory()

  const setupChatWidget = async (name?: string) => {
    if (config.zendeskChatEnabled) {
      try {
        await waitForChatWidget()
        window.zE('webWidget', 'updateSettings', {
          webWidget: {
            answerBot: {
              avatar: {
                url: 'https://www.bymiles.co.uk/static/assets/favicon.png',
                name: {
                  '*': 'Kitt',
                },
              },
            },
            chat: {
              departments: {
                enabled: [],
                select: 'New Business Support',
              },
            },
          },
        })
        // Keep the launcher hidden while on checkout part of the quote flow, the chat
        // open is controlled by the user clicking "Chat to us" on the contact window.
        if (!window.location.pathname.includes('/details')) {
          window.zE('webWidget', 'hide')
        }

        window.zE('webWidget:on', 'close', function () {
          window.zE('webWidget', window.location.pathname.includes('/details') ? 'close' : 'hide')
        })

        history.listen(() => {
          window.zE('webWidget', 'updatePath', {
            title: document.title,
            url: window.location.href,
          })
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const setChatEmail = async (email: string) => {
    if (config.zendeskChatEnabled) {
      try {
        await waitForChatWidget()
        window.zE('webWidget', 'identify', { email })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const openChatWidget = async (event?: string, suggestion?: string) => {
    if (config.zendeskChatEnabled) {
      try {
        await waitForChatWidget()
        if (event) window.zE('webWidget', 'chat:addTags', [event])
        if (suggestion) window.zE('webWidget', 'helpCenter:setSuggestions', { search: suggestion })
        window.zE('webWidget', 'show')
        window.zE('webWidget', 'open')
      } catch (error) {
        console.error(error)
      }
    }
  }

  const updateChatOffset = async (offset: ChatOffset) => {
    if (config.zendeskChatEnabled) {
      try {
        await waitForChatWidget()
        window.zE('webWidget', 'updateSettings', {
          webWidget: {
            offset: {
              ...offset,
              mobile: {
                ...offset,
              },
            },
          },
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  const updateChatWidgetCookies = async (hasCookiePermissions: boolean) => {
    if (config.zendeskChatEnabled) {
      try {
        await waitForChatWidget()
        window.zE('webWidget', 'updateSettings', {
          webWidget: {
            cookies: hasCookiePermissions,
          },
        })
      } catch (error) {
        console.error(error)
      }
    }
  }

  return {
    setupChatWidget,
    setChatEmail,
    openChatWidget,
    updateChatOffset,
    updateChatWidgetCookies,
  }
}

export default useChatWidget
