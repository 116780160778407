import { theme } from '@by-miles/bymiles-styles'
import styled from 'styled-components'

export const DynamicOverlayTableStyle = styled.div`
  width: 100%;
  border-radius: 8px;
  background: #fff;
  color: ${theme.primaryColours.aubergine.main};

  .table-wrapper {
    position: relative;
    margin: 32px 0;

    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      margin: 48px 0;
    }

    .column-title {
      max-width: 110px;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        max-width: 140px;
      }
      @media (min-width: ${theme.grid.resolution.desktop.width}px) {
        max-width: none;
      }
      &.cost {
        text-align: right;
      }
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      padding-bottom: 16px;
      margin-bottom: 24px;
      border-bottom: 1px solid ${theme.tints.darkAubergine};
      .title-item {
        .column-title {
          max-width: 140px;
        }
        &.middleTitle {
          .column-title {
            max-width: 120px;
          }
        }
      }
    }

    .item-row {
      display: flex;
      justify-content: space-between;
      margin: 20px 0;
      @media (min-width: ${theme.grid.resolution.tablet.width}px) {
        margin: 28px 0;
      }
      &:first-child {
        margin: 0;
      }
      &:last-child {
        margin-bottom: 0;
        @media (min-width: ${theme.grid.resolution.tablet.width}px) {
          margin-bottom: 0;
        }
      }

      &.active {
        background: ${theme.tints.lightAubergine};
        border-radius: 4px;
        padding: 8px;
        margin: 0 -16px;
        @media (min-width: ${theme.grid.resolution.tablet.width}px) {
          padding: 16px;
        }
      }

      .item {
        color: ${theme.primaryColours.aubergine.lightAubergine};
      }
    }
    &.three-columns {
      .title-wrapper {
      }
      .title-item {
        &.firstTitle {
          width: 50%;
        }
        &.middleTitle {
          width: 30%;
        }
      }

      .item-row {
        width: 100%;
        .item-wrapper {
          &.first {
            width: 45%;
            @media (min-width: ${theme.grid.resolution.tablet.width}px) {
              width: 40%;
            }
          }
          &.middle-item {
            width: 30%;
          }
          &.cost {
            min-width: 85px;
            text-align: right;
          }
        }
        &.active {
          margin: 0;
          width: 103%;
          position: relative;
          left: -5px;
          @media (min-width: ${theme.grid.resolution.tablet.width}px) {
            margin: 0;
            width: 105%;
            position: relative;
            left: -13px;
          }
          .item-wrapper {
            &.middle-item {
            }
            &.first {
              @media (min-width: ${theme.grid.resolution.tablet.width}px) {
                width: 38%;
              }
            }
          }
        }
        .item {
          &.green {
            color: ${theme.primaryColours.mint.superDarkMint};
          }
          &.middle-item {
            &.red {
              color: ${theme.primaryColours.tangerine.darkTangerine};
            }
          }
        }
      }
    }
  }

  .show-mobile-only {
    @media (min-width: ${theme.grid.resolution.tablet.width}px) {
      display: none;
    }
  }
`
